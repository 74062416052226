import React, {useEffect, useState} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";
import DataTable from "../../../components/dataTable/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {persianDateNT} from "../../../utility/persianDateNT";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import {TbEdit} from "react-icons/tb";
import OperatorButton from "../../../components/dataTable/OperatorButton";
import {MdDelete} from "react-icons/md";
import Alert from "../../../components/toast/Alert";
import {TiThListOutline} from "react-icons/ti";
import {clearSliderDelete, deleteAsyncSlider, getAsyncSliderList} from "../../../features/slider/sliderSlice";
import {clearNewsDelete, getAsyncNewsList} from "../../../features/news/newsSlice";
import {Toast} from "../../../components/toast/Toast";


const Slider = () => {

    const [perPage, setPerPage] = useState(10);
    const [numberPage, setNumberPage] = useState(1);

    const {sliderList, sliderDelete, loading, error, count, page} = useSelector(state => state.slider)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncSliderList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage, numberPage])

    const columns = [
        {
            id:1,
            name: "عنوان اسلایدر",
            selector: row => row.slider_title,
        },
        {
            id:2,
            name: "لینک اسلایدر",
            style: {
                width: '220px'
            },
            selector: row => row.slider_url
        },
        {
            id:3,
            name: "وضعیت",
            style: {
                width: '220px'
            },
            selector: row => row.slider_status
        },
        {
            id:4,
            name: "تاریخ ثبت",
            style: {
                width: '220px'
            },
            selector: row => persianDateNT.dateWithoutTime(row.slider_create_time)
        },
        {

            id:5,
            name: "عملیات",
            style: {
                width: '220px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center gap-2">
                        <OperatorLink to={'/add-slider/' + row.slider_id} icon={<TbEdit size={20}/>}
                                      label="ویرایش" classCustom="btn-blue"/>
                        <OperatorButton onClick={() => showAlert(row.slider_id)} icon={<MdDelete size={20}/>}
                                        label="حذف"
                                        classCustom={row.history_status === "حذف" ? "btn-disable" : "btn-red"}
                                        disabledAction={row.history_status === "حذف"}/>
                    </div>
                </div>
            )
        }
    ];

    //? for toast delete ///
    const [showAlertPage, setShowAlertPage] = useState(false)
    const [showAlertValue, setShowAlertValue] = useState("")
    const showAlert = (input) => {
        setShowAlertPage(true)
        setShowAlertValue(input)
    }
    const removeFunc = () => {
        dispatch(deleteAsyncSlider({slider_id: showAlertValue}))
    }

    useEffect(() => {
        if (sliderDelete.status !== undefined) {
            if (sliderDelete.status === "success") {
                dispatch(getAsyncSliderList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                dispatch(clearSliderDelete())
                Toast.success(sliderDelete.message)
                setShowAlertPage(false)
            } else {
                dispatch(clearSliderDelete())
                Toast.error(sliderDelete.message)
            }
        }
    }, [sliderDelete, perPage, numberPage])

    return (
        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالا استیل" titleLabel="اسلایدر های کارشده"
                                icons={<TiThListOutline className="text-slate-500 dark:text-slate-400" size={23}/>}/>
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg lg:px-10 py-8">
                    <div className="flex flex-col gap-1 py-2">
                        {loading && (<Loading/>)}
                        {error && (<Error error={error}/>)}
                        {!loading && error === null && (
                            <DataTable
                                to="/add-slider"
                                nameTitle="افزودن اسلایدر جدید"
                                columns={columns}
                                data={sliderList}
                                count={count}
                                page={page}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                numberPage={numberPage}
                                setNumberPage={setNumberPage}
                            />
                        )}
                    </div>

                    <Alert
                        showAlertPage={showAlertPage}
                        setShowAlertPage={setShowAlertPage}
                        title="اخطار"
                        label="آیا برای حذف مطمئن هستید‌؟"
                        onclick={removeFunc}
                    />

                </div>
            </div>
        </div>
    );
};

export default Slider;
