import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";



export const getAsyncSetting = createAsyncThunk('setting/getAsyncSetting', async (payload,
                                                                                  {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({ ...payload, action: "get_setting_admin"}),
            {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const editAsyncSetting = createAsyncThunk('setting/editAsyncSetting', async (payload,
                                                                                  {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({ ...payload, action: "edit_setting_admin" }),
            { headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncContact = createAsyncThunk('setting/getAsyncContact', async (payload,
                                                                                  {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({ ...payload, action: "get_contact_admin"}),
            {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const editAsyncContact = createAsyncThunk('setting/editAsyncContact', async (payload,
                                                                                  {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({ ...payload, action: "edit_contact_admin"}),
            {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncAboutUs = createAsyncThunk('setting/getAsyncAboutUs', async (payload,
                                                                                  {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({ ...payload, action: "get_about_admin"}),
            {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const editAsyncAboutUs = createAsyncThunk('setting/editAsyncAboutUs', async (payload,
                                                                                    {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({ ...payload, action: "edit_about_admin"}),
            {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    contact :{},
    resultContact: {},
    about :{},
    resultAbout: {},
    setting: {},
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const settingSlice = createSlice({

    name: "setting",
    initialState,
    reducers: {
        clearResultSetting: (state, action) => {
            state.result = {}
        },
        clearResultContact: (state, action) => {
            state.resultContact = {}
        },
        clearResultAbout: (state, action) => {
            state.resultAbout = {}
        }
    },
    extraReducers: {
        [getAsyncSetting.fulfilled]: (state, action) => {
            return {
                ...state,
                setting: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncSetting.pending]: (state, action) => {
            return {
                ...state,
                setting: {},
                loading: true,
                error: null
            }
        },
        [getAsyncSetting.rejected]: (state, action) => {
            return {
                ...state,
                setting: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [editAsyncSetting.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                loading: false,
                error: null,
            }
        },
        [editAsyncSetting.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: true,
                error: null
            }
        },
        [editAsyncSetting.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [getAsyncContact.fulfilled]: (state, action) => {
            return {
                ...state,
                contact: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncContact.pending]: (state, action) => {
            return {
                ...state,
                contact: {},
                loading: true,
                error: null
            }
        },
        [getAsyncContact.rejected]: (state, action) => {
            return {
                ...state,
                contact: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [editAsyncContact.fulfilled]: (state, action) => {
            return {
                ...state,
                resultContact: action.payload,
                loading: false,
                error: null,
            }
        },
        [editAsyncContact.pending]: (state, action) => {
            return {
                ...state,
                resultContact: {},
                loading: true,
                error: null
            }
        },
        [editAsyncContact.rejected]: (state, action) => {
            return {
                ...state,
                resultContact: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [getAsyncAboutUs.fulfilled]: (state, action) => {
            return {
                ...state,
                about: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncAboutUs.pending]: (state, action) => {
            return {
                ...state,
                about: {},
                loading: true,
                error: null
            }
        },
        [getAsyncAboutUs.rejected]: (state, action) => {
            return {
                ...state,
                about: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [editAsyncAboutUs.fulfilled]: (state, action) => {
            return {
                ...state,
                resultAbout: action.payload,
                loading: false,
                error: null,
            }
        },
        [editAsyncAboutUs.pending]: (state, action) => {
            return {
                ...state,
                resultAbout: {},
                loading: true,
                error: null
            }
        },
        [editAsyncAboutUs.rejected]: (state, action) => {
            return {
                ...state,
                resultAbout: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

    }

})

export const {clearResultSetting,clearResultContact,clearResultAbout} = settingSlice.actions;

export default settingSlice.reducer
