import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/toast/Toast";
import {deleteAsyncNews} from "../news/newsSlice";

export const getAsyncManagersList = createAsyncThunk('managers/getAsyncManagersList', async (payload,
                                                                                             {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_admin_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const postAsyncManagers = createAsyncThunk('managers/postAsyncManagers', async (payload,
                                                                                       {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "add_admin_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncManagers = createAsyncThunk('managers/getAsyncManagers', async (payload,
                                                                                     {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_admin_admin", admin_id: payload.id
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const deleteAsyncManager = createAsyncThunk('managers/deleteAsyncManager', async (payload, {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({...payload, action: "delete_admin_admin"}), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})


const initialState = {
    adminsList: [],
    admins: false,
    managerDelete: {},
    count: 1,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const managersSlice = createSlice({

    name: "managers",
    initialState,
    reducers: {
        clearResultManager: (state, action) => {
            state.result = {}
        },
        clearAdmins: (state, action) => {
            state.admins = false
        },
        clearResultManagerDelete: (state, action) => {
            state.managerDelete = {}
        },
    },
    extraReducers: {
        [getAsyncManagersList.fulfilled]: (state, action) => {
            return { ...state,
                adminsList: action.payload.result.admins,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null,
            }
        },
        [getAsyncManagersList.pending]: (state, action) => {
            return { ...state, adminsList: [], count: 1, page: 1, loading: true, error: null
            }
        },
        [getAsyncManagersList.rejected]: (state, action) => {
            return { ...state, adminsList: [], count: 1, page: 1, loading: false, error: ConfigMessage.error
            }
        },

        [getAsyncManagers.fulfilled]: (state, action) => {
            return { ...state,  admins: action.payload.result, loading: false, error: null,
            }
        },
        [getAsyncManagers.pending]: (state, action) => {
            return { ...state, admins: {},  loading: true, error: null
            }
        },
        [getAsyncManagers.rejected]: (state, action) => {
            return { ...state, admins: {}, loading: false, error: ConfigMessage.error
            }
        },

        [postAsyncManagers.fulfilled]: (state, action) => {
            return {...state, result: action.payload, loading: false, error: null,}
        },
        [postAsyncManagers.pending]: (state, action) => {
            return {...state, result: {}, loading: true, error: null}
        },
        [postAsyncManagers.rejected]: (state, action) => {
            return {...state, result: {}, loading: false, error: ConfigMessage.error
            }
        },

        [deleteAsyncManager.fulfilled]: (state, action) => {
            return { ...state, managerDelete: action.payload, loading: false, error: ConfigMessage.error }
        },
        [deleteAsyncManager.pending]: (state, action) => {
            return { ...state, managerDelete: {}, loading: true, error: null
            }
        },
        [deleteAsyncManager.rejected]: (state, action) => {
            return { ...state, managerDelete: {}, loading: false, error: ConfigMessage.error
            }
        },
    }

})

export const {clearResultManager, clearAdmins,clearResultManagerDelete} = managersSlice.actions;

export default managersSlice.reducer
