import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/toast/Toast";

export const getAsyncNewsCategoryList = createAsyncThunk('newsCategory/getAsyncNewsCategoryList', async (payload,
                                                                                                         {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_news_category_Admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncNewsCategoryListForAdd = createAsyncThunk('newsCategory/getAsyncNewsCategoryListForAdd', async (payload,
                                                                                                         {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({action: "list_news_category_for_add_Admin"}),
            {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncNewsCategory = createAsyncThunk('newsCategory/getAsyncNewsCategory', async (payload,
                                                                                                 {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_news_category_Admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const postAsyncNewsCategory = createAsyncThunk('newsCategory/postAsyncNewsCategory', async (payload,
                                                                                                   {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({...payload, action: "add_news_category_admin"})
            , {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const deleteAsyncNewsCategory = createAsyncThunk('newsCategory/deleteAsyncNewsCategory', async (payload,
                                                                                                       {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "delete_news_category_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    newsCategoryList: [],
    newsCategoryListSelect: [],
    newsCategory: {},
    newsCategoryDelete: {},
    count: 3,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const newsCategorySlice = createSlice({

    name: "newsCategory",
    initialState,
    reducers: {
        clearResultNewsCategory : (state, action) => {
            state.result = {}
        },
        clearNewsCategory: (state, action) => {
            state.newsCategory = false
        },
        clearResultNewsCategoryDelete : (state, action) => {
            state.newsCategoryDelete = {}
        },
    },
    extraReducers: {
        [getAsyncNewsCategoryList.fulfilled]: (state, action) => {
            return {
                ...state,
                newsCategoryList: action.payload.result.news_category,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null,
            }
         },
        [getAsyncNewsCategoryList.pending]: (state, action) => {
            return {
                ...state,
                newsCategoryList: [],
                count: 3,
                page: 1,
                loading: true,
                error: null
            }
        },
        [getAsyncNewsCategoryList.rejected]: (state, action) => {
            return {
                ...state,
                newsCategoryList: [],
                count: 3,
                page: 1,
                loading: false,
                error: ConfigMessage.error
            }
        },

        [getAsyncNewsCategoryListForAdd.fulfilled]: (state, action) => {
            return {
                ...state,
                newsCategoryListSelect : action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncNewsCategoryListForAdd.pending]: (state, action) => {
            return {
                ...state,
                newsCategoryListSelect: [],
                loading: true,
                error: null
            }
        },
        [getAsyncNewsCategoryListForAdd.rejected]: (state, action) => {
            return {
                ...state,
                newsCategoryListSelect: [],
                loading: false,
                error: ConfigMessage.error
            }
        },

        [getAsyncNewsCategory.fulfilled]: (state, action) => {
            return {
                ...state,
                newsCategory: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncNewsCategory.pending]: (state, action) => {
            return {
                ...state,
                newsCategory: {},
                loading: true,
                error: null
            }
        },
        [getAsyncNewsCategory.rejected]: (state, action) => {
            return {
                ...state,
                newsCategory: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncNewsCategory.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                loading: false,
                error: null,
            }
        },
        [postAsyncNewsCategory.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: true,
                error: null
            }
        },
        [postAsyncNewsCategory.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [deleteAsyncNewsCategory.fulfilled]: (state, action) => {
            return { ...state, newsCategoryDelete: action.payload, loading: false,  error: null, }
        },
        [deleteAsyncNewsCategory.pending]: (state, action) => {
            return { ...state, newsCategoryDelete: {}, loading: true, error: null }
        },
        [deleteAsyncNewsCategory.rejected]: (state, action) => {
            return { ...state, newsCategoryDelete: {}, loading: false, error: ConfigMessage.error }
        },

        // [deleteAsyncNewsCategory.fulfilled]: (state, action) => {
        //     if(action.payload.status === "success"){
        //         state.newsCategoryList = state.newsCategoryList.filter((value)=> value.news_category_id != action.payload.result.news_category_id)
        //         Toast.success(action.payload.message)
        //     }else {
        //         Toast.error(action.payload.message)
        //     }
        // }
    }
})

export const {clearResultNewsCategory,clearNewsCategory,clearResultNewsCategoryDelete} = newsCategorySlice.actions;

export default newsCategorySlice.reducer
