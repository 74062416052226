import React, {useEffect, useState} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";
import DataTable from "../../../components/dataTable/DataTable";
import {useDispatch, useSelector} from "react-redux";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import {TbEdit} from "react-icons/tb";
import OperatorButton from "../../../components/dataTable/OperatorButton";
import {MdDelete} from "react-icons/md";
import Alert from "../../../components/toast/Alert";
import {TiThListOutline} from "react-icons/ti";
import {clearTeamDelete, deleteAsyncTeam, getAsyncTeamList} from "../../../features/team/teamSlice";
import {Toast} from "../../../components/toast/Toast";


const ListTeam = () => {

    const [perPage, setPerPage] = useState(10);
    const [numberPage, setNumberPage] = useState(1);

    const {teamList, teamDelete, loading, error, count, page} = useSelector(state => state.team)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncTeamList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage, numberPage])

    const columns = [

        {
            name: "نام اعضاء",
            selector: row => row.team_name,
        },
        {
            name: "سمت",
            selector: row => row.team_role
        },
        {
            name: "تصحیلات",
            selector: row => row.team_education
        },
        {
            name: "وضعیت",
            selector: row => row.team_publish
        },
        {
            name: "عملیات",
            style: {
                width: '220px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <OperatorLink to={'/work-team/' + row.team_id} icon={<TbEdit size={20}/>}
                                  label="ویرایش"
                                  classCustom="btn-blue"/>
                    <OperatorButton onClick={() => showAlert(row.team_id)} icon={<MdDelete size={20}/>}
                                    label="حذف" classCustom={row.customer_status === "حذف" ? "btn-disable" : "btn-red"}
                                    disabledAction={row.customer_status === "حذف"}/>
                </div>
            )
        }
    ];

    //? for toast delete ///
    const [showAlertPage, setShowAlertPage] = useState(false)
    const [showAlertValue, setShowAlertValue] = useState("")
    const showAlert = (input) => {
        setShowAlertPage(true)
        setShowAlertValue(input)
    }
    const removeFunc = () => {
        dispatch(deleteAsyncTeam({team_id: showAlertValue}))
    }

    useEffect(() => {
        if (teamDelete.status !== undefined) {
            if (teamDelete.status === "success") {
                dispatch(getAsyncTeamList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                dispatch(clearTeamDelete())
                Toast.success(teamDelete.message)
                setShowAlertPage(false)
            } else {
                dispatch(clearTeamDelete())
                Toast.error(teamDelete.message)
            }
        }
    }, [teamDelete, perPage, numberPage])


    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="لیست اعضاء تیم"
                                icons={<TiThListOutline className="text-slate-500 dark:text-slate-400" size={20}/>}/>
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg lg:px-10 py-8">
                    <div className="flex flex-col gap-1">
                        <div>
                            {loading && (<Loading/>)}
                            {error && (<Error error={error}/>)}
                            {!loading && error === null && (
                                <DataTable
                                    to="/work-team"
                                    nameTitle = "افزودن اعضاء جدید"
                                    columns={columns}
                                    data={teamList}
                                    count={count}
                                    page={page}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                    numberPage={numberPage}
                                    setNumberPage={setNumberPage}
                                />
                            )}
                        </div>
                        <Alert
                            showAlertPage={showAlertPage}
                            setShowAlertPage={setShowAlertPage}
                            title="اخطار"
                            label="آیا برای حذف مطمئن هستید‌؟"
                            onclick={removeFunc}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ListTeam;
