import React, {useEffect} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import Input from "../../../components/input/Input";
import ButtonForm from "../../../components/button/ButtonForm";
import * as yup from "yup";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Toast} from "../../../components/toast/Toast";
import {clearProfile, clearResultProfile, postAsyncProfile} from "../../../features/profile/profileSlice";
import {CgPassword} from "react-icons/cg";

const EditPassword = () => {

    const {resultProfile, password} = useSelector(state => state.profile)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (resultProfile.status !== undefined) {
            if (resultProfile.status === "success") {
                dispatch(clearResultProfile())
                Toast.success(resultProfile.message)
                setTimeout(() => {
                    return navigate('/edit-profile')
                }, 1000)
            } else {
                Toast.error(resultProfile.message)
            }
        }
    }, [resultProfile])

    useEffect(() => {
        if (password === false) {
            dispatch(clearProfile())
        }
    }, [password])

    const initialValues = {
        password: "",
        password_new: "",
        password_new_repeat: "",
    }

    let validationSchema = yup.object({
        password: yup.string().required("افزودن گذرواژه قدیمی برای ارسال ضروری است."),
        password_new: yup.string()
            .required('رمز عبور ضروری است')
            .min(8, "حداقل 8 کارکتر برای رمز عبور نیاز است")
            .matches(/[a-z]+/, "یک حرف کوچک نیاز است")
            .matches(/[A-Z]+/, "یک حرف بزرگ نیاز است")
            .matches(/[@$!%*#?&]+/, "یک کارکتر خاص (@$!%*#?&) نیاز است")
            .matches(/\d+/, "یک عدد نیاز است"),
        password_new_repeat: yup.string()
            .required('رمز عبور ضروری است')
            .min(8, "حداقل 8 کارکتر برای رمز عبور نیاز است")
            .matches(/[a-z]+/, "یک حرف کوچک نیاز است")
            .matches(/[A-Z]+/, "یک حرف بزرگ نیاز است")
            .matches(/[@$!%*#?&]+/, "یک کارکتر خاص (@$!%*#?&) نیاز است")
            .matches(/\d+/, "یک عدد نیاز است")
            .oneOf([yup.ref('password'), null], 'گذرواژه ها باید مطابقت داشته باشند'),
    });

    const onSubmit = (values) => {
        dispatch(postAsyncProfile(values))
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit
    })

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                    <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="تغییر پسورد" icons={ <CgPassword className="text-slate-500 dark:text-slate-400" size={20}/>}/>
                <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                    <div className="flex flex-col md:flex-row gap-6 justify-start">
                        <Input name="password" label="رمزعبور قدیمی" formik={formik}/>
                        <Input name="password_new" label="رمزعبور جدید" formik={formik}/>
                        <Input name="password_new_repeat" label="تکرار رمزعبور جدید" formik={formik}/></div>
                    <div className="w-full flex flex-col my-12">
                        <ButtonForm formik={formik} title_button="ذخیره اطلاعات"/>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditPassword;