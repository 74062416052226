import React, {useEffect, useState} from 'react';
import {HiOutlineMenuAlt3} from "react-icons/hi";
import {VscColorMode} from "react-icons/vsc";
import {changeThemeReducer} from "../../../features/theme/themeSlice";
import {useDispatch, useSelector} from "react-redux";
import {Config} from "../../../config/Config";
import {useNavigate} from "react-router-dom";
import {clearLogin} from "../../../features/login/loginSlice";
import {getAsyncProfile} from "../../../features/profile/profileSlice";


const Header = ({valueMenu, setValueMenu}) => {

    const {profile} = useSelector(state => state.profile)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [iconChangeTheme, setIconChangeTheme] = useState(<VscColorMode className="text-gray-100" size={18}/>)

    const ChangeTheme = () => {
        const htmlTag = document.getElementById('htmlTag')
        const theme = htmlTag.classList.contains('dark')
        changeThemeMode(theme)
    }

    const changeThemeMode = (theme) => {
        const htmlTag = document.getElementById('htmlTag')
        if (theme) {
            dispatch(changeThemeReducer(false))
            htmlTag.classList.remove('dark')
            localStorage.setItem('theme', 'light')
            setIconChangeTheme(<VscColorMode size={20}/>)

        } else {
            dispatch(changeThemeReducer(true))
            htmlTag.classList.add('dark')
            localStorage.setItem('theme', 'dark')
            setIconChangeTheme(<VscColorMode className="text-gray-100" size={18}/>)
        }
    }

    useEffect(() => {
        dispatch(getAsyncProfile())
        if (localStorage.getItem('theme') === null) {
            changeThemeMode(false)
        } else if (localStorage.getItem('theme') === 'dark') {
            changeThemeMode(false)
        } else {
            changeThemeMode(true)
        }
    }, [])

    const close = () => {
        localStorage.removeItem("token");
        dispatch(clearLogin())
        setTimeout(() => {
            return navigate("/")
        }, 600)
    }

    return (
        <div className="w-full rounded flex flex-col justify-center items-center bg-white dark:bg-slate-800 mt-4">
            <div className="relative flex flex-col dark:bg-gray-800 shadow-md rounded w-full">
                <div className="flex flex-row items-center justify-between px-6 py-2">
                    <div className="flex flex-row gap-4 items-center">
                        <div onClick={() => setValueMenu(!valueMenu)}>
                            <HiOutlineMenuAlt3 className="text-gray-900 dark:text-gray-100" size={20}/>
                        </div>
                        <div onClick={ ()=> ChangeTheme()} className="p-2">
                            {iconChangeTheme}
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <div className="flex flex-col gap-0.5 text-left text-gray-900 dark:text-gray-100 text-xs">
                            <span>{profile?.role}</span>
                            <span>{profile?.family}</span>
                        </div>
                        <div className="flex h-8 w-8 object-center container object-cover rounded-full bg-gray-100 dark:bg-gray-400 ring-2 ring-blue-500 ring-offset-2 overflow-hidden">
                            <img className="object-center object-cover container"
                                src={profile?.image === null || profile?.image === undefined || profile?.image === ""
                                    ? (profile?.gender === "آقا" ? Config.imageAvatarMan : Config.imageAvatarWoman)
                                    : (Config.imageUrl + profile?.image)}
                            alt=""/>
                        </div>
                        <div onClick={() => close()}
                             className="flex flex-row gap-2 pr-2 cursor-pointer py-1 tex-gray-800 dark:text-gray-200 border-r dark:border-r-gray-200 border-gray-500">
                            <span className="text-sm">خروج</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
