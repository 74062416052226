import React from 'react';

const OperatorButton = ({onClick, icon, label, labelShow, classCustom, disabledAction = false , key}) => {
    return (
        <button key={key} disabled={disabledAction} onClick={onClick} className={classCustom}>
          <span className={`flex flex-row items-center gap-1 text-white text-xs md:text-sm ${!labelShow}`} title={label}>
            {icon}
              <span>{label}</span>
          </span>
        </button>
    );
};

export default OperatorButton;
