import React, {useEffect} from 'react';
import Input from "../../../components/input/Input";
import SelectOptionManual from "../../../components/input/SelectOptionManual";
import {showItems} from "../../../assets/data/Data";
import ButtonForm from "../../../components/button/ButtonForm";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";
import {Toast} from "../../../components/toast/Toast";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import Media from "../../../components/media/Media";
import TextArea from "../../../components/textarea/TextArea";
import {TiImage} from "react-icons/ti";
import {clearResultSlider, clearSlider, getAsyncSlider, postAsyncSlider} from "../../../features/slider/sliderSlice";


const AddSlider = () => {

    const {slider, result, loading} = useSelector(state => state.slider)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {slider_id} = useParams()

    useEffect(() => {
        if (slider_id !== undefined) {
            dispatch(getAsyncSlider({slider_id: slider_id}))
        } else {
            dispatch(clearSlider())
        }
    }, [slider_id])

    useEffect(() => {
        if (result.status !== undefined) {
            if (result.status === "success") {
                dispatch(clearResultSlider())
                Toast.success(result.message)
                setTimeout(() => {
                    return navigate('/slider')
                }, 1000)
            } else {
                Toast.error(result.message)
            }
        }
    }, [result])

    const initialValues = {
        slider_title: "",
        slider_url: "",
        slider_desc: "",
        slider_image: "",
        slider_status: "انتشار",
    }

    let validationSchema = yup.object({
        slider_title: yup.string().required("افزودن عنوان برای ارسال ضروری است."),
    });

    const onSubmit = (values) => {
        dispatch(postAsyncSlider(values))
    }

    const formik = useFormik({
        initialValues: slider || initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit
    })

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard LabelButton="بازگشت" titleLabel="آلبوم جدید"
                                icons={<TiImage className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <form onSubmit={formik.handleSubmit}
                      className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                    <div className="flex flex-col lg:flex-row gap-6 justify-start">
                        <div className="flex flex-col w-full justify-start gap-4 text-gray-400">
                            <SelectOptionManual formik={formik} options={showItems} name="slider_status"
                                                title="وضعیت انتشار" formikAddress={formik.values.slider_status}/>
                            <Input formik={formik} name="slider_title" label="عنوان اسلایدر عکس ها"/>
                            <Input formik={formik} name="slider_url" label="لینک اسلایدر "/>
                        </div>
                        <div className=" lg:w-80 mt-2">
                            <Media
                                single={true}
                                desc="سایز عکس 480x720-px"
                                label="افزودن تصویر اصلی"
                                name="slider_image"
                                formik={formik}
                                formikAddress={formik.values.slider_image}
                            />
                        </div>
                    </div>
                    <TextArea formik={formik} name="slider_desc" label="چکیده متن اسلایدر"/>
                    <div className="w-full flex flex-col my-12 gap-2 ">
                        <ButtonForm
                            formik={formik}
                            title_button="افزودن اسلایدر جدید"
                            title_button_edit="اسلایدر"
                            type={slider_id}
                            loading={loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSlider;
