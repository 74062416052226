import {configureStore} from '@reduxjs/toolkit'
import mediaReducer from './media/mediaSlice'
import newsReducer from './news/newsSlice'
import themeReducer from './theme/themeSlice'
import userReducer from './user/userSlice'
import managersReducer from './managers/managersSlice'
import customerReducer from './customer/customerSlice'
import newsCategoryReducer from './newsCategory/newsCategorySlice'
import contactReducer from './contact/contactSlice'
import loginReducer from './login/loginSlice'
import sliderReducer from './slider/sliderSlice';
import settingReducer from './setting/settingSlice'
import profileReducer from './profile/profileSlice'
import productReducer from './products/productSlice'
import dashboardReducer from './dashboard/DashboardSlice'
import reportsLogReducer from './reportsLog/reportsLogSlisc'
import teamReducer from './team/teamSlice'
import networkReducer from './socialNetwork/socialNetworkSlice'


export const store = configureStore({

    reducer: {

        media: mediaReducer,
        news: newsReducer,
        dashboard: dashboardReducer,
        product: productReducer,
        managers: managersReducer,
        counter: newsReducer,
        themeAdmin: themeReducer,
        userAdmin: userReducer,
        customer: customerReducer,
        newsCategory: newsCategoryReducer,
        contact: contactReducer,
        login: loginReducer,
        slider: sliderReducer,
        setting: settingReducer,
        profile: profileReducer,
        reportsLog: reportsLogReducer,
        team: teamReducer,
        network: networkReducer,

    }
})
