import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/toast/Toast";

export const postAsyncCustomer = createAsyncThunk('customer/postAsyncCustomer', async (payload,
                                                                                   {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "add_customer_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncCustomerList = createAsyncThunk('customer/getAsyncCustomerList', async (payload,
                                                                                 {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_customer_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncCustomer = createAsyncThunk('customer/getAsyncCustomer', async (payload, {rejectWithValue}) => {

    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_customer_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const deleteAsyncCustomer = createAsyncThunk('customer/deleteAsyncCustomer', async (payload,
                                                                           {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "delete_customer_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    customerList: [],
    customer: {},
    customerDelete: {},
    count: 1,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const customerSlice = createSlice ({

    name: "customer",
    initialState,
    reducers: {
        clearResultCustomer: (state, action) => {
            state.result = {}
        },
        clearCustomer: (state, action) => {
            state.customer = false
        },
        clearCustomerDelete: (state, action) => {
            state.customerDelete = {}
        }
    },
    extraReducers: {
        [getAsyncCustomerList.fulfilled]: (state, action) => {
            return {
                ...state,
                customerList: action.payload.result.customer,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null,
            }
        },
        [getAsyncCustomerList.pending]: (state, action) => {
            return {
                ...state,
                customerList: [],
                count: 1,
                page: 1,
                loading: true,
                error: null
            }
        },
        [getAsyncCustomerList.rejected]: (state, action) => {
            return {
                ...state,
                customerList: [],
                count: 1,
                page: 1,
                loading: false,
                error: "error"
            }
        },

        [getAsyncCustomer.fulfilled]: (state, action) => {
            return {
                ...state,
                customer: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncCustomer.pending]: (state, action) => {
            return {
                ...state,
                customer: {},
                loading: true,
                error: null
            }
        },
        [getAsyncCustomer.rejected]: (state, action) => {
            return {
                ...state,
                customer: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncCustomer.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                loading: false,
                error: null,
            }
        },
        [postAsyncCustomer.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: true,
                error: null
            }
        },
        [postAsyncCustomer.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [deleteAsyncCustomer.fulfilled]: (state, action) => {
            return {
                ...state,
                customerDelete: action.payload,
                loading: false,
                error: null,
            }
        },
        [deleteAsyncCustomer.pending]: (state, action) => {
            return {
                ...state,
                customerDelete: {},
                loading: true,
                error: null
            }
        },
        [deleteAsyncCustomer.rejected]: (state, action) => {
            return {
                ...state,
                customerDelete: {},
                loading: false,
                error: ConfigMessage.error
            }
        },
        // [deleteAsyncCustomer.fulfilled]: (state, action) => {
        //     if(action.payload.status === "success"){
        //         state.customerList = state.customerList.filter((value)=> value.customer_id != action.payload.result)
        //         Toast.success(action.payload.message)
        //     }else {
        //         Toast.error(action.payload.message)
        //     }
        // },
    }

})

export const {clearResultCustomer,clearCustomer,clearCustomerDelete} = customerSlice.actions;

export default customerSlice.reducer
