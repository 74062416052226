import React, { useRef, useMemo} from 'react';
import JoditEditor from 'jodit-react';
import MediaTextEditor from "../../components/media/MediaTextEditor";


const JoditEditorText = ({formik, name, formikAddress,media= true}) => {

    const editor = useRef(null);

    const config = useMemo(
        () => ({
            readonly: false,
            height: 600,
            toolbarButtonSize: 'large',
            placeholder: "متن را اینجا وارد کنید ....",
            editorCssClass : 'Jodit-editor-class',
            defaultLineHeight: 2,
        }),
        []
    );

    const addImage = (input) => {
        const value = editor.current.value + `<img src="${input}" alt="" width="300px" style="display: block; margin-left: auto; margin-right: auto;">`
        formik.setFieldValue(name, value)
    }

    return (
        <div className="flex flex-col gap-2">

            {media && (
                <MediaTextEditor
                    label="افزودن تصویر"
                    addImage={addImage}
                />
            )}

            <JoditEditor
                ref={editor}
                value={formikAddress}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => formik.setFieldValue(name, newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {
                }}
            />

        </div>
    );
};

export default JoditEditorText
