import React, {useState} from 'react';
import {TiArrowSortedDown} from "react-icons/ti";
import {BsCircleFill} from "react-icons/bs";
import {NavLink} from "react-router-dom";


const SidebarItemsWithSub = ({title, icon, sub}) => {

    const [open, setOpen] = useState(false)

    const inClick = 'flex flex-row gap-3 items-center hover:bg-gray-400 hover:font-bold hover:text-gray-100 rounded px-4 py-2'
    const click = 'flex flex-row gap-3 items-center bg-blue-500 hover:font-bold text-gray-100 rounded px-4 py-2'

    return (

        <div className="flex flex-col overflow-hidden">
            <div onClick={() => {
                setOpen(!open)
            }} className="flex flex-row items-center justify-between hover:cursor-pointer rounded px-4 py-3 hover:bg-gray-400 hover:font-bold hover:text-gray-100">
                <div className="flex flex-row items-center">
                    {icon}
                    <span className="px-4">{title}</span>
                </div>
                <TiArrowSortedDown className={`${open ? "rotate-0" : "!rotate-90"} transition-all duration-500 ease-in-out`}/>
            </div>
            <div className={`${open ? "max-h-96" : "max-h-0"} transition-all duration-500 ease-in-out`}>
                <div className="flex flex-col gap-1 p-3">
                    {sub && sub.map((value, index) => {
                        return (
                            <NavLink end to={value.link} key={index} className={(navData) => (navData.isActive ? click : inClick)}>
                                <BsCircleFill size={8}/>
                                <span className="text-sm">{value.title}</span>
                            </NavLink>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default SidebarItemsWithSub;
