import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import formurlencoded from "form-urlencoded";


export const getAsyncLogAllList = createAsyncThunk("reportsLog/getAsyncLogAllList", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, formurlencoded({
            action: "list_log_admin_admin",
            row_per_page: payload.row_per_page,
            page_number: payload.page_number,

        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = res.data
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncLogList = createAsyncThunk("reportsLog/getAsyncLogList", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, formurlencoded({
            action: "get_log_admin_admin",
            log_admin_id: payload.log_admin_id,

        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = res.data
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {

    resultLogAllList: [],
    resultLogAll: [],
    page: 1,
    count: 1,
    isError: null,
    isLoading: false,
    status: "idle",

}

const reportsInformationSlice = createSlice({

    name: 'reportsLog',
    initialState,
    reducers: {},
    extraReducers: {

        [getAsyncLogAllList.fulfilled]: (state, action) => {
            return {
                ...state,
                resultLogAllList: action.payload.result.log,
                count: action.payload.result.count,
                page: action.payload.result.page,
                isLoading: false,
                isError: null
            }
        },
        [getAsyncLogAllList.pending]: (state, action) => {
            return {...state, resultLogAllList: [],  count: 1, page: 1,isLoading: true, isError: null}
        },
        [getAsyncLogAllList.rejected]: (state, action) => {
            return {...state, resultLogAllList: [], count: 1, page: 1, isLoading: false, isError: ConfigMessage.isError}
        },

        [getAsyncLogList.fulfilled]: (state, action) => {
            return {
                ...state,
                resultLogAll: action.payload.result,
                isLoading: false,
                isError: null
            }
        },
        [getAsyncLogList.pending]: (state, action) => {
            return {...state, resultLogAll: [], isLoading: true, isError: null}
        },
        [getAsyncLogList.rejected]: (state, action) => {
            return {...state, resultLogAll: [], isLoading: false, isError: ConfigMessage.error}
        },
    }
})

export const {} = reportsInformationSlice.actions
export default reportsInformationSlice.reducer
