import React from 'react';

const TextArea = ({formik,name, label, rows, value, disabled = true, classTextarea = "", dir = 'rtl'}) => {

    return (

        <div className="flex flex-col gap-1 w-full">
            {disabled && (<div className="flex flex-col gap-2">
                <label htmlFor={name} className="text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
                <textarea dir={dir} {...formik.getFieldProps(name)} rows={rows} id={name} name={name}
                          className={`dark:text-gray-100 bg-gray-50 dark:bg-[#1f2937] text-gray-900 w-full dark:border-gray-600 border border-gray-300 
                          focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg p-3 ${classTextarea}`}/>
            </div>)}
            {formik.errors[name] && formik.touched[name] && (
                <p className="mt-2 text-pink-600 text-xs">
                    {formik.errors[name]}
                </p>
            )}
        </div>
    );
};

export default TextArea;
