import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import * as yup from "yup";
import ButtonForm from "../../../components/button/ButtonForm";
import Input from "../../../components/input/Input";
import SelectOptionManual from "../../../components/input/SelectOptionManual";
import {showItems} from "../../../assets/data/Data";
import {useDispatch, useSelector} from "react-redux";
import {clearNews, getAsyncNews} from "../../../features/news/newsSlice";
import {useNavigate, useParams} from "react-router-dom";
import {getAsyncNewsCategoryListForAdd} from "../../../features/newsCategory/newsCategorySlice";
import {Toast} from "../../../components/toast/Toast";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import JoditEditorText from "../../../components/editor/JoditEditorText";
import Media from "../../../components/media/Media";
import TextArea from "../../../components/textarea/TextArea";
import {TiChartLineOutline, TiPen} from "react-icons/ti";
import axios from "axios";
import {Config} from "../../../config/Config";
import {arrayObjectToFromDataNT} from "../../../utility/arrayObjectToFromData";
import {RiMailSendLine} from "react-icons/ri";
import {GenerateUrl} from "../../../utility/generateUrl";


const AddNews = () => {

    const [uploadFile, setUploadFile] = useState(0)
    const {newsCategoryListSelect} = useSelector(state => state.newsCategory)
    const {news, loading} = useSelector(state => state.news)
    const [disabled, setDisabled] = useState(true)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {news_id} = useParams()

    useEffect(() => {
        dispatch(getAsyncNewsCategoryListForAdd())
    }, [])

    const initialValues = {
        news_title: "",
        news_body: "",
        news_url: "",
        news_body_abstract: "",
        news_category_id: "",
        news_image: "",
        news_seo_title: "",
        news_seo_description: "",
        news_gallery_image: [],
        news_publish: "انتشار"
    }

    let validationSchema = yup.object({
        news_title: yup.string().required("افزودن عنوان برای ارسال ضروری است."),
        news_body_abstract: yup.string().required("افزودن چکیده خبر برای ارسال ضروری است."),
        news_body: yup.string().required("افزودن متن خبر برای ارسال ضروری است."),
    });

    const onSubmit = (values) => {
        if (formik.values.news_image !== null && formik.values.news_gallery_image.length > 0) {
            UploadMediaAxios(values)
        } else {
            Toast.warning("انتخاب تصاویر برای ارسال ضروری می باشد")
        }
    }

    const formik = useFormik({
        initialValues: news || initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit
    })

    useEffect(() => {
        dispatch(getAsyncNewsCategoryListForAdd())
        if (news_id !== undefined) {
            dispatch(getAsyncNews({news_id: news_id}))
        } else {
            dispatch(clearNews())
        }
    }, [news_id])

    useEffect(() => {
        if (news_id !== undefined) {
            formik.setFieldValue("news_url", news.news_url)
        } else {
            formik.setFieldValue("news_url", GenerateUrl(formik.values.news_title?.trim()))
        }
    }, [news_id,formik.values.news_title])

    useEffect(() => {
          formik.setFieldValue("news_url", GenerateUrl(formik.values.news_url?.trim()))
    }, [formik.values.news_url])

    const config = {
        onUploadProgress: (event) => {
            const {loaded, total} = event;
            let number = Math.floor((loaded * 100) / total)
            if (number <= 100) {
                setUploadFile(number)
            }
        },
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }

    const UploadMediaAxios = async (payload) => {
        const {
            data: {
                status,
                message
            }
        } = await axios.post(Config.api, arrayObjectToFromDataNT(payload, "add_news_Admin"), config)
        if (status === "success") {
            Toast.success(message)
            setTimeout(() => {
                return navigate(`/list-news`)
            }, 1000)
            formik.resetForm()
        } else {
            Toast.error(message)
        }
    }

    return (

        <div className="flex flex-col gap-2">
            <div className="bg-gray">
                <LabelDashboard LabelButton="بازگشت" titleLabel="خبر جدید" icons={<TiPen className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <form onSubmit={formik.handleSubmit}>
                    <div
                        className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-4">
                        <div className="flex flex-col gap-1 mt-5">
                            <div  className="flex flex-col gap-5 w-full border-2 border-gray-100 dark:border-gray-500 p-6 rounded-lg">
                                <span className="text-xs text-blue-700 dark:text-blue-300 font-bold">برای انجام ویرایش url به جای فاصله از خط تیره (-) استفاده کنید.</span>
                             <div className="flex flex-col md:flex-row gap-5">
                                 <Input disabled={disabled} formik={formik} name="news_url" label="آدرس url"/>
                                 <div onClick={() => setDisabled(false)}
                                      className="flex py-2 px-8 items-center justify-center mt-6 cursor-pointer bg-blue-600 dark:bg-blue-600 text-sm text-gray-100 rounded font-bold">ویرایش
                                 </div>
                             </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row  gap-6 justify-start py-8">
                            <div className="flex flex-col w-full justify-start gap-4 text-gray-400">
                                <Input formik={formik} name="news_title" label="عنوان خبر"/>
                                <SelectOptionManual formik={formik} options={newsCategoryListSelect}
                                                    name="news_category_id"
                                                    title="انتخاب دسته" formikAddress={formik.values.news_category_id}/>
                                <SelectOptionManual formik={formik} options={showItems} name="news_publish"
                                                    title="وضعیت انتشار" formikAddress={formik.values.news_publish}/>
                            </div>
                            <div className="lg:w-80">
                                <Media
                                    single={true}
                                    label="افزودن تصویر اصلی"
                                    desc="سایز عکس 480x720-px"
                                    name="news_image"
                                    formik={formik}
                                    formikAddress={formik.values.news_image}
                                />
                            </div>
                        </div>
                        <TextArea formik={formik} name="news_body_abstract" label="چکیده خبر"/>
                        <Media
                            label="افزودن تصاویر خبر"
                            name="news_gallery_image"
                            formik={formik}
                            formikAddress={formik.values.news_gallery_image}/>
                        <div className="flex flex-col gap-2">
                            <span className="text-sm text-gray-900 dark:text-gray-300 font-medium">متن خبر</span>
                            <div className="flex flex-col w-full justify-start gap-1">
                                <JoditEditorText formik={formik} name="news_body"
                                                 formikAddress={formik.values.news_body}/>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mt-5">
                            <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="سئو محصول"
                                            icons={<TiChartLineOutline className="text-slate-500 dark:text-slate-400"
                                                                       size={25}/>}/>
                            <div
                                className="flex flex-col gap-5 w-full border-2 border-gray-100 dark:border-gray-500 p-6 rounded-lg">
                                <Input formik={formik} name="news_seo_title" label="عنوان سئو"/>
                                <TextArea formik={formik} name="news_seo_description" label="توضیحات"/>
                            </div>
                        </div>
                        {uploadFile > 0 &&
                            <div className="w-full bg-gray-50 dark:bg-gray-800 p-6 rounded-xl">
                                <div
                                    className="flex flex-col gap-2 border border-blue-600 rounded-xl w-full px-10 py-4 items-center justify-center">
                                    <div className="flex flex-row gap-2 items-center justify-center">
                                        <RiMailSendLine className="text-blue-600" size={30}/>
                                        <span className="text-blue-600 font-bold text-lg">در حال ثبت اطلاعات</span>
                                    </div>
                                    <div className="w-full my-5 bg-gray-300 dark:bg-gray-700 rounded-full">
                                        <div
                                            className="bg-blue-500 p-0.5 text-center text-sm font-medium leading-none text-white rounded-full"
                                            style={{width: ` ${uploadFile}%`}}>
                                            {uploadFile}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="w-full flex flex-col my-12 mt-10">
                            <ButtonForm
                                formik={formik}
                                title_button="ارسال خبر"
                                title_button_edit="ویرایش خبر"
                                type={news_id}
                                loading={loading}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AddNews;
