import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAsyncLogAllList} from "../../../features/reportsLog/reportsLogSlisc";
import {persianDateNT} from "../../../utility/persianDateNT";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import {FiEye} from "react-icons/fi";
import DataTable from "../../../components/dataTable/DataTable";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {TiThListOutline} from "react-icons/ti";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";


const Reports = () => {

    const [perPage, setPerPage] = useState(10);
    const [numberPage, setNumberPage] = useState(1);

    const {resultLogAllList, isLoading, isError, count, page} = useSelector(state => state.reportsLog)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncLogAllList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage, numberPage])

    const columns = [

        {
            name: "نام کاربر",
            selector: row => row.full_name,
        },
        {
            name: "ای پی",
            selector: row => row.ip,
        },
        {
            name: "شماره موبایل",
            selector: row => row.mobile,
        },
        {
            name: "تاریخ ثبت",
            selector: row => persianDateNT.date(row.log_time),
        },
        {
            name: "عملیات",
            style: {
                width: '100px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center gap-2">
                        <OperatorLink to={`/reports-admin/${row.log_admin_id}`} icon={<FiEye size={15}/>} label="مشاهده"
                                      classCustom="btn-green"/>
                    </div>
                </div>
            )
        }
    ];
    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="کلیه گزارشات"
                                icons={<TiThListOutline className="text-slate-500 dark:text-slate-400" size={22}/>}/>
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg lg:px-10 py-8">
                    <div className="flex flex-col gap-1">
                        <div>
                            {isLoading && (<Loading/>)}
                            {isError && (<Error error={isError}/>)}
                            {!isLoading && isError === null && (
                                <DataTable
                                    columns={columns}
                                    data={resultLogAllList}
                                    count={count}
                                    page={page}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                    numberPage={numberPage}
                                    setNumberPage={setNumberPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


export default Reports;