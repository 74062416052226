import React from "react";
import {BsCircleFill} from "react-icons/bs";
import {
    HiOutlineClipboardList,
    HiOutlineCog, HiOutlineDuplicate,
    HiOutlineHome, HiOutlineIdentification, HiOutlineLightBulb,
    HiOutlineNewspaper, HiOutlinePhoneIncoming,
    HiOutlinePhotograph,
    HiOutlineTicket,
    HiOutlineUser, HiOutlineUserGroup
} from "react-icons/hi";


export const AdminSidebar = [
    {
        id: "1",
        title: "پیشخوان",
        icon: <HiOutlineHome size={20}/>,
        link: "dashboard",
        sub: []
    },
    {
        id: "2",
        title: "اخبار",
        icon: <HiOutlineNewspaper size={20}/>,
        sub: [
            {id: "21", title: "افزودن خبر جدید", link: "/add-news"},
            {id: "22", title: "لیست خبرها", link: "/list-news"},
            {id: "23", title: "دسته بندی", link: "/category-news"}
        ]
    },
    {
        id: "3",
        title: "اسلایدر",
        icon: <HiOutlinePhotograph size={20}/>,
        sub: [
            {id: "31", title: "افزودن اسلایدر جدید", link: "/add-slider"},
            {id: "32", title: "لیست اسلایدر ها", link: "/slider"}
        ]
    },
    {
        id: "4",
        title: "مقالات",
        icon: <HiOutlineTicket size={20}/>,
        sub: [
            {id: "41", title: "افزودن مقاله جدید", link: "/add-products"},
            {id: "42", title: "لیست مقالات", link: "/list-products"},
        ]
    },
    {
        id: "5",
        title: "افزودن رسانه",
        icon: <HiOutlinePhotograph size={20}/>,
        link: "add-gallery",
        sub: []
    },
    {
        id: "6",
        title: "مشتریان",
        icon: <HiOutlineDuplicate size={20}/>,
        sub: [
            {id: "61", title: "افزودن مشتریان جدید", link: "/add-customer"},
            {id: "62", title: "لیست مشتریان", link: "/list-customer"},
        ]
    },
    {
        id: "7",
        title: "ارتباط با ما",
        icon: <HiOutlinePhoneIncoming size={20}/>,
        sub: [
            // {id: "72", title: "پیام ها", icon: <BsCircleFill size={8}/>, link: "/response"},
            {id: "71", title: "لیست پیام ها", icon: <BsCircleFill size={8}/>, link: "/contact-us"},
        ]
    },
    {
        id: "8",
        title: "معرفی تیم",
        icon: <HiOutlineUserGroup size={20}/>,
        sub: [
            {id: "81", title: "افزودن اعضاء جدید", link: "/work-team"},
            {id: "82", title: "لیست اعضاء استیل", link: "/list-work-team"},

        ]
    },
    {
        id: "9",
        title: "مدیران",
        icon: <HiOutlineUser size={20}/>,
        link: "/manager",
        sub: []
    },
    {
        id: "10",
        title: "گزارشات",
        icon: <HiOutlineClipboardList size={20}/>,
        link: "/reports-all",
        sub: []
    },
    {
        id: "11",
        title: "تنظیمات",
        icon: <HiOutlineCog size={20}/>,
        sub: [
            {id: "111", title: "درباره ما", icon: <BsCircleFill size={8}/>, link: "/about"},
            {id: "112", title: "ارتباط با ما", icon: <BsCircleFill size={8}/>, link: "/contact"},
            {id: "113", title: "شبکه های اجتماعی", icon: <BsCircleFill size={8}/>, link: "/social-network",},
            {id: "114", title: "تنظیمات", icon: <BsCircleFill size={8}/>, link: "/setting"},
        ]
    },
    {
        id: "12",
        title: "پروفایل",
        icon: <HiOutlineIdentification size={20}/>,
        sub: [
            {id: "111", title: "پروفایل", icon: <BsCircleFill size={8}/>, link: "/edit-profile"},
            {id: "112", title: "تغییر پسورد", icon: <BsCircleFill size={8}/>, link: "/change-password"},
        ]
    },
    {
        id: "13",
        title: "درباره نیلفام تک",
        icon: <HiOutlineLightBulb size={20}/>,
        link: "/about-software",
        sub: []
    }
]

export const showItems = [
    {id: 1, label: "انتشار", value: "انتشار"},
    {id: 2, label: "پیش نویس", value: "پیش نویس"}
]

export const showNameCompany = [
    {id: 1, label: "شرکت خصوصی", value: "شرکت خصوصی"},
    {id: 2, label: "شرکت دولتی", value: "شرکت دولتی"}
]

export const showItemsCategory = [
    {id: 1, label: "ملزومات", value: ""},
    {id: 2, label: "ورق استیل", value: ""},
    {id: 3, label: "رول استیل", value: ""},
    {id: 4, label: "میله استیل", value: ""}
]


export const managerGender = [
    {id: 1, label: "آقا", value: "آقا"},
    {id: 2, label: "خانم", value: "خانم"}
]

export const settingStatus = [
    {id: 1, label: "فعال", value: "true"},
    {id: 2, label: "غیر فعال", value: "false"}
]

export const managerStatus = [
    {id: 1, label: "فعال", value: "active"},
    {id: 2, label: "غیر فعال", value: "inactive"}
]

export const managerRole = [
    {id: 1, label: "مدیر کل", value: "مدیر کل"},
    {id: 2, label: "کاربر", value: "کاربر"},
]

