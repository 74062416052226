import React, {useEffect} from 'react';
import SidebarItem from "../../sidebar/SidebarItem";
 import {AdminSidebar} from "../../../assets/data/Data";
import {Config} from "../../../config/Config";
import {IoClose} from "react-icons/io5";
import {useDispatch, useSelector} from "react-redux";
import {changeThemeReducer} from "../../../features/theme/themeSlice";
import SidebarItemsWithSub from "../../sidebar/SidebarItemsWithSub";
import {postAsyncDetailsSite} from "../../../features/login/loginSlice";


const Sidebar = ({hiddenSidebar, setHiddenSidebar, widthSidebar, mobileStatus}) => {

    const {dark} = useSelector((state) => state.themeAdmin)
    const {DetailsSite} = useSelector(state => state.login)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(postAsyncDetailsSite())
    } ,[])

    useEffect(() => {
        dispatch(changeThemeReducer())
    }, [])

    return (
        <div className="">
            <div className={`bg-white min-h-screen h-full dark:bg-gray-800 rounded overflow-hidden transition-all ease-in-out duration-700 
                absolute lg:relative top-0 bottom-0 z-40 ${hiddenSidebar ? "w-0" : widthSidebar}`}>
                <div className="flex flex-col gap-8 p-4 pt-8 relative">
                    {mobileStatus && (
                        <div className="absolute left-2 top-2 dark:text-gray-100 text-gray-900 text-2xl p-5"
                             onClick={() => setHiddenSidebar(!hiddenSidebar)}>
                            <IoClose size={20}/>
                        </div>
                    )}
                    <div
                        className="flex flex-row gap-4 items-center justify-center text-slate-900 dark:text-slate-100 py-4 px-8">
                        {DetailsSite.setting_logo_slider
                            ?  (<img className="h-18 w-36" alt="" src={Config.imageUrl + DetailsSite.setting_logo_slider}/> )
                            : (<img className="h-18 w-36" alt=""  src={dark ? (Config.imageLogoDark) : (Config.imageLogo)}/>)
                        }
                    </div>
                    <div className="flex flex-col gap-1 text-slate-900 dark:text-slate-100 font-bold">
                        {AdminSidebar && AdminSidebar.map((value, index) => {
                            return value.sub.length > 0 ? (
                                <SidebarItemsWithSub key={index} title={value.title} icon={value.icon} sub={value.sub}/>
                            ) : (
                                <SidebarItem key={index} title={value.title} icon={value.icon} link={value.link}/>
                            )
                        })}
                    </div>
                </div>
            </div>
            {
                mobileStatus && !hiddenSidebar && (
                    <button onClick={() => {
                        setHiddenSidebar(!hiddenSidebar)
                    }} className="bg-gray-500/40 dark:bg-gray-800/40 backdrop-blur-md absolute top-0 bottom-0 w-full h-full z-30 "></button>
                )
            }
        </div>
    );
};

export default Sidebar;
