import React from 'react';
import {BiLoaderAlt} from "react-icons/bi";

const Loading = ({loadingPage}) => {

    return (

        <div>
            {loadingPage && (
                <div
                    className="absolute w-full h-full bg-gray-200 dark:bg-gray-800 z-50 flex flex-col items-center pt-56">
                    <BiLoaderAlt size={40} className="text-blue-600 animate-spin"/>
                    <span
                        className="text-sm font-bold dark:text-gray-200 py-6 text-gray-700">در حال دریافت اطلاعات</span>
                </div>
            )}
        </div>

    );
};

export default Loading;