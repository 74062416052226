import React from 'react';
import {Navigate,Outlet } from "react-router-dom";


const useAuth = () => {
    const token = localStorage.getItem('token');
    return (token !== undefined && token !== null && token.length > 20)
}

const PublicRoutes = (props) => {
    const auth = useAuth();
    return auth ? <Navigate to="dashboard"/> : <Outlet/>
};

export default PublicRoutes;
