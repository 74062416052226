import React, {useEffect} from 'react';
import Input from "../../../components/input/Input";
import ButtonForm from "../../../components/button/ButtonForm";
import * as yup from "yup";
import {useFormik} from "formik";
import SelectOptionManual from "../../../components/input/SelectOptionManual";
import {managerGender, managerRole, managerStatus} from "../../../assets/data/Data";
import {useDispatch, useSelector} from "react-redux";
import {
    clearAdmins,
    clearResultManager,
    getAsyncManagers,
    postAsyncManagers
} from "../../../features/managers/managersSlice";
import {useNavigate, useParams} from "react-router-dom";
import {Toast} from "../../../components/toast/Toast";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {TiUserAddOutline} from "react-icons/ti";
import Media from "../../../components/media/Media";


const AddManager = () => {

    const {admins, result, loading} = useSelector(state => state.managers)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getAsyncManagers({id: id}))
        } else {
            dispatch(clearAdmins())
        }
    }, [id])

    const initialValues = {
        name: "",
        family: "",
        username: "",
        mobile: "",
        password: "",
        password_repeat: "",
        gender: "آقا",
        role: "",
        image: "",
        admin_status: "",
    }

    let validationSchema = yup.object({
        name: yup.string().required("وارد کردن نام ضروری است."),
        family: yup.string().required("افزودن نام خانوادگی برای ارسال ضروری است."),
        username: yup.string().required('افزودن نام کاربری برای ارسال ضروری است.'),
        password: yup.string()
            .when([], {
                is: () => !id,
                then: yup.string()
                    .required('رمز عبور ضروری است')
                    .min(8, "حداقل 8 کارکتر برای رمز عبور نیاز است")
                    .matches(/[a-z]+/, "یک حرف کوچک نیاز است")
                    .matches(/[A-Z]+/, "یک حرف بزرگ نیاز است")
                    .matches(/[@$!%*#?&]+/, "یک کارکتر خاص (@$!%*#?&) نیاز است")
                    .matches(/\d+/, "یک عدد نیاز است"),
                otherwise: yup.string().notRequired(),
            }),
        password_repeat: yup.string()
            .when([], {
                is: () => !id,
                then: yup.string()
                    .required('رمز عبور ضروری است')
                    .min(8, "حداقل 8 کارکتر برای رمز عبور نیاز است")
                    .matches(/[a-z]+/, "یک حرف کوچک نیاز است")
                    .matches(/[A-Z]+/, "یک حرف بزرگ نیاز است")
                    .matches(/[@$!%*#?&]+/, "یک کارکتر خاص (@$!%*#?&) نیاز است")
                    .matches(/\d+/, "یک عدد نیاز است")
                    .oneOf([yup.ref('password'), null], 'گذرواژه ها باید مطابقت داشته باشند'),
                otherwise: yup.string().notRequired(),
            })
    });

    const onSubmit = (value) => {
        dispatch(postAsyncManagers(value))
    }

    const formik = useFormik({
        initialValues: admins || initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit
    })

    useEffect(() => {
        if (result.status !== undefined) {
            if (result.status === "success") {
                dispatch(clearResultManager())
                Toast.success(result.message)
                setTimeout(() => {
                    return navigate('/manager')
                }, 1000)
            } else {
                Toast.error(result.message)
            }
        }
    }, [result])

    return (

        <div className="bg-gray">
            <LabelDashboard titleLabel="افزودن مدیر جدید" LabelButton="بازگشت"
                            icons={<TiUserAddOutline className="text-slate-500 dark:text-slate-400" size={25}/>}/>
            <form onSubmit={formik.handleSubmit}
                  className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="flex flex-col gap-5">
                        <SelectOptionManual formik={formik} options={managerGender} name="gender" title="جنسیت"
                                            formikAddress={formik.values.gender}/>
                        <SelectOptionManual formik={formik} options={managerStatus} name="admin_status" title="وضعیت"
                                            formikAddress={formik.values.admin_status}/>
                        <SelectOptionManual formik={formik} options={managerRole} name="role" title="نقش"
                                            formikAddress={formik.values.role}/>
                        <Input name="name" label="نام مدیر" formik={formik}/>
                        <Input name="family" label="نام خانوادگی مدیر" formik={formik}/>
                        <Input name="mobile" label="شماره موبایل" formik={formik}/>
                    </div>
                    <div className="flex flex-col gap-4">
                        <Input disabled={id ? true : false}
                               classColor="text-gray-500/70 dark:text-gray-500/80 bg-white dark:bg-gray-900"
                               name="username" label="نام کاربری" formik={formik}/>
                        <div className={`flex flex-col gap-4 ${id ? "hidden" : "block"}`}>
                            <Input name="password" label="رمز عبور" formik={formik}/>
                            <Input name="password_repeat" label="تکرار رمز عبور" formik={formik}/>
                        </div>
                        <Media
                            single={true}
                            label="انتخاب تصویر"
                            desc="انتخاب تصویر کاربر"
                            name="image"
                            formik={formik}
                            formikAddress={formik.values.image}
                            classDiv="h-56 mt-10"
                        />
                    </div>
                </div>
                <div className="flex flex-col my-12">
                    <ButtonForm formik={formik}
                                title_button="افزودن مدیر"
                                title_button_edit="ویرایش مدیر"
                                type={id}
                                loading={loading}/>
                </div>
            </form>
        </div>
    );
};

export default AddManager;
