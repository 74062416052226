import React from 'react';
import {BiLoaderAlt} from "react-icons/bi";

const Loading = () => {
    return (
        <div className="dark:text-gray-300 text-gray-700 flex flex-col gap-4 justify-center items-center mt-10 text-lg">
            <BiLoaderAlt size={50} className="text-blue-500 animate-spin"/>
            <span>در حال دریافت اطلاعات</span>
        </div>
    );
};

export default Loading;
