import React, {useEffect, useState} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";
import DataTable from "../../../components/dataTable/DataTable";
import {useDispatch, useSelector} from "react-redux";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import {TbEdit} from "react-icons/tb";
import OperatorButton from "../../../components/dataTable/OperatorButton";
import {MdDelete} from "react-icons/md";
import Alert from "../../../components/toast/Alert";
import {TiThListOutline} from "react-icons/ti";
import {clearCustomerDelete, deleteAsyncCustomer, getAsyncCustomerList} from "../../../features/customer/customerSlice";
import {persianDateNT} from "../../../utility/persianDateNT";
import {Toast} from "../../../components/toast/Toast";


const ListCustomers = () => {

    const [perPage, setPerPage] = useState(10);
    const [numberPage, setNumberPage] = useState(1);

    const {customerList, customerDelete, loading, error, count, page} = useSelector(state => state.customer)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncCustomerList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage, numberPage])

    const columns = [
        {
            name: "نام شرکت",
            selector: row => row.customer_title,
        },
        {
            name: "وضعیت",
            selector: row => row.customer_publish
        },
        {
            name: "تاریخ ثبت",
            selector: row => persianDateNT.dateWithoutTime(row.customer_create_time)
        },
        {
            name: "عملیات",
            style: {
                width: '220px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <OperatorLink to={'/add-customer/' + row.customer_id} icon={<TbEdit size={20}/>}
                                  label="ویرایش"
                                  classCustom="btn-blue"/>
                    <OperatorButton onClick={() => showAlert(row.customer_id)} icon={<MdDelete size={20}/>}
                                    label="حذف" classCustom={row.customer_status === "حذف" ? "btn-disable" : "btn-red"}
                                    disabledAction={row.customer_status === "حذف"}/>
                </div>
            )
        }
    ];

    //? for toast delete ///
    const [showAlertPage, setShowAlertPage] = useState(false)
    const [showAlertValue, setShowAlertValue] = useState("")
    const showAlert = (input) => {
        setShowAlertPage(true)
        setShowAlertValue(input)
    }

    const removeFunc = () => {
        dispatch(deleteAsyncCustomer({customer_id: showAlertValue}))
        setShowAlertPage(false)
    }

    useEffect(() => {
        if (customerDelete.status !== undefined) {
            if (customerDelete.status === "success") {
                dispatch(getAsyncCustomerList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                dispatch(clearCustomerDelete())
                Toast.success(customerDelete.message)
                setShowAlertPage(false)
            } else {
                dispatch(clearCustomerDelete())
                Toast.error(customerDelete.message)
            }
        }
    }, [customerDelete, perPage, numberPage])

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="لیست مشتریان"
                                icons={<TiThListOutline className="text-slate-500 dark:text-slate-400" size={20}/>}/>
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg lg:px-10 py-8">
                    <div className="flex flex-col gap-1">
                        <div>
                            {loading && (<Loading/>)}
                            {error && (<Error error={error}/>)}
                            {!loading && error === null && (
                                <DataTable
                                    to="/add-customer"
                                    nameTitle="افزودن مشتری جدید"
                                    columns={columns}
                                    data={customerList}
                                    count={count}
                                    page={page}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                    numberPage={numberPage}
                                    setNumberPage={setNumberPage}
                                />
                            )}
                        </div>
                        <Alert
                            showAlertPage={showAlertPage}
                            setShowAlertPage={setShowAlertPage}
                            title="اخطار"
                            label="آیا برای حذف مطمئن هستید‌؟"
                            onclick={removeFunc}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ListCustomers;
