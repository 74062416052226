import React, {useEffect, useState} from 'react';
import Header from "../../components/layout/homeAdmin/Header";
import Sidebar from "../../components/layout/homeAdmin/Sidebar";
import {Outlet} from "react-router-dom";
import ToastContainer from "../../components/toast/ToastContainer";
import useWindowSize from "../../customHook/useWindowSize";
import Loading from "../../components/layout/homeAdmin/Loading";


const LayoutHomeAdmin = () => {

    const {height, width} = useWindowSize();
    const [hiddenSidebar, setHiddenSidebar] = useState(false)
    const [mobileStatus, setMobileStatus] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true)
    const [widthSidebar, setWidthSidebar] = useState("w-96")

    useEffect(() => {
        if (width > 1024) {
            setHiddenSidebar(false)
            setMobileStatus(false)
        } else {
            setHiddenSidebar(true)
            setMobileStatus(true)
            setWidthSidebar("w-80")
        }
        setTimeout(() => {
            setLoadingPage(false)
        }, 700)
    }, [width])

    return (

        <div className="flex flex-col bg-gray-200 dark:bg-gray-900 min-h-screen relative">
            <Loading loadingPage={loadingPage}/>
            <div className="flex flex-row lg:gap-6">
                {/*Sidebar*/}
                <Sidebar hiddenSidebar={hiddenSidebar} widthSidebar={widthSidebar} setHiddenSidebar={setHiddenSidebar}
                         mobileStatus={mobileStatus}/>
                <div className="flex flex-col gap-6 w-full pl-4 lg:pl-6 pr-4 lg:pr-0 mb-52">
                    {/*TopBar*/}
                    <div>
                        <Header setValueMenu={setHiddenSidebar} valueMenu={hiddenSidebar}/>
                    </div>
                    {/*content*/}
                    <div>
                        <Outlet/>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>

    );
};

export default LayoutHomeAdmin;
