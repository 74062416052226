import React, {useEffect, useState} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";
import DataTable from "../../../components/dataTable/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {persianDateNT} from "../../../utility/persianDateNT";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import {TbEdit} from "react-icons/tb";
import {getAsyncContactList} from "../../../features/contact/contactSlice";
import {TiThListOutline} from "react-icons/ti";

const ContactUs = () => {

    const [perPage, setPerPage] = useState(10);
    const [numberPage, setNumberPage] = useState(1);

    const {contactList, loading, error, count, page} = useSelector(state => state.contact)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncContactList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage,numberPage])

    const columns = [

        {
            name: "نام",
            selector: row => row.contact_name,
        },
        {
            name: "موبایل",
            style: {
                width: '200px'
            },
            selector: row => row.contact_mobile,
        },
        {
            name: "ایمیل",
            style: {
                width: '240px'
            },
            selector: row => row.contact_email,
        },
        {
            name: "وضعیت",
            style: {
                width: '160px'
            },
            selector: row => row.contact_answered_status === "پاسخ نداده" ?
                <div className="text-red-500 font-bold">پاسخ نداده</div>
                :
                <div className="text-green-500 font-bold">پاسخ داده</div>,
        },
        {
            name: "تاریخ ثبت",
            style: {
                width: '200px'
            },
            selector: row => persianDateNT.dateWithoutTime(row.contact_create_time)
            // selector: row => (<span className="" dir="ltr">{row.message_create_time}</span>)
        },
        {
            name: "عملیات",
            style: {
                width: '180px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center gap-2">
                        <OperatorLink to={'/response/' + row.contact_id} icon={<TbEdit size={20}/>}
                                      label="مشاهده و پاسخ" classCustom="btn-blue"/>
                    </div>
                </div>
            )
        }
    ];

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="لیست پیام های" icons={ <TiThListOutline className="text-slate-500 dark:text-slate-400" size={22}/>}/>
              <div className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg lg:px-10 py-8">
                  <div className="flex flex-col gap-1">
                      <div>
                          {loading && (<Loading/>)}
                          {error && (<Error error={error}/>)}
                          {!loading && error === null && (
                              <DataTable
                                  Button={false}
                                  columns={columns}
                                  data={contactList}
                                  count={count}
                                  page={page}
                                  perPage={perPage}
                                  setPerPage={setPerPage}
                                  numberPage={numberPage}
                                  setNumberPage={setNumberPage}
                              />
                          )}
                      </div>
                  </div>
              </div>
            </div>
        </div>
    );
};

export default ContactUs;
