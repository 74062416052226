import React, {useEffect} from 'react';
import {useFormik} from "formik";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import Input from "../../../components/input/Input";
import ButtonForm from "../../../components/button/ButtonForm";
import {useDispatch, useSelector} from "react-redux";
import {
    clearResultSetting,
    editAsyncSetting,
    getAsyncSetting
} from "../../../features/setting/settingSlice";
import {Toast} from "../../../components/toast/Toast";
import {settingStatus} from "../../../assets/data/Data";
import SelectOptionManual from "../../../components/input/SelectOptionManual";
import {TiCogOutline} from "react-icons/ti";
import Media from "../../../components/media/Media";
import TextArea from "../../../components/textarea/TextArea";


const Setting = () => {

    const {setting, result} = useSelector(state => state.setting)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncSetting())
    }, [dispatch])

    useEffect(() => {
        if (result.status !== undefined) {
            if (result.status === "success") {
                dispatch(clearResultSetting())
                Toast.success(result.message)
            } else {
                Toast.error(result.message)
            }
        }
    }, [result])

    const initialValues = {

        setting_title_panel: "",
        setting_logo_panel: "",
        setting_logo_slider: "",
        setting_title: "",
        setting_logo_site: "",
        setting_allow_admin_to_enter: true,
        short_description: "",

    }

    const onSubmit = (values) => {
        dispatch(editAsyncSetting(values))
    }

    const formik = useFormik({
        initialValues: setting || initialValues,
        enableReinitialize: true,
        onSubmit
    })

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="تنظیمات"
                                icons={<TiCogOutline className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <form onSubmit={formik.handleSubmit}
                      className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                        <Media
                            single={true}
                            label="انتخاب لوگو پنل"
                            desc="انتخاب لوگو پنل"
                            name="setting_logo_panel"
                            formik={formik}
                            formikAddress={formik.values.setting_logo_panel}
                        />
                        <Media
                            single={true}
                            label="انتخاب لوگو اسلایدر"
                            desc="انتخاب لوگو اسلایدر"
                            name="setting_logo_slider"
                            formik={formik}
                            formikAddress={formik.values.setting_logo_slider}
                        />
                        <Media
                            single={true}
                            label="انتخاب لوگو سایت"
                            desc="انتخاب لوگو سایت"
                            name="setting_logo_site"
                            formik={formik}
                            formikAddress={formik.values.setting_logo_site}
                        />

                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="flex flex-col lg:flex-row gap-8">
                            <Input name="setting_title_panel" label="نام پنل" formik={formik}/>
                            <SelectOptionManual formik={formik} options={settingStatus}
                                                name="setting_allow_admin_to_enter" title="اجازه ورود مدیران به پنل"
                                                formikAddress={formik.values.setting_allow_admin_to_enter}/>
                        </div>
                        <Input name="setting_title" label="نام سایت" formik={formik}/>
                        <TextArea formik={formik} name="short_description" label="توضیحات پاورقی" rows={5}/>
                    </div>
                    <div className="my-20">
                        <ButtonForm
                            formik={formik}
                            title_button="ذخیره تغییرات"
                        />
                    </div>
                </form>
            </div>
        </div>

    )
        ;
};

export default Setting;
