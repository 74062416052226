import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/toast/Toast";
import {serialize} from "object-to-formdata";



export const postAsyncMedia = createAsyncThunk('media/postAsyncMedia', async (payload,
                                                                              {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, serialize({
            ...payload, action: "add_media_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const postAsyncMediaAlt = createAsyncThunk('media/postAsyncMediaAlt', async (payload,
                                                                                    {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, serialize({
            ...payload, action: "add_media_alt_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncMediaList = createAsyncThunk('media/getAsyncMediaList', async (payload,
                                                                                    {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_media_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})


export const deleteAsyncMedia = createAsyncThunk('media/deleteAsyncMedia', async (payload,
                                                                                  {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "delete_media_Admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    mediaList: [],
    media: {},
    resultAlt: {},
    count: 1,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const mediaSlice = createSlice({

    name: "media",
    initialState,
    reducers: {
        clearResultMedia: (state, action) => {
            state.result = {}
        },
        clearMedia: (state, action) => {
            state.media = false
        },
        clearResultAltMedia: (state, action) => {
            state.resultAlt = {}
        },
    },
    extraReducers: {
        [getAsyncMediaList.fulfilled]: (state, action) => {
            return {
                ...state,
                mediaList: action.payload.result.media,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null
            }
        },
        [getAsyncMediaList.pending]: (state, action) => {
            return {
                ...state,
                mediaList: [], count: 1,
                page: 1,
                loading: true,
                error: null
            }
        },
        [getAsyncMediaList.rejected]: (state, action) => {
            return {
                ...state,
                mediaList: [],
                count: 1, page: 1,
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncMedia.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                loading: false,
                error: null
            }
        },
        [postAsyncMedia.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: true,
                error: null
            }
        },
        [postAsyncMedia.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncMediaAlt.fulfilled]: (state, action) => {
            return {
                ...state,
                resultAlt: action.payload,
                loading: false,
                error: null
            }
        },
        [postAsyncMediaAlt.pending]: (state, action) => {
            return {
                ...state,
                resultAlt: {},
                loading: true,
                error: null
            }
        },
        [postAsyncMediaAlt.rejected]: (state, action) => {
            return {
                ...state,
                resultAlt: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [deleteAsyncMedia.fulfilled]: (state, action) => {
            if(action.payload.status === "success"){
                state.mediaList = state.mediaList.filter((value)=> value.media_id != action.payload.result.media_id)
                Toast.success(action.payload.message)
            }else {
                Toast.error(action.payload.message)
            }
        },
    }
})

export const {clearMedia,clearResultMedia,clearResultAltMedia} = mediaSlice.actions;

export default mediaSlice.reducer
