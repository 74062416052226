import React, {useEffect, useRef, useState} from 'react';
import {FiCheckCircle, FiTrash} from "react-icons/fi";
import Loading from "../messageRedux/Loading";
import Error from "../messageRedux/Error";
import {useDispatch, useSelector} from "react-redux";
import {Toast} from "../toast/Toast";
import {clearResultAltMedia, getAsyncMediaList, postAsyncMediaAlt} from "../../features/media/mediaSlice";
import {Config} from "../../config/Config";
import Paging from "../dataTable/Paging";
import {MdDelete} from "react-icons/md";
import axios from "axios";
import {serialize} from "object-to-formdata";
import Alert from "../toast/Alert";
import {HiOutlineX} from "react-icons/hi";
import {IoMdInformation} from "react-icons/io";
import {IoCloseCircleOutline} from "react-icons/io5";
import useWindowSize from "../../customHook/useWindowSize";


const Media = ({formik, name, formikAddress, single = false, label, desc = false, classDiv = ""}) => {

    const refUpload = useRef()
    const [openGallery, setOpenGallery] = useState(false)

    const [perPage, setPerPage] = useState(18);
    const [numberPage, setNumberPage] = useState(1);

    const {mediaList, resultAlt, count, page, loading, error} = useSelector(state => state.media)
    const dispatch = useDispatch()
    const {height, width} = useWindowSize();

    const [selectedItem, setSelectedItem] = useState([])

    useEffect(() => {
        if (width > 1536) {
            setPerPage(18)
        } else if (width > 1024) {
            setPerPage(12)
        } else {
            setPerPage(4)
        }
    }, [width])


    useEffect(() => {
        dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [openGallery, perPage, numberPage])

    const selectHandler = (input) => {

        if (single) {
            setSelectedItem([input])
        } else {
            if (!selectedItem.some(val => val.id === input.id)) {
                setSelectedItem([...selectedItem, input])
            } else {
                const result = selectedItem.filter(val => val.id !== input.id)
                setSelectedItem([...result])
            }
        }
    }

    const addFileHandler = (e) => {
        e.preventDefault()
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size < 2200000) {
                UploadMediaAxios({uploadImage: e.target.files[0]}).then((res) => {
                    if (res.status === 'success') {
                        Toast.success(res.message)
                        setUploadPercent(100)
                        dispatch(getAsyncMediaList({
                            row_per_page: parseInt(perPage),
                            page_number: parseInt(numberPage)
                        }))
                        setTimeout(() => {
                            setUploadPercent(0)
                        }, 1000)
                    } else {
                        Toast.warning(res.message)
                        setUploadPercent(0)
                    }
                })
                refUpload.current.value = ""
            } else {
                Toast.warning("حجم فایل نباید بیشتر از 20kb باشد.")
            }
        }
    };

    const [uploadPercent, setUploadPercent] = useState(0)
    const config = {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent < 100) {
                setUploadPercent(percent)
            }
        },
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }

    const UploadMediaAxios = async (payload) => {
        const result = await axios.post(Config.api, serialize({...payload, action: "add_media_admin"}), config)
        return result.data
    }

    const removeMediaAxios = async (payload) => {
        const result = await axios.post(Config.api, serialize({...payload, action: "delete_media_admin"}), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        return result.data
    }

    const addFormikHandler = () => {
        if (single) {
            formik.setFieldValue(name, selectedItem[0].url)
        } else {
            formik.setFieldValue(name, selectedItem)
        }
        setTimeout(() => {
            setOpenGallery(false)
        }, [300])
    }

    const removeMedia = (id) => {
        if (single) {
            setSelectedItem([])
            formik.setFieldValue(name, "")
        } else {
            const result = formikAddress.filter(val => val.id != id)
            setSelectedItem([...result])
            formik.setFieldValue(name, result)
        }
    }

    const [showAlertPage, setShowAlertPage] = useState(false)
    const [showAlertValue, setShowAlertValue] = useState("")

    const showAlertDelete = (input) => {
        setShowAlertPage(true)
        setShowAlertValue(input)
    }

    const [showAlertInfPage, setShowAlertInfPage] = useState(false)
    const [showAlertInfoValue, setShowAlertInfoValue] = useState({media_id: "", media_alt: "", media_url: ""})

    const showAlertInfo = (input) => {
        setShowAlertInfPage(true)
        setShowAlertInfoValue(input)
    }

    const removeMediaFunc = () => {
        removeMediaAxios({media_id: showAlertValue}).then((res) => {
            if (res.status === 'success') {
                Toast.success(res.message)
                dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                setSelectedItem([])
                setShowAlertPage(false)
            } else {
                Toast.warning(res.message)
                setShowAlertPage(false)
            }
        })
    }

    useEffect(() => {
        if (resultAlt.status !== undefined) {
            if (resultAlt.status === "success") {
                dispatch(clearResultAltMedia())
                Toast.success(resultAlt.message)
                dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                setShowAlertInfPage(false)
            } else {
                Toast.error(resultAlt.message)
            }
        }
    }, [resultAlt])


    return (
        <div>
            {single ? (
                <div className="">
                    <div
                        className={`flex flex-col gap-2 items-center justify-center w-full h-56 border border-blue-500 dark:border-blue-400 rounded-md mt-6 py-4 ${classDiv}`}>
                        <div className="w-full flex items-center justify-center">
                            {Boolean(formikAddress) && (
                                <div className="relative">
                                    <div
                                        className="flex h-20 w-32 object-center container justify-center bg-gray-200 dark:bg-gray-700 ring-1 ring-blue-500 dark:ring-blue-400 rounded-lg overflow-hidden">
                                        <img className="object-center container object-cover"
                                             src={Config.imageUrl + formikAddress} alt=""/>
                                    </div>
                                    <div onClick={() => removeMedia()}
                                         className="absolute -top-3 -left-2 h-8 w-9 flex flex-row justify-center items-center rounded-full bg-red-600">
                                        <MdDelete className="text-white" size={20}/>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div onClick={() => {
                            setOpenGallery(true)
                        }}
                             className="cursor-pointer hover:bg-blue-600 hover:text-white dark:hover:text-white text-xs text-blue-600 dark:text-blue-400 border border-blue-500 dark:border-blue-400 rounded p-2 px-4">{label}</div>
                        {desc && (<div className="text-xs font-bold dark:text-gray-300"><p>{desc}</p></div>)}
                    </div>
                </div>
            ) : (
                <div className="mb-5">
                    <div onClick={() => {
                        setOpenGallery(true)
                    }}
                         className="bg-blue-600 text-xs lg:text-sm w-fit rounded mt-5 p-2 lg:py-3 lg:px-4 text-white cursor-pointer">{label}</div>
                    {desc && (<div className="font-bold mt-5 dark:text-gray-300"><p>{desc}</p></div>)}
                    {Boolean(formikAddress) && formikAddress.length > 0 && (
                        <div className="flex flex-row gap-2 mt-5 border rounded p-2 ">
                            {Boolean(formikAddress) && formikAddress.length > 0 && formikAddress.map(($value) => {
                                return (
                                    <div
                                        className="flex w-20 h-20 object-center container justify-center overflow-hidden border-2 dark:border-gray-600 p-1 bg-gray-100 dark:bg-gray-700 shadow rounded dark:hover:bg-gray-600 hover:bg-gray-300 cursor-pointer relative">
                                        <img className="object-center container object-cover"
                                             src={Config.imageUrl + $value.url} alt=""/>
                                        {formikAddress.find(value => value.id === $value.id) && (
                                            <FiTrash onClick={() => {
                                                removeMedia($value.id)
                                            }} size={20}
                                                     className="absolute left-1 top-1 text-white bg-red-500 rounded-full p-0.5 "/>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            )}
            <div className={`${openGallery ? "" : "hidden"} fixed top-0 bottom-0 right-0 left-0 z-50 bg-gray-400/70 backdrop-blur flex flex-col items-center justify-center`}>
                <div className="bg-white dark:bg-gray-900 rounded p-5 w-96 lg:w-[50rem] xl:w-[64rem]">
                    <div className="flex flex-row justify-between items-center border-b dark:border-gray-500 pb-2">
                        <span className="text-xs dark:text-gray-100">افزودن رسانه</span>
                        <HiOutlineX className="dark:text-gray-100" onClick={() => {
                            setOpenGallery(false)
                        }} size={18}/>
                    </div>
                    <div className="p-8 border-4 border-separate dark:border-gray-500 mt-5 rounded border-dashed">

                        <div>
                            <div className="flex flex-row w-full items-center justify-center gap-2">
                                <label className="text-gray-700 text-sm font-bold dark:text-gray-200 cursor-pointer">رسانه
                                    خود را انتخاب کنید</label>
                                <input ref={refUpload} type="file" onChange={(event) => addFileHandler(event)}
                                       className="text-sm text-gray-500 file:py-2 file:px-4 file:rounded-full file:border-0
                                        file:text-sm file:font-bold file:bg-blue-100 dark:file:bg-blue-800 file:text-blue-800 dark:file:text-blue-100
                                        hover:file:text-blue-600 hover:file:bg-white dark:hover:file:bg-gray-900"/>
                            </div>
                        </div>
                        {uploadPercent > 0 && (
                            <div className="w-full bg-neutral-200 dark:bg-neutral-600 my-5 rounded-full">
                                <div
                                    className="bg-blue-500 p-0.5 text-center text-xs font-medium leading-none text-white rounded-full"
                                    style={{width: `${uploadPercent}%`}}>
                                    {uploadPercent}%
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col w-full gap-3 justify-center items-center">
                        {loading && (<Loading/>)}
                        {error && (<Error error={error}/>)}
                        {!loading && error === null && mediaList && (
                            <div className="flex flex-row gap-2">
                                <div
                                    className="w-full self-start grid md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-6 gap-2 my-5 mx-2">
                                    {mediaList.map((value, index) => {
                                        return (
                                            <div key={index} onClick={() => {
                                                selectHandler({id: parseInt(value.media_id), url: value.media_url})
                                            }}
                                                 className="flex group w-28 h-28 container object-center justify-center overflow-hidden border-2 dark:border-gray-600 p-1 bg-gray-100 dark:bg-gray-700 shadow rounded dark:hover:bg-gray-600 hover:bg-gray-300 cursor-pointer relative">
                                                <img className="w-28 h-28 object-center container object-cover rounded"
                                                     src={Config.imageUrl + value.media_url} alt=""/>

                                                {selectedItem.find(item => item.id === parseInt(value.media_id)) && (
                                                    <FiCheckCircle size={30}
                                                                   className="absolute left-1/2 top-1/2 transition -translate-x-1/2 -translate-y-1/2 text-blue-500 bg-white/40 rounded-full "/>
                                                )}

                                                <FiTrash onClick={() => {
                                                    showAlertDelete(value.media_id)
                                                }} size={23}
                                                         className="invisible group-hover:visible p-0.5 absolute left-1 top-1 text-red-500 bg-gray-50 hover:bg-gray-300 rounded-full "/>

                                                <IoMdInformation onClick={() => {
                                                    showAlertInfo({
                                                        media_id: value.media_id,
                                                        media_alt: value.media_alt,
                                                        media_url: value.media_url
                                                    })
                                                }} size={23}
                                                                 className="invisible group-hover:visible absolute left-8 top-1 text-yellow-600 bg-gray-50 hover:bg-gray-300 rounded-full "/>
                                            </div>
                                        )
                                    })}
                                </div>
                                {showAlertInfPage &&
                                    <div
                                        className="flex flex-col self-start items-center justify-center gap-4 border-2 border-gray-100 dark:border-gray-600 rounded-lg my-5 p-2 w-64">
                                        <div onClick={() => {
                                            setShowAlertInfPage(false)
                                        }} className="w-full flex flex-row cursor-pointer justify-between">
                                            <label
                                                className="text-gray-700 text-sm font-bold dark:text-gray-200 cursor-pointer">توضیحات
                                                تصویر</label>
                                            <IoCloseCircleOutline size={25}
                                                                  className="hover:text-blue-400 dark:text-gray-200 text-gray-400"/>
                                        </div>
                                        <div
                                            className="h-40 w-40 rounded-lg object-cover object-center justify-center flex overflow-hidden items-center">
                                            <img className="object-center h-40 w-40 container object-cover rounded"
                                                 src={Config.imageUrl + showAlertInfoValue.media_url} alt=""/>
                                        </div>
                                        <input hidden type="text" value={showAlertInfoValue.media_id} name="media_id"/>
                                        <div className="media_alt">
                                            <label htmlFor="media_id"
                                                   className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                متن جایگزین تصویر
                                            </label>
                                            <textarea className="caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300
                                            text-gray-900 rounded-lg text-sm focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5"
                                                      placeholder={showAlertInfoValue.media_alt} name="media_alt"
                                                      id="media_alt" rows={6}
                                                      onChange={(event) => setShowAlertInfoValue({
                                                          ...showAlertInfoValue,
                                                          media_alt: event.target.value
                                                      })}/>
                                        </div>
                                        <div
                                            className="w-full flex py-2 items-center justify-center bg-blue-600 dark:bg-blue-600 text-sm text-gray-100 rounded font-bold"
                                            onClick={() => dispatch((postAsyncMediaAlt(showAlertInfoValue)))}>
                                            ارسال
                                        </div>
                                    </div>
                                }
                            </div>

                        )}

                        <Alert
                            showAlertPage={showAlertPage}
                            setShowAlertPage={setShowAlertPage}
                            title="اخطار"
                            label="آیا برای حذف این فایل مطمئن هستید‌؟"
                            onclick={removeMediaFunc}
                        />

                    </div>
                    <nav
                        className="flex flex-col lg:flex-row w-full gap-2 bg-gray-200/70 dark:bg-gray-400/70 mt-2  justify-between items-center p-2 px-4 rounded"
                        aria-label="Table navigation">
                        <div className="flex flex-row gap-2 justify-center items-center">
                            <span className="text-xs font-normal text-gray-600 dark:text-gray-200">درحال نمایش </span>
                            <span
                                className="font-bold text-gray-800 dark:text-white">{((parseInt(numberPage) - 1) * parseInt(perPage)) + 1}-{parseInt(perPage) * parseInt(numberPage) < count ? parseInt(perPage) * parseInt(numberPage) : count}</span>
                            <span className="text-xs font-normal text-gray-600 dark:text-gray-200">از </span>
                            <span className="font-bold text-gray-900 dark:text-white">{count}</span>
                        </div>
                        <Paging page={page} setNumberPage={setNumberPage} numberPage={numberPage}/>
                    </nav>
                    <div className="py-3">
                        <div onClick={() => {
                            addFormikHandler()
                        }}
                             className="flex flex-row justify-center cursor-pointer hover:bg-blue-700 w-36 bg-blue-600 text-xs p-2 text-white rounded">
                            افزودن رسانه
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Media;
