import imageMan from "../assets/image/user/man.png"
import imageWoman from "../assets/image/user/woman.png"
import imageLogoDark from "../assets/image/logo/logo-title-white.png"
import imageLogo from "../assets/image/logo/logo-title copy.png"
import imageLogoCircle from "../assets/image/logo/logo.png"
import profileImageBackground from "../assets/image/background/img.png"


export const Config = {

    // imageUrl: "https://dtools.ir/nilfam-tech-api-center/steel",
    // api: "https://dtools.ir/nilfam-tech-api-center/steel/api/interfaces/site/",
    imageUrl: "https://api.bidestansteel.com",
    api: "https://api.bidestansteel.com/api/interfaces/site/",
    authorName: "NilfamTech.ir",
    version: "1.0.0.0",
    imageLogoDark: imageLogoDark,
    imageLogo: imageLogo,
    imageLogoCircle: imageLogoCircle,
    loginTitle: "بیدستان کالا استیل",
    about: "سایت بیدستان کالا استیل",
    address: "تهران، کیلومتر ۴ بزرگراه فتح (جاده قدیم کرج)، مابین فتح ۱۱ و۱۳، ساختمان بیدستان، پلاک ۳۳۳",
    phoneOne: " ۷۴-۶۶۷۹۷۰۷۱-۰۲۱",
    supportSocial: "",
    toastSuccess: "اطلاعات شما ثبت شد.",
    imageAvatarWoman: imageWoman,
    imageAvatarMan: imageMan,
    profileImageBackground : profileImageBackground,
    panelTitle: "سامانه بیدستان کالا استیل",

}

export const ConfigMessage = {
    error: "عملیات با خطا روبرو شد."
}

export const RegExp = {
    phone: /^(0|\+98)?([ ]|-|[()]){0,2}9[1|2|3|4]([ ]|-|[()]){0,2}(?:[0-9]([ ]|-|[()]){0,2}){8}$/

}
