

export  const GenerateUrl = (input) => {
if(typeof input === "string"){
    const result0 = input.replaceAll(/\s{2,}/g, '-')
    const result1 = result0.replaceAll(" ", '-')
    const result2 = result1.replaceAll('،', '')
    const result3 = result2.replaceAll(',', '')
    const result4 = result3.replaceAll("'", '')
    return result4.replaceAll('"', '')
}
return ""
};