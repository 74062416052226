import React, {useEffect} from 'react';
import {MdLabelImportantOutline} from "react-icons/md";
import {BiBell} from "react-icons/bi";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {useDispatch, useSelector} from "react-redux";
import {TiChartLineOutline, TiNews,TiTicket} from "react-icons/ti";
import {useNavigate} from "react-router-dom";
import {getAsyncDetailsDashboard} from "../../../features/dashboard/DashboardSlice";


const Dashboard = () => {

        const {dashboard} = useSelector(state => state.dashboard)
        const dispatch = useDispatch()
        const navigate = useNavigate()

        useEffect(() => {
         dispatch(getAsyncDetailsDashboard())
        }, [dispatch])

        return (

            <div className="flex flex-col gap-6">

                <div className="flex flex-col gap-6 rounded ">

                    <div className="bg-gray">
                        <LabelDashboard titleLabel="تعداد بازدید سایت" ShortLabel="بیدستان کالا استیل"
                                        icons={<TiChartLineOutline className="text-slate-500 dark:text-slate-400"
                                                                 size={25}/>}/>
                        <div
                            className="flex flex-col md:flex-row gap-4 lg:gap-10 justify-center items-center border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 xl:px-10 py-8">
                            <div
                                className="flex flex-col gap-2 w-44 items-center cursor-pointer text-gray-100
                                dark:hover:bg-gray-900 dark:hover:text-blue-100 hover:text-blue-900 bg-blue-500 hover:bg-gray-100 rounded-lg px-4 py-6">
                                <span className="text-xl font-bold">{dashboard.count_view}</span>
                                <span className="text-sm font-bold">تعداد بازدید</span>
                            </div>
                            <div
                                className="flex flex-col gap-2 w-44 items-center cursor-pointer text-gray-100
                                dark:hover:bg-gray-900 dark:hover:text-blue-100 hover:text-blue-900 bg-blue-500 hover:bg-gray-100 rounded-lg px-4 py-6">
                                <span className="text-xl font-bold">{dashboard.count_product}</span>
                                <span className="text-sm font-bold">تعداد مقالات</span>
                            </div>
                            <div
                                className="flex flex-col gap-2 w-44 items-center cursor-pointer text-gray-100
                                dark:hover:bg-gray-900 dark:hover:text-blue-100 hover:text-blue-900 bg-blue-500 hover:bg-gray-100 rounded-lg px-4 py-6">
                                <span className="text-xl font-bold">{dashboard.count_news}</span>
                                <span className="text-sm font-bold">تعداد اخبار</span>
                            </div>
                            <div
                                className="flex flex-col gap-2 w-44 items-center cursor-pointer text-gray-100
                                dark:hover:bg-gray-900 dark:hover:text-blue-100 hover:text-blue-900 bg-blue-500 hover:bg-gray-100 rounded-lg px-4 py-6">
                                <span className="text-xl font-bold">{dashboard.count_customer}</span>
                                <span className="text-sm font-bold">تعداد مشتریان</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="bg-gray self-start">
                        <LabelDashboard titleLabel="اخبار کار شده" titleButton="افزودن خبر جدید" to={()=> navigate("/add-news")}
                                        icons={<TiNews className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                        <div
                            className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 xl:px-10 py-8">
                            {dashboard.last_news && dashboard.last_news.length > 0 && dashboard.last_news.map((item, index) => {
                                return (
                                    <div key={index}
                                         className="flex flex-row gap-2 items-center cursor-pointer dark:text-gray-100 dark:hover:text-blue-500 hover:text-blue-500">
                                        <BiBell className="font-bold" size={18}/>
                                        <span className="text-sm font-bold">{item.news_title}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="bg-gray self-start">
                        <LabelDashboard titleLabel="مقالات کار شده" titleButton="افزودن مقاله جدید" to={()=> navigate("/add-products")}
                                        icons={<TiTicket className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                        <div
                            className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 xl:px-10 py-8">
                            {dashboard.last_product && dashboard.last_product.length > 0 && dashboard.last_product.map((item, index) => {
                                return (
                                    <div key={index}
                                         className="flex flex-row gap-2 items-center cursor-pointer dark:text-gray-100 dark:hover:text-blue-500 hover:text-blue-500">
                                        <MdLabelImportantOutline className="font-bold" size={20}/>
                                        <span className="text-sm font-bold">{item.product_title}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>

        );
    };

export default Dashboard;
