import React, {useEffect} from 'react';
import Input from "../../../components/input/Input";
import SelectOptionManual from "../../../components/input/SelectOptionManual";
import {showItems} from "../../../assets/data/Data";
import ButtonForm from "../../../components/button/ButtonForm";
import * as yup from "yup";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Toast} from "../../../components/toast/Toast";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {TiBusinessCard} from "react-icons/ti";
import {
    clearCustomer,
    clearResultCustomer,
    getAsyncCustomer,
    postAsyncCustomer
} from "../../../features/customer/customerSlice";
import Media from "../../../components/media/Media";


const AddCustomer = () => {

    const {customer, result, loading} = useSelector(state => state.customer)
    const dispatch = useDispatch()
    const {customer_id} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (customer_id !== undefined) {
            dispatch(getAsyncCustomer({customer_id: customer_id}))
        } else {
            dispatch(clearCustomer())
        }
    }, [customer_id])

    useEffect(() => {
        if (result.status !== undefined) {
            if (result.status === "success") {
                setTimeout(() => {
                    return navigate('/list-customer')
                }, 1000)
                dispatch(clearResultCustomer())
                Toast.success(result.message)
            } else {
                Toast.error(result.message)
            }
        }
    }, [result])

    const initialValues = {
        customer_title: "",
        customer_image: "",
        customer_publish: "انتشار"
    }

    let validationSchema = yup.object({
        customer_title: yup.string().required("وارد کردن نام شرکت برای ارسال ضروری است."),
        customer_image: yup.string().required("افزودن لوگو شرکت برای ارسال ضروری است.")
    });

    const onSubmit = (values) => {
        dispatch(postAsyncCustomer(values))
    }

    const formik = useFormik({
        initialValues: customer || initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit
    })

    console.log(formik.values)

    return (

        <div className="bg-gray">
            <LabelDashboard LabelButton="بازگشت" titleLabel="افزودن مشتریان جدید"
                            icons={<TiBusinessCard className="text-slate-500 dark:text-slate-400" size={25}/>}/>
            <form onSubmit={formik.handleSubmit}
                  className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                <div className="flex flex-col lg:flex-row gap-5">
                    <div className="flex flex-col w-full gap-6">
                        <Input name="customer_title" label="نام شرکت" formik={formik}/>
                        <SelectOptionManual formik={formik} options={showItems} name="customer_publish"
                                            title="وضعیت انتشار" formikAddress={formik.values.customer_publish}/>
                    </div>
                    <div className=" lg:w-80">
                        <Media
                            single={true}
                            desc=""
                            label="افزودن تصویر اصلی"
                            name="customer_image"
                            formik={formik}
                            formikAddress={formik.values.customer_image}
                            classDiv=" h-36 "
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col my-12">
                    <ButtonForm
                        formik={formik}
                        title_button="ثبت"
                        title_button_edit="ویرایش"
                        type={customer_id}
                        loading={loading}
                    />
                </div>
            </form>
        </div>

    );
};

export default AddCustomer;
