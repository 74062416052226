import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/toast/Toast";


export const getAsyncSliderList = createAsyncThunk('slider/getAsyncSliderList', async (payload,
                                                                                          {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_slider_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncSlider = createAsyncThunk('slider/getAsyncSlider', async (payload,
                                                                                  {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_slider_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const postAsyncSlider = createAsyncThunk('slider/postAsyncSlider', async (payload,
                                                                                    {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({...payload, action: "add_slider_admin"}), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const deleteAsyncSlider = createAsyncThunk('slider/deleteAsyncSlider', async (payload,
                                                                                    {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "delete_slider_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    sliderList: [],
    slider: {},
    sliderDelete: {},
    count: 1,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const sliderSlice = createSlice({

    name: "slider",
    initialState,
    reducers: {
        clearResultSlider : (state, action) => {
            state.result = {}
        },
        clearSlider : (state, action) => {
            state.slider = false
        },
        clearSliderDelete : (state, action) => {
            state.sliderDelete = {}
        }
    },
    extraReducers: {
        [getAsyncSliderList.fulfilled]: (state, action) => {
            return {
                ...state,
                sliderList: action.payload.result.slider,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null,
            }
        },
        [getAsyncSliderList.pending]: (state, action) => {
            return {...state, sliderList: [], count: 1, page: 1, loading: true, error: null}
        },
        [getAsyncSliderList.rejected]: (state, action) => {
            return {...state, sliderList: [], count: 1, page: 1, loading: false, error: ConfigMessage.error}
        },

        [getAsyncSlider.fulfilled]: (state, action) => {
            return {
                ...state,
                slider: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncSlider.pending]: (state, action) => {
            return {
                ...state,
                slider: {},
                loading: true,
                error: null
            }
        },
        [getAsyncSlider.rejected]: (state, action) => {
            return {
                ...state,
                slider: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncSlider.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                loading: false,
                error: null,
            }
        },
        [postAsyncSlider.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: true,
                error: null
            }
        },
        [postAsyncSlider.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [deleteAsyncSlider.fulfilled]: (state, action) => {
            return {
                ...state,
                sliderDelete: action.payload,
                loading: false,
                error: null,
            }
        },
        [deleteAsyncSlider.pending]: (state, action) => {
            return {
                ...state,
                sliderDelete: {},
                loading: true,
                error: null
            }
        },
        [deleteAsyncSlider.rejected]: (state, action) => {
            return {
                ...state,
                sliderDelete: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        // [deleteAsyncSlider.fulfilled]: (state, action) => {
        //     if(action.payload.status === "success"){
        //         state.sliderList = state.sliderList.filter((value)=> value.slider_id != action.payload.result)
        //         Toast.success(action.payload.message)
        //     }else {
        //         Toast.error(action.payload.message)
        //     }
        // },
    }

})

export const {clearSlider,clearResultSlider,clearSliderDelete} = sliderSlice.actions;

export default sliderSlice.reducer
