import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";



export const editAsyncSocialNetwork = createAsyncThunk('network/editAsyncSocialNetwork', async (payload,
                                                                                       {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "edit_social_network_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncSocialNetwork = createAsyncThunk('network/getAsyncSocialNetwork', async (payload,
                                                                               {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_social_network_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})


const initialState = {
    network: {},
    isLoading: false,
    error: null,
    result: {},
    status: "idle"
}

const sliderSlice = createSlice({

    name: "network",
    initialState,
    reducers: {
        clearResultSlider : (state, action) => {
            state.result = {}
        },
        clearSlider : (state, action) => {
            state.slider = false
        },
    },
    extraReducers: {
        [getAsyncSocialNetwork.fulfilled]: (state, action) => {
            return {
                ...state,
                network: action.payload.result,
                isLoading: false,
                error: null,
            }
        },
        [getAsyncSocialNetwork.pending]: (state, action) => {
            return {...state, network: {}, isLoading: true, error: null}
        },
        [getAsyncSocialNetwork.rejected]: (state, action) => {
            return {...state, network: {}, isLoading: false, error: ConfigMessage.error}
        },

        [editAsyncSocialNetwork.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                isLoading: false,
                error: null,
            }
        },
        [editAsyncSocialNetwork.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                isLoading: true,
                error: null
            }
        },
        [editAsyncSocialNetwork.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                isLoading: false,
                error: ConfigMessage.error
            }
        },
    }

})

export const {clearSlider,clearResultSlider,clearSliderDelete} = sliderSlice.actions;

export default sliderSlice.reducer
