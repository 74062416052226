import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/toast/Toast";

export const getAsyncNewsList = createAsyncThunk('news/getAsyncNewsList', async (payload,
                                                                                 {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_news_Admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncNews = createAsyncThunk('news/getAsyncNews', async (payload,
                                                                         {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_news_Admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const deleteAsyncNews = createAsyncThunk('news/deleteAsyncNews', async (payload,
                                                                           {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "delete_news_Admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    newsList: [],
    news: {},
    newsDelete:{},
    count: 1,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const newsSlice = createSlice({

    name: "news",
    initialState,
    reducers: {
        clearResultNews: (state, action) => {
            state.result = {}
        },
        clearNews: (state, action) => {
            state.news = false
        },
        clearNewsDelete: (state, action) => {
            state.newsDelete = {}
        },
    },
    extraReducers: {
        [getAsyncNewsList.fulfilled]: (state, action) => {
            return {
                ...state,
                newsList: action.payload.result.news,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null
            }
        },
        [getAsyncNewsList.pending]: (state, action) => {
            return { ...state, newsList: [], count: 1, page: 1, loading: true, error: null }
        },
        [getAsyncNewsList.rejected]: (state, action) => {
            return { ...state, newsList: [], count: 1, page: 1, loading: false, error: ConfigMessage.error }
        },

        [getAsyncNews.fulfilled]: (state, action) => {
            return { ...state, news: action.payload.result, loading: false, error: null }
        },
        [getAsyncNews.pending]: (state, action) => {
            return {  ...state,  news: {},  loading: true, error: null }
        },
        [getAsyncNews.rejected]: (state, action) => {
            return { ...state, news: {}, loading: false, error: ConfigMessage.error }
        },

        [deleteAsyncNews.fulfilled]: (state, action) => {
            return { ...state, newsDelete: action.payload, loading: false, error: ConfigMessage.error }
        },
        [deleteAsyncNews.pending]: (state, action) => {
            return { ...state, newsDelete: {}, loading: true, error: null }
        },
        [deleteAsyncNews.rejected]: (state, action) => {
            return { ...state, newsDelete: {}, loading: false, error: ConfigMessage.error }
        },
    }
})

export const {clearNews,clearResultNews,clearNewsDelete} = newsSlice.actions;

export default newsSlice.reducer
