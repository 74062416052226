import React from 'react';
import {IoCloseCircleOutline} from "react-icons/io5";


const Alert = ({setShowAlertPage , showAlertPage, label, title,onclick}) => {

    return (
        <div className={`${showAlertPage ? "" : "hidden"} fixed inset-0 z-40 bg-gray-400/70 backdrop-blur flex flex-col items-center justify-center`}>
            <div className="absolute w-96 bg-gray-100 dark:bg-gray-800 rounded-lg border-2 dark:border-gray-500 border-gray-300 shadow flex flex-col items-center justify-center p-6">
                <div className="w-full flex flex-row justify-between items-center">
                    <div className="dark:text-gray-200">
                        {title}
                    </div>
                    <div onClick={() => {setShowAlertPage(false)}} className="cursor-pointer">
                        <IoCloseCircleOutline size={25} className="hover:text-gray-400 dark:text-gray-200"/>
                    </div>
                </div>
                <div className="py-6 dark:text-gray-200">
                    {label}
                </div>
                <div className="flex flex-row gap-3 p-6 items-center justify-center">
                    <div onClick={() => {
                        onclick()
                    }} className="bg-blue-500 border border-blue-500 hover:bg-blue-600 cursor-pointer py-1 w-20 rounded text-center text-white">بله</div>

                    <div onClick={() => {setShowAlertPage(false)}}
                         className="bg-gray-100 border border-blue-500 cursor-pointer py-1 w-20 rounded text-center text-blue-500" >انصراف</div>
                </div>
            </div>
        </div>
    );
};

export default Alert;
