import React, {useEffect, useRef, useState} from 'react';
import {FiCheckCircle, FiTrash} from "react-icons/fi";
import Loading from "../messageRedux/Loading";
import Error from "../messageRedux/Error";
import {useDispatch, useSelector} from "react-redux";
import {Toast} from "../toast/Toast";
import {getAsyncMediaList} from "../../features/media/mediaSlice";
import {Config} from "../../config/Config";
import Paging from "../dataTable/Paging";
import axios from "axios";
import {serialize} from "object-to-formdata";
import Alert from "../toast/Alert";
import useWindowSize from "../../customHook/useWindowSize";
import {HiX} from "react-icons/hi";


const MediaTextEditor = ({addImage, label, desc = false}) => {


    const refUpload = useRef()
    const [openGallery, setOpenGallery] = useState(false)
    const [perPage, setPerPage] = useState(21);
    const [numberPage, setNumberPage] = useState(1);
    const {mediaList, count, page, loading, error} = useSelector(state => state.media)
    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState([])
    const {height, width} = useWindowSize();

    useEffect(() => {
        if (width > 1024) {
            setPerPage(21)
        } else {
            setPerPage(6)
        }
    }, [width])

    useEffect(() => {
        dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [openGallery, perPage, numberPage])

    const selectHandler = (input) => {
        setSelectedItem([input])
    }


    const addFileHandler = (e) => {
        e.preventDefault()
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size < 2200000) {
                UploadMediaAxios({uploadImage: e.target.files[0]}).then((res) => {
                    if (res.status === 'success') {
                        Toast.success(res.message)
                        setUploadPercent(100)
                        dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                        setTimeout(() => {
                            setUploadPercent(0)
                        }, 1000)
                    } else {
                        Toast.warning(res.message)
                        setUploadPercent(0)
                    }
                })
                refUpload.current.value = ""
            } else {
                Toast.warning("حجم فایل نباید بیشتر از 20mb باشد.")

            }
        }
    };


    const [uploadPercent, setUploadPercent] = useState(0)
    const config = {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded} kb of ${total} | ${percent} %`)

            if (percent < 100) {
                setUploadPercent(percent)
            }
        },
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }

    const UploadMediaAxios = async (payload) => {
        const result = await axios.post(Config.api, serialize({...payload, action: "add_media_admin"}), config)
        return result.data
    }

    const removeMediaAxios = async (payload) => {
        const result = await axios.post(Config.api, serialize({...payload, action: "delete_media_admin"}), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        return result.data
    }


    const addFormikHandler = () => {
        if(setSelectedItem.length > 0){
            addImage(Config.imageUrl + selectedItem[0].url)
            setTimeout(() => {
                setOpenGallery(false)
            }, [300])
        }else {
            Toast.warning("لطفا یک رسانه برای نمایش انتخاب کنید.")
        }

    }

    const [showAlertPage, setShowAlertPage] = useState(false)
    const [showAlertValue, setShowAlertValue] = useState("")
    const showAlert = (input) => {
        setShowAlertPage(true)
        setShowAlertValue(input)
    }

    const removeMediaFunc = () => {
        removeMediaAxios({media_id : showAlertValue}).then((res) => {
            if (res.status === 'success') {
                Toast.success(res.message)
                dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                setSelectedItem([])
                setShowAlertPage(false)
            } else {
                Toast.warning(res.message)
                setShowAlertPage(false)
            }
        })
    }

    return (
        <div>
            <div className="">
                <div onClick={() => {
                    setOpenGallery(true)
                }}
                     className="flex flex-row justify-center md:w-fit text-sm gap-2 cursor-pointer text-center rounded-lg border border-blue-500 dark:text-gray-100 text-gray-800 hover:dark:text-blue-100 hover:text-gray-100 hover:bg-blue-500 font-bold py-2 px-4">
                    {label}
                </div>
            </div>

            <div className={`${openGallery ? "" : "hidden"} fixed top-0 bottom-0 right-0 left-0 z-50 bg-gray-400/70 backdrop-blur flex flex-col items-center justify-center`}>
                <div className="bg-white dark:bg-gray-900 rounded p-5 lg:w-[64rem]">
                    <div className="flex flex-row justify-between items-center border-b dark:border-gray-500 pb-2">
                        <span className="text-sm font-bold dark:text-gray-100">افزودن رسانه</span>
                        <HiX className="dark:text-gray-100" onClick={() => {
                            setOpenGallery(false)
                        }} size={18}/>
                    </div>
                    <div className="p-8 border-4 border-separate dark:border-gray-500 mt-5 rounded border-dashed">
                        <div>
                            <div className="flex flex-row w-full items-center justify-center gap-2">
                                <label className="text-gray-700 text-xs md:text-sm md:font-bold dark:text-gray-200">رسانه خود را انتخاب کنید</label>
                                <input ref={refUpload} type="file" onChange={(event) => addFileHandler(event)}
                                       className="text-xs md:text-sm text-gray-500 file:py-2 file:px-4 file:rounded-full file:border-0
                                        file:md:text-sx file:md:text-sm file:font-bold file:bg-blue-100 dark:file:bg-blue-800 file:text-blue-800 dark:file:text-blue-100
                                        hover:file:text-blue-600 hover:file:bg-white dark:hover:file:bg-gray-900"/>
                            </div>
                        </div>
                        {uploadPercent > 0 && (
                            <div className="w-full bg-neutral-200 dark:bg-neutral-600 my-5 rounded-full">
                                <div className="bg-blue-500 p-0.5 text-center text-xs font-medium leading-none text-white rounded-full"
                                     style={{width: `${uploadPercent}%`}}>
                                    {uploadPercent}%
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col w-full gap-3 justify-center items-center">
                        {loading && (<Loading/>)}
                        {error && (<Error error={error}/>)}
                        {!loading && error === null && mediaList && (
                            <div className="justify-center grid-cols-3 grid md:grid-cols-5 lg:grid-cols-7 gap-2 my-5">
                                {mediaList.map((value, index) => {
                                    return (
                                        <div key={index} onClick={() => {
                                            selectHandler({id: parseInt(value.media_id), url: value.media_url})
                                        }} className="group w-28 h-28 overflow-hidden border-2 dark:border-gray-600 p-1 bg-gray-100 dark:bg-gray-700 shadow rounded dark:hover:bg-gray-600 hover:bg-gray-300 cursor-pointer relative">
                                            <img src={Config.imageUrl + value.media_url} className="rounded" alt=""/>

                                            {selectedItem.find(item => item.id === parseInt(value.media_id)) && (
                                                <FiCheckCircle size={30} className="absolute left-1/2 top-1/2 transition -translate-x-1/2 -translate-y-1/2 text-blue-500 bg-white/40 rounded-full "/>
                                            )}

                                            <FiTrash onClick={() => {
                                                showAlert(value.media_id)
                                            }} size={23} className="invisible group-hover:visible p-0.5 absolute left-1 top-1 text-red-500 bg-gray-50 hover:bg-gray-300 rounded-full "/>

                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        <Alert
                            showAlertPage={showAlertPage}
                            setShowAlertPage={setShowAlertPage}
                            title="اخطار"
                            label="آیا برای حذف این فایل مطمئن هستید‌؟"
                            onclick = {removeMediaFunc}
                        />

                    </div>
                    <nav className="flex flex-col md:flex-row w-full gap-2 bg-gray-200/70 dark:bg-gray-400/70 mt-2 justify-between items-center p-2 px-4 rounded" aria-label="Table navigation">
                        <div className="flex flex-row gap-2 justify-center items-center">
                            <span className="text-xs lg:font-normal text-gray-600 dark:text-gray-200">درحال نمایش </span>
                            <span className="text-xs md:font-bold text-gray-800 dark:text-white">{((parseInt(numberPage) - 1) * parseInt(perPage)) + 1}-{parseInt(perPage) * parseInt(numberPage) < count ? parseInt(perPage) * parseInt(numberPage) : count}</span>
                            <span className="text-xs lg:font-normal text-gray-600 dark:text-gray-200">از </span>
                            <span className="text-xs md:font-bold text-gray-900 dark:text-white">{count}</span>
                        </div>
                        <Paging page={page} setNumberPage={setNumberPage} numberPage={numberPage}/>
                    </nav>
                    <div className="py-3">
                        <div onClick={() => {
                            addFormikHandler()
                        }} className="flex flex-row text-sm justify-center cursor-pointer hover:bg-blue-700 bg-blue-600 px-10 py-2 text-white rounded">
                            افزودن رسانه
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default MediaTextEditor;
