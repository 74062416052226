
export const Toast = {
    success (desc, time = 3000) {
        Alert(desc, 'bg-green-600', time)
    },
    warning (desc, time = 3000) {
        Alert(desc, 'bg-yellow-500', time)
    },
    error (desc, time = 3000) {
        Alert(desc, 'bg-red-600', time)
    },
    info (desc, time = 3000) {
        Alert(desc, 'bg-blue-600', time)
    },
    clear () {
        document.getElementById('alertShow').innerHTML = "";
    }
}



const Alert = (desc, type, time) => {

    setTimeout(function () {
        document.getElementById('alertShow').innerHTML = "";
    }, time);

    document.getElementById('alertShow').innerHTML =
        `<div class="rounded-lg bg-white shadow-2xl border-2 overflow-hidden" role="alert">
              <div class=" ${type} h-4"></div>
             <div class="text-gray-700 text-sm font-medium flex flex-col text-justify p-6 ">${desc}</div>
        </div>`;
}
