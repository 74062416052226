import React from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import Input from "../../../components/input/Input";
import {Config} from "../../../config/Config";
import {TiWeatherWindy} from "react-icons/ti";


const EditProfile = () => {

    const {profile} = useSelector(state => state.profile)

    const formik = useFormik({
        initialValues: profile,
    })

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="تنظیمات کاربری"
                                icons={<TiWeatherWindy className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                    <div
                        className="flex flex-col lg:flex-row w-full items-center justify-between gap-20 text-gray-100">
                        <div
                            className="flex items-center justify-center object-cover object-center h-64 w-64 lg:w-96">
                            <div
                                className="h-64 w-64 rounded-full object-cover object-center justify-center flex overflow-hidden items-center ring ring-blue-500 ring-offset-2">
                                <img name="image" className="object-cover object-center h-64 w-64"
                                     src={Config.imageUrl + formik.values.image} alt="profile"/>
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-6 lg:gap-8 items-center">
                            <Input name="name" label="نام و نام خانوادگی" formik={formik}/>
                            <Input name="family" label="نام و نام خانوادگی" formik={formik}/>
                            <Input name="mobile" label="شماره موبایل" formik={formik}/>
                            <Input name="role" label="نقش" formik={formik}/>
                            <Input name="gender" label="جنسیت" formik={formik}/>
                            {formik.values.admin_status === "active"
                                ? <div className="flex flex-col gap-2">
                                    <label htmlFor="admin_status_active"
                                           className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">وضعیت</label>
                                    <input value="فعال" name="admin_status_active"
                                           className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300
                                           text-gray-900 rounded-lg text-sm focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5`}/>
                                </div>
                                : <div className="flex flex-col gap-2">
                                    <label htmlFor="admin_status_no_active"
                                           className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">وضعیت</label>
                                    <input value="غیر فعال" name="admin_status_no_active"
                                           className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300 
                                           text-gray-900 rounded-lg text-sm focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5`}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EditProfile;
