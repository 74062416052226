import React, {useEffect, useState} from 'react';
import {persianDateNT} from "../../../utility/persianDateNT";
import {TbEdit} from "react-icons/tb";
import {MdDelete} from "react-icons/md";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {useDispatch, useSelector} from "react-redux";
import Error from "../../../components/messageRedux/Error";
import Loading from "../../../components/messageRedux/Loading";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import OperatorButton from "../../../components/dataTable/OperatorButton";
import DataTable from "../../../components/dataTable/DataTable";
import {
    clearResultNewsCategoryDelete,
    deleteAsyncNewsCategory,
    getAsyncNewsCategoryList
} from "../../../features/newsCategory/newsCategorySlice";
import {TiThListOutline} from "react-icons/ti";
import {clearProductDelete, getAsyncProductsList} from "../../../features/products/productSlice";
import {Toast} from "../../../components/toast/Toast";


const CategoryNews = () => {

    const [perPage, setPerPage] = useState(10);
    const [numberPage, setNumberPage] = useState(1);

    const {newsCategoryList, newsCategoryDelete, loading, error, count, page} = useSelector(state => state.newsCategory)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncNewsCategoryList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage,numberPage])


    const columns = [
        {
            name: "عنوان دسته بندی",
            selector: row => row.news_category_title
        },
        {
            name: "عنوان لاتین",
            selector: row => row.news_category_title_english
        },
        {
            name: "تاریخ ثبت",
            style: {
                width: '220px'
            },
            selector: row => persianDateNT.date(row.news_category_create_time)
        },
        {
            name: "عملیات",
            style: {
                width: '220px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center gap-2">
                        <OperatorLink to={'/add-category-news/' + row.news_category_id}
                                      icon={<TbEdit size={20}/>}
                                      label="ویرایش" classCustom="btn-blue"/>
                        <OperatorButton
                            onClick={() => dispatch(deleteAsyncNewsCategory({news_category_id: row.news_category_id}))}
                            icon={<MdDelete size={20}/>}
                            label="حذف"
                            classCustom={row.news_category_status === "حذف" ? "btn-disable" : "btn-red"}
                            disabledAction={row.news_category_status === "حذف"}/>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        if (newsCategoryDelete.status !== undefined) {
            if (newsCategoryDelete.status === "success") {
                dispatch(getAsyncNewsCategoryList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                dispatch(clearResultNewsCategoryDelete())
                Toast.success(newsCategoryDelete.message)
            } else {
                dispatch(clearResultNewsCategoryDelete())
                Toast.error(newsCategoryDelete.message)
            }
        }
    }, [newsCategoryDelete, perPage, numberPage])

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="کلیه ی دسته بندی ها" titleLabel="دسته بندی اخبار" icons={ <TiThListOutline className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <div className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg lg:px-10 py-8">
                    <div>
                        {loading && (<Loading/>)}
                        {error && (<Error error={error}/>)}
                        {!loading && error === null && (
                            <DataTable
                                to="/add-category-news"
                                nameTitle="افزودن دسته بندی جدید"
                                columns={columns}
                                data={newsCategoryList}
                                count={count}
                                page={page}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                numberPage={numberPage}
                                setNumberPage={setNumberPage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default CategoryNews;
