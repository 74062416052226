export const arrayObjectToFromDataNT = (input, action) => {

    const formData = new FormData()
    formData.append("action", action)

    for (const formDataKey in input) {
        if (input[formDataKey] && input[formDataKey]!== null &&  input[formDataKey].length > 0 ) {
            const test1 = input[formDataKey];
            if (typeof input[formDataKey] === 'string') {
                formData.append(`${formDataKey}`, input[formDataKey]);
            } else if (typeof input[formDataKey] === 'object') {
                for (const formDataKey2 in test1) {
                    const test2 = test1[formDataKey2];
                    if(typeof test1[formDataKey2] !== 'object'){
                        formData.append(`${formDataKey}[${formDataKey2}]`, test1[formDataKey2]);
                    } else {
                        for (const formDataKey3 in test2) {
                            formData.append(
                                `${formDataKey}[${formDataKey2}][${formDataKey3}]`,
                                test2[formDataKey3]
                            );
                        }
                    }
                }
            }
        } else {
            if(input[formDataKey].length === undefined && typeof input[formDataKey] === 'object'){
                formData.append(`${formDataKey}`, input[formDataKey]);
            }else{
                formData.append(`${formDataKey}`, "");
            }
        }
    }

    return formData
}


//
// for (const formDataKey2 in test1) {
//     const test2 = test1[formDataKey2];
//     if(test1[formDataKey2] !== 'object'){
//         formData.append(`${formDataKey}[${formDataKey2}]`, test1[formDataKey2]);
//     }else{
//         for (const formDataKey3 in test2) {
//             formData.append(
//                 `${formDataKey}[${formDataKey2}][${formDataKey3}]`,
//                 test2[formDataKey3]
//             );
//         }
//     }
// }
