import React, {useEffect} from 'react';
import Input from "../../../components/input/Input";
import ButtonForm from "../../../components/button/ButtonForm";
import * as yup from "yup";
import {useFormik} from "formik";
import {
    clearNewsCategory,
    clearResultNewsCategory,
    getAsyncNewsCategory,
    postAsyncNewsCategory
} from "../../../features/newsCategory/newsCategorySlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {Toast} from "../../../components/toast/Toast";
import {TiTags, TiUserAddOutline} from "react-icons/ti";


const AddCategoryNews = () => {

    const {newsCategory, result, loading, error} = useSelector(state => state.newsCategory)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {news_category_id} = useParams()

    useEffect(() => {
        if (news_category_id !== undefined) {

            dispatch(getAsyncNewsCategory({news_category_id: news_category_id}))
        } else {
            dispatch(clearNewsCategory())
        }
    }, [news_category_id])

    useEffect(() => {
        if (result.status !== undefined) {
            if (result.status === "success") {
                dispatch(clearResultNewsCategory())
                Toast.success(result.message)
                setTimeout(() => {
                    return navigate('/category-news')
                }, 1000)

            } else {
                Toast.error(result.message)
            }
        }
    }, [result])

    const initialValues = {
        news_category_title: "",
        news_category_title_english: "",
    }

    let validationSchema = yup.object({
        news_category_title: yup.string().required("وارد کردن عنوان ضروری است."),
        news_category_title_english: yup.string().required("افزودن عنوان لاتین برای ارسال ضروری است.")
    });

    const onSubmit = (values) => {
        dispatch(postAsyncNewsCategory(values))
    }

    const formik = useFormik({
        initialValues: newsCategory || initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit
    })

    return (

        <div className="bg-gray">
            <LabelDashboard LabelButton="بازگشت" titleLabel="دسته بندی اخبار" icons={ <TiTags className="text-slate-500 dark:text-slate-400 rotate-90" size={25}/>}/>
            <form onSubmit={formik.handleSubmit}
                  className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                <div className="flex flex-col lg:flex-row w-full justify-start gap-6">
                    <Input name="news_category_title" label="عنوان دسته بندی" formik={formik}/>
                    <Input name="news_category_title_english" label="عنوان لاتین دسته بندی" formik={formik}/>
                </div>
                <div className="w-full flex flex-col my-12">
                    <ButtonForm
                        formik={formik}
                        title_button="افزودن دسته بندی جدید"
                        title_button_edit="ویرایش دسته بندی"
                        type={news_category_id}
                        loading={loading}
                    />
                </div>
            </form>
        </div>

    );
};

export default AddCategoryNews;
