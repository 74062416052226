import React, {useEffect} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {TiThListOutline} from "react-icons/ti";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncLogList} from "../../../features/reportsLog/reportsLogSlisc";
import {persianDateNT} from "../../../utility/persianDateNT";
import {useParams} from "react-router-dom";
import {Config} from "../../../config/Config";

const ReportsAdmin = () => {

    const dispatch = useDispatch()
    const {resultLogAll} = useSelector(state => state.reportsLog)
    const {log_admin_id} = useParams()

    useEffect(() => {
        dispatch(getAsyncLogList({log_admin_id: log_admin_id}))
    }, [log_admin_id, dispatch])

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="گزارشات ورود کاربر"
                                icons={<TiThListOutline className="text-slate-500 dark:text-slate-400" size={22}/>}/>
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                    <div className="flex flex-col gap-1">
                        {resultLogAll && resultLogAll?.map((value, index) => {
                            return (
                                <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
                               <div className="w-72">
                                   <div
                                       className="h-64 w-64 rounded-full object-cover object-center justify-center flex overflow-hidden items-center ring ring-blue-500 ring-offset-2">
                                       <img name="image" className="object-cover object-center h-64 w-64"
                                            src={Config.imageUrl + value.image} alt="profile"/>
                                   </div>
                               </div>
                                    <div className="w-full flex flex-col self-start items-center justify-center">
                                        <div
                                            className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 self-start lg:mt-12">
                                            <div className="w-full">
                                                <label
                                                    className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">نام
                                                    کاربر</label>
                                                <span className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300 text-gray-900 rounded-lg text-sm
                                                                  focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5 `}>{value.full_name}</span>
                                            </div>
                                            <div className="w-full">
                                                <label
                                                    className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">شماره
                                                    تماس کاربر</label>
                                                <span className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300 text-gray-900 rounded-lg text-sm
                                                                  focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5 `}>{value.mobile}</span>
                                            </div>
                                            <div className="w-full">
                                                <label
                                                    className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">نقش
                                                    کاربر</label>
                                                <span className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300 text-gray-900 rounded-lg text-sm
                                                                  focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5 `}>{value.role}</span>
                                            </div>
                                            <div className="w-full">
                                                <label
                                                    className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">جنسیت
                                                    کاربر</label>
                                                <span className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300 text-gray-900 rounded-lg text-sm
                                                                  focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5 `}>{value.gender}</span>
                                            </div>
                                            <div className="w-full">
                                                <label
                                                    className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">وضعیت
                                                    کاربر</label>
                                                <span className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300 text-gray-900 rounded-lg text-sm
                                                                  focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5 `}>{value.admin_status === "active" ? "فعال" : "غیرفعال"}</span>
                                            </div>
                                            <div className="w-full">
                                                <label
                                                    className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">ای
                                                    پی کاربر</label>
                                                <span className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300 text-gray-900 rounded-lg text-sm
                                                                  focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5 `}>{value.ip}</span>
                                            </div>
                                            <div className="w-full col-span-2">
                                                <label
                                                    className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">زمان
                                                    ورود</label>
                                                <span className={`caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300 text-gray-900 rounded-lg text-sm
                                                                  focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5 `}>{persianDateNT.unix(value.admin_create_time)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportsAdmin;