import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config} from "../../config/Config";


export const postAsyncLogin = createAsyncThunk('login/postAsyncLogin', async (payload,
                                                                                      {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "login"
        }))
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})


export const postAsyncDetailsSite = createAsyncThunk('login/postAsyncDetailsSite', async (payload,
                                                                                                      {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({action: "get_details_site_Index"}))
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {

    login: false,
    DetailsSite: {},
    token: "",
    isLoading: false,
    isError: null,
    result: {},
    status: "idle"

}
const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        clearLogin: (state) => {
            state.login = false
        }
    },
    extraReducers: {

        [postAsyncLogin.fulfilled]: (state, action) => {
            return {...state, login: action.payload, isLoading: false, isError: false}
        },
        [postAsyncLogin.pending]: (state, action) => {
            return {...state, login: false, isLoading: true, isError: true}
        },
        [postAsyncLogin.rejected]: (state, action) => {
            return {...state, login: false, isLoading: false, isError: true}
        },

        [postAsyncDetailsSite.fulfilled]: (state, action) => {
            return {...state, DetailsSite: action.payload.result, isLoading: false, isError: false}
        },
        [postAsyncDetailsSite.pending]: (state, action) => {
            return {...state, DetailsSite: {}, isLoading: true, isError: true}
        },
        [postAsyncDetailsSite.rejected]: (state, action) => {
            return {...state, DetailsSite: {}, isLoading: false, isError: true}
        }
    }

})

export const {clearLogin} = loginSlice.actions;

export default loginSlice.reducer
