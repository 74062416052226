import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    dark: false
}

const themeSlice = createSlice({
    name: "themeAdmin",
    initialState,
    reducers: {
        changeThemeReducer: (state,action) => {
            state.dark = action.payload
        }
    }})

export const {changeThemeReducer} = themeSlice.actions;

export default themeSlice.reducer
