import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";

export const postAsyncTeam = createAsyncThunk('team/postAsyncTeam', async (payload,
                                                                                   {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "add_team_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncTeamList = createAsyncThunk('team/getAsyncTeamList', async (payload,
                                                                                 {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_team_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncTeam = createAsyncThunk('team/getAsyncTeam', async (payload, {rejectWithValue}) => {

    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_team_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const deleteAsyncTeam = createAsyncThunk('team/deleteAsyncTeam', async (payload,
                                                                           {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "delete_team_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    teamList: [],
    team: {},
    teamDelete: {},
    count: 1,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const teamSlice = createSlice ({

    name: "team",
    initialState,
    reducers: {
        clearResultTeam: (state, action) => {
            state.result = {}
        },
        clearTeam: (state, action) => {
            state.team = false
        },
        clearTeamDelete: (state, action) => {
            state.teamDelete = {}
        },
    },
    extraReducers: {
        [getAsyncTeamList.fulfilled]: (state, action) => {
            return {
                ...state,
                teamList: action.payload.result.team,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null,
            }
        },
        [getAsyncTeamList.pending]: (state, action) => {
            return {
                ...state,
                teamList: [],
                count: 1,
                page: 1,
                loading: true,
                error: null
            }
        },
        [getAsyncTeamList.rejected]: (state, action) => {
            return {
                ...state,
                teamList: [],
                count: 1,
                page: 1,
                loading: false,
                error: "error"
            }
        },

        [getAsyncTeam.fulfilled]: (state, action) => {
            return {
                ...state,
                team: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncTeam.pending]: (state, action) => {
            return {
                ...state,
                team: {},
                loading: true,
                error: null
            }
        },
        [getAsyncTeam.rejected]: (state, action) => {
            return {
                ...state,
                team: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncTeam.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                loading: false,
                error: null,
            }
        },
        [postAsyncTeam.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: true,
                error: null
            }
        },
        [postAsyncTeam.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [deleteAsyncTeam.fulfilled]: (state, action) => {
            return {
                ...state,
                teamDelete: action.payload,
                loading: false,
                error: null,
            }
        },
        [deleteAsyncTeam.pending]: (state, action) => {
            return {
                ...state,
                teamDelete: {},
                loading: true,
                error: null
            }
        },
        [deleteAsyncTeam.rejected]: (state, action) => {
            return {
                ...state,
                teamDelete: {},
                loading: false,
                error: ConfigMessage.error
            }
        },
    }

})

export const {clearResultTeam,clearTeam,clearTeamDelete} = teamSlice.actions;

export default teamSlice.reducer
