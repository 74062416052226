import React from 'react';

import {Link} from "react-router-dom";


const OperatorLink = ({to, icon, label,labelShow = true, classCustom , key}) => {
    return (
        <Link key={key} to={to} className={classCustom}>
          <span className={`flex flex-row items-center gap-1 text-white text-xs md:text-sm ${!labelShow && ('p-1') }`} title={label}>
            {icon}
              {labelShow && (
                  <span>{label}</span>
              )}
          </span>
        </Link>

    );
};

export default OperatorLink;
