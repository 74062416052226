import React, {useEffect, useRef, useState} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {Toast} from "../../../components/toast/Toast";
import {clearResultAltMedia, getAsyncMediaList, postAsyncMediaAlt} from "../../../features/media/mediaSlice";
import axios from "axios";
import {Config} from "../../../config/Config";
import {serialize} from "object-to-formdata";
import {useDispatch, useSelector} from "react-redux";
import {TiImage} from "react-icons/ti";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";
import {FiCheckCircle, FiTrash} from "react-icons/fi";
import Alert from "../../../components/toast/Alert";
import Paging from "../../../components/dataTable/Paging";
import useWindowSize from "../../../customHook/useWindowSize";
import {IoMdInformation} from "react-icons/io";
import {IoCloseCircleOutline} from "react-icons/io5";


const AddGallery = () => {

    const {mediaList, resultAlt, count, page, loading, error} = useSelector(state => state.media)
    const dispatch = useDispatch()
    const refUpload = useRef()

    const [perPage, setPerPage] = useState(48);
    const [numberPage, setNumberPage] = useState(1);
    const {height, width} = useWindowSize();

    const [showItems, setShowItems] = useState(false)
    const [selectedItem, setSelectedItem] = useState([])
    const [uploadPercent, setUploadPercent] = useState(0)

    useEffect(() => {
        if(width !== undefined){
            if (width > 1024) {
                setPerPage(48)
            } else {
                setPerPage(8)
            }
            dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
        }
    }, [width, perPage, numberPage])

    const addFileHandler = (e) => {
        e.preventDefault()
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size < 2200000) {
                UploadMediaAxios({uploadImage: e.target.files[0]}).then((res) => {
                    if (res.status === 'success') {
                        Toast.success(res.message)
                        setUploadPercent(100)
                        dispatch(getAsyncMediaList({
                            row_per_page: parseInt(perPage),
                            page_number: parseInt(numberPage)
                        }))
                        setTimeout(() => {
                            setUploadPercent(0)
                        }, 1000)
                    } else {
                        Toast.warning(res.message)
                        setUploadPercent(0)
                    }
                })
                refUpload.current.value = ""
            } else {
                Toast.warning("حجم فایل نباید بیشتر از 20kb باشد.")
            }
        }
    };

    const config = {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent < 100) {
                setUploadPercent(percent)
            }
        },
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }

    const UploadMediaAxios = async (payload) => {
        const result = await axios.post(Config.api, serialize({...payload, action: "add_media_admin"}), config)
        return result.data
    }

    const removeMediaAxios = async (payload) => {
        const result = await axios.post(Config.api, serialize({...payload, action: "delete_media_admin"}), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        return result.data
    }

    const selectHandler = (input) => {
        setSelectedItem([input])
    }

    const [showAlertPage, setShowAlertPage] = useState(false)
    const [showAlertValue, setShowAlertValue] = useState("")

    const showAlertDelete = (input) => {
        setShowAlertPage(true)
        setShowAlertValue(input)
    }

    const [showAlertInfPage, setShowAlertInfPage] = useState(false)
    const [showAlertInfoValue, setShowAlertInfoValue] = useState({media_id: "", media_alt: "", media_url: ""})

    const showAlertInfo = (input) => {
        setShowAlertInfPage(true)
        setShowAlertInfoValue(input)
    }

    const removeMediaFunc = () => {
        removeMediaAxios({media_id: showAlertValue}).then((res) => {
            if (res.status === 'success') {
                Toast.success(res.message)
                dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                setSelectedItem([])
                setShowAlertPage(false)
            } else {
                Toast.warning(res.message)
                setShowAlertPage(false)
            }
        })
    }

    useEffect(() => {
        if (resultAlt.status !== undefined) {
            if (resultAlt.status === "success") {
                dispatch(clearResultAltMedia())
                Toast.success(resultAlt.message)
                dispatch(getAsyncMediaList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                setShowAlertInfPage(false)
            } else {
                Toast.error(resultAlt.message)
            }
        }
    }, [resultAlt])

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard titleButton="افزودن رسانه" to={() => setShowItems(!showItems)} titleLabel="رسانه"
                                icons={<TiImage className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                {showItems &&
                    <div
                        className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">

                        <div>
                            <div className="flex flex-col lg:flex-row w-full items-center justify-center gap-2">
                                <label className="text-gray-700 text-sm font-bold dark:text-gray-200 cursor-pointer">
                                    رسانه خود را انتخاب کنید
                                </label>
                                <input ref={refUpload} type="file" onChange={(event) => addFileHandler(event)}
                                       className="text-sm text-gray-500 file:py-2 file:px-4 file:rounded-full file:border-0
                                        file:text-sm file:font-bold file:bg-blue-100 dark:file:bg-blue-800 file:text-blue-800 dark:file:text-blue-100
                                        hover:file:text-blue-600 hover:file:bg-white dark:hover:file:bg-gray-900"/>
                            </div>
                        </div>
                        {uploadPercent > 0 && (
                            <div className="w-full bg-neutral-200 dark:bg-neutral-600 my-5 rounded-full">
                                <div
                                    className="bg-blue-500 p-0.5 text-center text-xs font-medium leading-none text-white rounded-full"
                                    style={{width: `${uploadPercent}%`}}>
                                    {uploadPercent}%
                                </div>
                            </div>
                        )}
                    </div>}
            </div>
            <div className="bg-gray">
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 xl:px-10 py-8">
                    <div className="flex flex-col w-full gap-3 justify-center items-center">
                        {loading && (<Loading/>)}
                        {error && (<Error error={error}/>)}
                        {!loading && error === null && mediaList && (
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="w-full self-start grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 2xl:grid-cols-8 gap-2 my-2 md:my-5 self-start">
                                    {mediaList.map((value, index) => {
                                        return (
                                            <div key={index} onClick={() => {
                                                selectHandler({id: parseInt(value.media_id), url: value.media_url})
                                            }} className="flex group w-28 h-28 container object-center justify-center overflow-hidden border-2 dark:border-gray-600 p-1 bg-gray-100 dark:bg-gray-700 shadow rounded dark:hover:bg-gray-600 hover:bg-gray-300 cursor-pointer relative">
                                                <img onClick={() => {
                                                    showAlertInfo({
                                                        media_id: value.media_id,
                                                        media_alt: value.media_alt,
                                                        media_url: value.media_url,
                                                    })
                                                }} className="object-center container object-cover rounded"
                                                     src={Config.imageUrl + value.media_url} alt=""/>
                                                {selectedItem.find(item => item.id === parseInt(value.media_id)) && (
                                                    <FiCheckCircle size={30} className="absolute left-1/2 top-1/2 transition -translate-x-1/2 -translate-y-1/2 text-blue-500 bg-white/40 rounded-full "/>
                                                )}
                                                <FiTrash onClick={() => {
                                                    showAlertDelete(value.media_id)
                                                }} size={23} className="invisible group-hover:visible p-0.5 absolute left-1 top-1 text-red-500 bg-gray-50 hover:bg-gray-300 rounded-full "/>
                                                <IoMdInformation onClick={() => {
                                                    showAlertInfo({
                                                        media_id: value.media_id,
                                                        media_alt: value.media_alt,
                                                        media_url: value.media_url,
                                                    })
                                                }} size={23} className="invisible group-hover:visible absolute left-8 top-1 text-yellow-600 bg-gray-50 hover:bg-gray-300 rounded-full "/>
                                            </div>
                                        )
                                    })}
                                </div>
                                {showAlertInfPage &&
                                    <div className="flex flex-col self-start items-center justify-center gap-4 border-2 border-gray-100 dark:border-gray-500 rounded-lg my-5 p-2 w-64">
                                        <div onClick={() => {
                                            setShowAlertInfPage(false)
                                        }} className="w-full flex flex-row cursor-pointer justify-between">
                                            <label
                                                className="text-gray-700 text-sm font-bold dark:text-gray-200 cursor-pointer">
                                                توضیحات تصویر
                                            </label>
                                            <IoCloseCircleOutline size={25}
                                                                  className="hover:text-blue-400 dark:text-gray-200 text-gray-400"/>
                                        </div>
                                        <div className="flex flex-col gap-4 my-6 items-center justify-center">
                                            <div
                                                className="h-40 w-40 rounded-lg object-cover object-center justify-center flex overflow-hidden items-center">
                                                <img className="object-center h-40 w-40 container object-cover rounded"
                                                     src={Config.imageUrl + showAlertInfoValue.media_url} alt=""/>
                                            </div>
                                            <input hidden type="text" value={showAlertInfoValue.media_id}
                                                   name="media_id"/>
                                            <div className="media_alt">
                                                <label htmlFor="media_id"
                                                       className="w-full block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    متن جایگزین تصویر
                                                </label>
                                                <textarea className="caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words dark:border-gray-600 border border-gray-300
                                            text-gray-900 rounded-lg text-sm focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full p-2.5"
                                                          placeholder={showAlertInfoValue.media_alt} name="media_alt"
                                                          id="media_alt" rows={6}
                                                          onChange={(event) => setShowAlertInfoValue({
                                                              ...showAlertInfoValue,
                                                              media_alt: event.target.value,
                                                          })}/>
                                            </div>
                                            <div
                                                className="w-full flex py-2 items-center justify-center bg-blue-600 dark:bg-blue-600 text-sm text-gray-100 rounded font-bold cursor-pointer"
                                                onClick={() => dispatch((postAsyncMediaAlt(showAlertInfoValue)))}>
                                                ارسال
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}

                        <Alert
                            showAlertPage={showAlertPage}
                            setShowAlertPage={setShowAlertPage}
                            title="اخطار"
                            label="آیا برای حذف این فایل مطمئن هستید‌؟"
                            onclick={removeMediaFunc}/>
                    </div>
                    <nav
                        className="flex flex-col lg:flex-row w-full gap-2 bg-gray-200/70 dark:bg-gray-400/70 mt-2  justify-between items-center p-2 px-4 rounded"
                        aria-label="Table navigation">
                        <div className="flex flex-row gap-2 justify-center items-center">
                            <span className="text-xs font-normal text-gray-600 dark:text-gray-200">درحال نمایش </span>
                            <span
                                className="font-bold text-gray-800 dark:text-white">{((parseInt(numberPage) - 1) * parseInt(perPage)) + 1}-{parseInt(perPage) * parseInt(numberPage) < count ? parseInt(perPage) * parseInt(numberPage) : count}</span>
                            <span className="text-xs font-normal text-gray-600 dark:text-gray-200">از </span>
                            <span className="font-bold text-gray-900 dark:text-white">{count}</span>
                        </div>
                        <Paging page={page} setNumberPage={setNumberPage} numberPage={numberPage}/>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default AddGallery;