import React from 'react'
import {Config} from "../../../config/Config"
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {TiFeather} from "react-icons/ti";

const AboutNilfam = () => {

    return (

        <div className="flex flex-col">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="درباره نرم افزار"
                                icons={<TiFeather className="text-slate-500 dark:text-slate-400 rotate-45"
                                                  size={25}/>}/>
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 text-gray-500 text-xs md:text-base text-right mb-6 rounded-lg px-5 lg:px-10 py-8">
                    <div className='flex flex-col gap-12'>
                        <span className='font-bold text-lg text-blue-500 dark:text-blue-500'>اطلاعات برنامه :</span>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <p>نسخه در حال استفاده :</p>
                        <p>{Config.version}</p>
                        <p className='dark:text-gray-200 text-gray-700'>( شما در حال استفاده از آخرین نسخه این برنامه
                            هستید .)</p>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <p>نام سامانه :</p>
                        <p>{Config.loginTitle}</p>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <p>درباره :</p>
                        <p>{Config.about}</p>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <p>تیم توسعه :</p>
                        <a href='https://nilfamTech.ir/' target="_blank"
                           className='font-serif italic underline decoration-gray-400 dark:decoration-gray-200 decoration-double underline-offset-2'>
                            NilfamTech.ir
                        </a>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <p>پشتیبانی :</p>
                        <p>NilfamTech.ir-net@</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutNilfam
