import React, {useEffect} from 'react';
import ToastContainer from "../../components/toast/ToastContainer";
import {Config} from "../../config/Config";
import imageBack from "../../assets/image/background/steel_varage.jpg";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import Input from "../../components/input/Input";
import {
    clearLogin,
    postAsyncDetailsSite,
    postAsyncLogin
} from "../../features/login/loginSlice";
import {Toast} from "../../components/toast/Toast";
import {useNavigate} from "react-router-dom";


const Login = () => {

    const {login ,DetailsSite} = useSelector(state => state.login)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(postAsyncDetailsSite())
    } ,[])

    const initialValues = {
        username: "",
        password: ""
    }

    let validationSchema = yup.object({
        username: yup.string().required("نام کاربری ضروری است.").min(4, "نام کاربری نباید کمتر از 4 کارکتر باشد"),
        password: yup.string().required("افزودن گذرواژه برای ارسال ضروری است.").min(6, "گذرواژه نباید کمتر از 6 کارکتر باشد")

    });

    const onSubmit = (values) => {
         dispatch(postAsyncLogin(values))
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit
    })

    useEffect(() => {
        if (login.status !== undefined) {
            if (login.status === "success") {
                localStorage.setItem("token", login.result.token)
                Toast.success(login.message)
                dispatch(clearLogin())
                setTimeout(() => {
                    return navigate("/dashboard")
                }, 1000)
            } else {
                Toast.error(login.message)
                dispatch(clearLogin())
            }
        }
    }, [login])

    return (
        <div className="flex flex-col lg:grid grid-cols-3 gap-4 lg:gap-1 items-center justify-center bg-gray-50 dark:bg-gray-800">
            <div className="w-full h-screen flex flex-col gap-4 items-center justify-center">
                <div className="flex flex-col gap-5 items-center justify-center w-96 h-fit px-10">
                    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5 w-full">
                        <div className="flex flex-col gap-4 pb-10 items-center justify-center">
                            <img src={Config.imageUrl + DetailsSite.setting_logo_panel} className="h-fit w-32 lg:w-52" alt=""/>
                            <h1 className="w-full font-bold dark:text-gray-200 text-center text-xl">{DetailsSite.setting_title_panel}</h1>
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Input classLabel="text-slate-300" classColor="dark:bg-gray-800/70 bg-gray-600/70 text-gray-100" dir="ltr" formik={formik} name="username" label="نام کاربری"/>
                            <Input classLabel="text-slate-300" classColor="dark:bg-gray-800/70 bg-gray-600/70 text-gray-100" dir="ltr" formik={formik} name="password" label="رمز عبور" type="password"/>
                        </div>
                        <div className="w-full flex flex-row gap-2 py-2 justify-start">
                            <button disabled={!formik.isValid} className="w-full flex py-2 items-center justify-center
                                disabled:dark:bg-gray-900 disabled:dark:text-gray-500 disabled:bg-gray-500 disabled:text-gray-400 bg-blue-500 text-gray-100 rounded font-bold">ورود</button>
                        </div>
                    </form>
                </div>
                <div className="text-gray-700 dark:text-gray-200 text-center py-8 text-sm">
                    <a target="_blank" className="font-bold" href="https://nilfamtech.ir/">{Config.authorName}</a>
                </div>
            </div>
            <div className="hidden lg:block w-full lg:col-span-2">
                <div  className="w-full h-screen bg-blue-500 flex-col gap-4 items-center justify-center object-cover overflow-hidden">
                    <img src={imageBack} className="w-full h-full object-cover object-center opacity-60" alt=""/>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default Login;
