import React, {useEffect, useRef} from 'react';


const Input = ({
                   formik ={}, name ="", error = false, helperText = "",
                   require = false, isFocus = false, desc = false,
                   changesClass = "", label ="", placeholder = null,
                   type = "text", disabled = false, dir = 'rtl',
                   onlyNum = false, maxLength = 30000, textErrorLabel = false , icon = false
               }) => {
    let errorsStyle = formik.errors[name] && formik.touched[name] ? 'border-pink-500 dark:border-pink-600' : ''


    //Only Type Number
    const onKeyPressHandler = (e) => {
        if (onlyNum) {
            if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
            }
        }
    }


    const test = useRef()
    useEffect(() => {
        if (isFocus) {
            test.current.focus()
        }

    }, [])


    return (
        <div className="w-full">
            <div className="flex flex-row gap-1 justify-between items-center mb-2 ">
                {icon}
                <label htmlFor={name}
                       className="w-full block text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
                {textErrorLabel && <span dir="ltr" className="text-blue-500 text-xs min truncate">{textErrorLabel}</span>}
            </div>
            <input disabled={disabled} ref={test} dir={dir} {...formik.getFieldProps(name)}
                   maxLength={maxLength} onKeyPress={onKeyPressHandler}
                   type={type} id={name} name={name}
                   className={`${errorsStyle} caret-blue-500 bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 break-words
             dark:border-gray-600 border border-gray-300 text-gray-900 rounded-lg text-sm
              focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 
               block w-full p-2.5 ${changesClass}`} placeholder={placeholder}/>
            {!error ?
                formik.errors[name] && formik.touched[name] && (
                    <p className="mt-2 text-pink-600 text-xs">
                        {formik.errors[name]}
                    </p>
                )
                : (
                    <p className="mt-2 text-pink-600 text-xs">
                        {helperText}
                    </p>
                )}
        </div>
    )
}

export default Input;
