import React, {useEffect} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {TiLocationArrowOutline} from "react-icons/ti";
import Input from "../../../components/input/Input";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {clearResultContact} from "../../../features/setting/settingSlice";
import {Toast} from "../../../components/toast/Toast";
import {editAsyncSocialNetwork, getAsyncSocialNetwork} from "../../../features/socialNetwork/socialNetworkSlice";
import ButtonForm from "../../../components/button/ButtonForm";
import {
    TbBrandFacebook,
    TbBrandInstagram,
    TbBrandLinkedin,
    TbBrandTelegram,
    TbBrandTwitter,
    TbBrandYoutube
} from "react-icons/tb";
import {SiAparat} from "react-icons/si";
import {BsWhatsapp} from "react-icons/bs";
import {IoPlanetOutline} from "react-icons/io5";


const SocialNetwork = () => {

    const {network, result} = useSelector(state => state.network)
    const dispatch = useDispatch()

    useEffect(() => {
        if (result.status !== undefined) {
            if (result.status === "success") {
                dispatch(clearResultContact())
                Toast.success(result.message)
            } else {
                Toast.error(result.message)
            }
        }
    }, [result])

    useEffect(() => {
        dispatch(getAsyncSocialNetwork())
    }, [dispatch])

    const initialValues = {
        telegram_channel: "",
        instagram_channel: "",
        twitter_channel: "",
        youtube_channel: "",
        linkedin_channel: "",
        aparat_channel: "",
        facebook_channel: "",
    }

    const onSubmit = (values) => {
        dispatch(editAsyncSocialNetwork(values))
    }

    const formik = useFormik({
        initialValues: network || initialValues,
        enableReinitialize: true,
        onSubmit
    })

    return (

        <div className="flex flex-col gap-6">
            <div
                className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="شبکه های اجتماعی"
                                icons={<TiLocationArrowOutline className="text-slate-500 dark:text-slate-400"
                                                               size={25}/>}/>
                <form onSubmit={formik.handleSubmit}
                      className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                    <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-6 items-center">
                        <div
                            className="flex flex-row gap-4 items-center justify-start  dark:border-gray-600 border border-gray-300 rounded-lg bg-gray-50 dark:bg-[#1f2937] p-3 my-6">
                            <span
                                className="text-sm font-blold text-gray-600 dark:text-gray-200">نمونه درست آدرس دهی</span>
                            <span className="text-sm font-blold text-gray-600 dark:text-gray-200">==></span>
                            <span
                                className="text-sm font-blold text-blue-600 dark:text-blue-400">https://t.me/nilfam</span>
                        </div>
                        <div>

                        </div>
                        <Input name="telegram_channel" label="آیدی تلگرام" formik={formik}
                               icon={<TbBrandTelegram className="text-blue-500" size={25}/>}/>
                        <Input name="instagram_channel" label="آیدی اینستاگرام" formik={formik}
                               icon={<TbBrandInstagram className="text-blue-500" size={25}/>}/>
                        <Input name="twitter_channel" label="آیدی تویئتر" formik={formik}
                               icon={<TbBrandTwitter className="text-blue-500" size={25}/>}/>
                        <Input name="youtube_channel" label="آیدی یوتویوپ" formik={formik}
                               icon={<TbBrandYoutube className="text-blue-500" size={25}/>}/>
                        <Input name="linkedin_channel" label="آیدی لینک دین" formik={formik}
                               icon={<TbBrandLinkedin className="text-blue-500" size={25}/>}/>
                        <Input name="aparat_channel" label="آدرس آپارات" formik={formik}
                               icon={<SiAparat className="text-blue-500" size={25}/>}/>
                        <Input name="facebook_channel" label="آیدی فیس بوک" formik={formik}
                               icon={<TbBrandFacebook className="text-blue-500" size={25}/>}/>
                        <Input name="whatsapp_channel" label="آیدی واتس آپ" formik={formik}
                               icon={<BsWhatsapp className="text-blue-500" size={25}/>}/>
                        <Input name="rubika_channel" label="آیدی روبیکا" formik={formik}
                               icon={<IoPlanetOutline className="text-blue-500" size={25}/>}/>
                        <Input name="eitaa_channel" label="آیدی ایتا" formik={formik}
                               icon={<IoPlanetOutline className="text-blue-500" size={25}/>}/>
                        <Input name="bale_channel" label="آیدی بله" formik={formik}
                               icon={<IoPlanetOutline className="text-blue-500" size={25}/>}/>
                    </div>

                    <div className="my-20">
                        <ButtonForm
                            formik={formik}
                            title_button="ذخیره تغییرات"
                        />
                    </div>
                </form>
            </div>
        </div>


    );
};

export default SocialNetwork;