import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {Config} from "../../config/Config";
import formUrlEncoded from "form-urlencoded";

export const getAsyncUserList = createAsyncThunk('user/getAsyncUserList', async (payload, {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_user_index"
        }))
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})
const initialState = {
    userList: [],
    count: 1,
    page: 1,
    loading: false,
    error: null,
    status: "idle"
}
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    exportReducers: {
        [getAsyncUserList.fulfilled]:{

        },
        [getAsyncUserList.pending]:{

        },
        [getAsyncUserList.rejected]:{

        }
    }
})

export const {} = userSlice.actions;
export default userSlice.reducer
