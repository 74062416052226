import React, {useEffect} from 'react';
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {
    clearResultContact,
    editAsyncContact,
    getAsyncContact
} from "../../../features/setting/settingSlice";
import {Toast} from "../../../components/toast/Toast";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import Input from "../../../components/input/Input";
import ButtonForm from "../../../components/button/ButtonForm";
import {TiMessageTyping} from "react-icons/ti";


const Contact = () => {

    const {contact, resultContact} = useSelector(state => state.setting)
    const dispatch = useDispatch()

    useEffect(() => {
        if (resultContact.status !== undefined) {
            if (resultContact.status === "success") {
                dispatch(clearResultContact())
                Toast.success(resultContact.message)
            } else {
                Toast.error(resultContact.message)
            }
        }
    }, [resultContact])

    useEffect(() => {
        dispatch(getAsyncContact())
    }, [dispatch])

    const initialValues = {
        contact_title: "",
        contact_address: "",
        contact_phone_one: "",
        contact_phone_two: "",
        contact_email: "",
        contact_zipcode: "",

        contact_title2: "",
        contact_address2: "",
        contact_phone_one2: "",
        contact_phone_two2: "",
        contact_email2: "",
        contact_zipcode2: "",
    }

    const onSubmit = (values) => {
        dispatch(editAsyncContact(values))
    }

    const formik = useFormik({
        initialValues: contact || initialValues,
        enableReinitialize: true,
        onSubmit
    })

    return (

        <div className="flex flex-col gap-6">
            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
                <div className="bg-gray">
                    <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="صفحه ارتباط با ما"
                                    icons={<TiMessageTyping className="text-slate-500 dark:text-slate-400"
                                                            size={25}/>}/>
                    <div
                        className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                        <div className="flex flex-col gap-4">
                            <Input name="contact_title" label="نام شعبه" formik={formik}/>
                            <Input name="contact_address" label="آدرس شرکت" formik={formik}/>
                           <div className="flex flex-col md:flex-row gap-4">
                               <Input name="contact_phone_one" label="خط تلفن اول" formik={formik}/>
                               <Input name="contact_phone_two" label="خط تلفن دوم" formik={formik}/>
                           </div>
                            <Input name="contact_email" label="ایمیل شرکت" formik={formik}/>
                            <Input name="contact_zipcode" label="کد پستی" formik={formik}/>
                        </div>
                        <div className="flex flex-col gap-4 mt-4 lg:mt-10">
                            <Input name="contact_title2" label="نام شعبه" formik={formik}/>
                            <Input name="contact_address2" label="آدرس شرکت" formik={formik}/>
                           <div className="flex flex-col md:flex-row gap-4">
                               <Input name="contact_phone_one2" label="خط تلفن اول" formik={formik}/>
                               <Input name="contact_phone_two2" label="خط تلفن دوم" formik={formik}/>
                           </div>
                            <Input name="contact_email2" label="ایمیل شرکت" formik={formik}/>
                            <Input name="contact_zipcode2" label="کد پستی" formik={formik}/>
                        </div>
                        <div className="my-4 md:my-8 lg:my-12">
                            <ButtonForm
                                formik={formik}
                                title_button="ذخیره تغییرات"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
};

export default Contact;
