import React, {useEffect, useState} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {useDispatch, useSelector} from "react-redux";
import {
    clearResultManagerDelete,
    deleteAsyncManager,
    getAsyncManagersList
} from "../../../features/managers/managersSlice";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import {TbEdit} from "react-icons/tb";
import OperatorButton from "../../../components/dataTable/OperatorButton";
import {MdDelete} from "react-icons/md";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";
import DataTable from "../../../components/dataTable/DataTable";
import {TiThListOutline} from "react-icons/ti";
import {Toast} from "../../../components/toast/Toast";


const Manager = () => {

    const {adminsList, managerDelete, loading, error, count, page} = useSelector(state => state.managers)
    const dispatch = useDispatch()
    const [perPage, setPerPage] = useState(15);
    const [numberPage, setNumberPage] = useState(1);

    useEffect(() => {
        dispatch(getAsyncManagersList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage, numberPage])

    const columns = [
        {
            name: "نام و نام خانوادگی",
            selector: row => row.full_name,
        },
        {
            name: "نام کاربری",
            selector: row => row.username,
        },
        {
            name: "موبایل",
            selector: row => row.mobile,
        },

        {
            name: "نقش",
            selector: row => (
                <span className={`font-bold text-xs`}>
                    {row.role}
                </span>
            )
        },
        {
            name: "عملیات",
            style: {
                width: '350px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center gap-2">
                        <OperatorLink to={'/change-password-general/' + row.admin_id} icon={<TbEdit size={20}/>}
                                      label="ویرایش رمزعبور" classCustom="btn-teal"/>

                        <OperatorLink to={'/add-manager/' + row.admin_id} icon={<TbEdit size={20}/>}
                                      label="ویرایش" classCustom="btn-blue"/>

                        <OperatorButton onClick={() => dispatch(deleteAsyncManager({admin_id: row.admin_id}))}
                                        icon={<MdDelete size={20}/>}
                                        label="حذف"
                                        classCustom={row.admins_status === "حذف" ? "btn-disable" : "btn-red"}
                                        disabledAction={row.admins_status === "حذف"}/>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        if (managerDelete.status !== undefined) {
            if (managerDelete.status === "success") {
                dispatch(getAsyncManagersList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                dispatch(clearResultManagerDelete())
                Toast.success(managerDelete.message)
            } else {
                dispatch(clearResultManagerDelete())
                Toast.error(managerDelete.message)
            }
        }
    }, [managerDelete, adminsList, perPage, numberPage])

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="مدیران سایت" icons={ <TiThListOutline className="text-slate-500 dark:text-slate-400" size={20}/>}/>
                <div className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg lg:px-10 py-8">
                    {loading && (<Loading/>)}
                    {error && (<Error error={error}/>)}
                    {!loading && error === null && (
                        <DataTable
                            to="/add-manager"
                            nameTitle="افزودن مدیر جدید"
                            columns={columns}
                            data={adminsList}
                            count={count}
                            page={page}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            numberPage={numberPage}
                            setNumberPage={setNumberPage}/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Manager;
