import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearNewsDelete, deleteAsyncNews, getAsyncNewsList} from "../../../features/news/newsSlice";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";
import {persianDateNT} from "../../../utility/persianDateNT";
import {TbEdit} from "react-icons/tb";
import {MdDelete} from "react-icons/md";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import DataTable from "../../../components/dataTable/DataTable";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import OperatorButton from "../../../components/dataTable/OperatorButton";
import Alert from "../../../components/toast/Alert";
import {TiThListOutline} from "react-icons/ti";
import {Toast} from "../../../components/toast/Toast";


const ListNews = () => {

    const [perPage, setPerPage] = useState(10);
    const [numberPage, setNumberPage] = useState(1);

    const {newsList, newsDelete, loading, error, count, page} = useSelector(state => state.news)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncNewsList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage, numberPage])

    const columns = [
        {
            id:1,
            name: "عنوان خبر",
            selector: row => row.news_title,
        },
        {
            id:2,
            name: "نویسنده",
            style: {
                width: '220px'
            },
            selector: row => (<span className={`font-bold text-xs`}>{row.full_name}</span>)
        },
        {
            id:3,
            name: "تاریخ ثبت",
            style: {
                width: '220px'
            },
            selector: row => persianDateNT.date(row.news_create_time)
            // selector: row => (<span className="" dir="ltr">{row.message_create_time}</span>)
        },
        {
            id:4,
            name: "عملیات",
            style: {
                width: '220px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center gap-2">
                        <OperatorLink to={'/add-news/' + row.news_id} icon={<TbEdit size={20}/>}
                                      label="ویرایش" classCustom="btn-blue"/>
                        <OperatorButton onClick={() => showAlert(row.news_id)} icon={<MdDelete size={20}/>}
                                        label="حذف"
                                        classCustom={row.news_status === "حذف" ? "btn-disable" : "btn-red"}
                                        disabledAction={row.news_status === "حذف"}/>
                    </div>
                </div>
            )
        }
    ];

    //? for toast delete ///
    const [showAlertPage, setShowAlertPage] = useState(false)
    const [showAlertValue, setShowAlertValue] = useState("")
    const showAlert = (input) => {
        setShowAlertPage(true)
        setShowAlertValue(input)
    }
    const removeFunc = () => {
        dispatch(deleteAsyncNews({news_id: showAlertValue}))
    }

    useEffect(() => {
        if (newsDelete.status !== undefined) {
            if (newsDelete.status === "success") {
                dispatch(getAsyncNewsList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                dispatch(clearNewsDelete())
                Toast.success(newsDelete.message)
                setShowAlertPage(false)
            } else {
                dispatch(clearNewsDelete())
                Toast.error(newsDelete.message)
            }
        }
    }, [newsDelete, newsList, perPage, numberPage])

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="خبر های کار شده"
                                icons={<TiThListOutline className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <div
                    className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg  lg:px-10 py-8">
                    <div className="flex flex-col gap-1 py-2">
                        {loading && (<Loading/>)}
                        {error && (<Error error={error}/>)}
                        {!loading && error === null && (
                            <DataTable
                                to="/add-news"
                                nameTitle="افزودن خبر جدید"
                                columns={columns}
                                data={newsList}
                                count={count}
                                page={page}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                numberPage={numberPage}
                                setNumberPage={setNumberPage}
                            />
                        )}
                    </div>

                    <Alert
                        showAlertPage={showAlertPage}
                        setShowAlertPage={setShowAlertPage}
                        title="اخطار"
                        label="آیا برای حذف مطمئن هستید‌؟"
                        onclick={removeFunc}
                    />
                </div>
            </div>
        </div>
    );
};

export default ListNews;
