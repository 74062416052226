import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";


export const postAsyncContact = createAsyncThunk('contact/postAsyncContact', async (payload,
                                                                                        {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "post_contact_form_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncContactList = createAsyncThunk('contact/getAsyncContactList', async (payload,
                                                                                          {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_contact_form_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncContact = createAsyncThunk('contact/getAsyncContact', async (payload,
                                                                         {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_contact_form_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    contactList: [],
    contact: {},
    count: 1,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const contactSlice = createSlice({

    name: "contact",
    initialState,
    reducers: {
        clearResultContact : (state, action) => {
            state.result = {}
        },
        clearContact: (state, action) => {
            state.contact = false
        },
    },
    extraReducers: {
        [getAsyncContactList.fulfilled]: (state, action) => {
            return {
                ...state,
                contactList: action.payload.result.contact_us,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null,
            }
        },
        [getAsyncContactList.pending]: (state, action) => {
            return {
                ...state,
                contactList: [],
                count: 1,
                page: 1,
                loading: true,
                error: null
            }
        },
        [getAsyncContactList.rejected]: (state, action) => {
            return {
                ...state,
                contactList: [],
                count: 1,
                page: 1,
                loading: false,
                error: ConfigMessage.error
            }
        },

        [getAsyncContact.fulfilled]: (state, action) => {
            return {
                ...state,
                contact: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncContact.pending]: (state, action) => {
            return {
                ...state,
                contact: {},
                loading: true,
                error: null
            }
        },
        [getAsyncContact.rejected]: (state, action) => {
            return {
                ...state,
                contact: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncContact.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                loading: false,
                error: null,
            }
        },
        [postAsyncContact.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: true,
                error: null
            }
        },
        [postAsyncContact.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: false,
                error: ConfigMessage.error
            }
        },
    }
})

export const {clearResultContact,clearContact} = contactSlice.actions;

export default contactSlice.reducer
