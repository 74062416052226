import React, {useEffect} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import InputImageUpload from "../../../components/input/InputImageUpload";
import Input from "../../../components/input/Input";
import JoditEditorText from "../../../components/editor/JoditEditorText";
import ButtonForm from "../../../components/button/ButtonForm";
import {useDispatch, useSelector} from "react-redux";
import {clearResultAbout, editAsyncAboutUs, getAsyncAboutUs} from "../../../features/setting/settingSlice";
import {Toast} from "../../../components/toast/Toast";
import * as yup from "yup";
import {useFormik} from "formik";
import {TiEdit} from "react-icons/ti";
import Media from "../../../components/media/Media";

const AboutUs = () => {

    const {about, resultAbout} = useSelector(state => state.setting)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncAboutUs())
    }, [])

    useEffect(() => {
        if (resultAbout.status !== undefined) {
            if (resultAbout.status === "success") {
                dispatch(clearResultAbout())
                Toast.success(resultAbout.message)
            } else {
                Toast.error(resultAbout.message)
            }
        }
    }, [resultAbout])

    const initialValues = {
        about_title: "",
        about_image_one: "",
        about_image_two: "",
        about_body: "",
    }

    let validationSchema = yup.object({
        about_title: yup.string().required("وارد کردن عنوان پنل مدیریت ضروری است."),
        about_body: yup.string().required("وارد کردن تماس با ما ضروری است.")
    });

    const onSubmit = (values) => {
        dispatch(editAsyncAboutUs(values))
    }

    const formik = useFormik({
        initialValues: about || initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit
    })

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="صفحه ی درباره ما"
                                icons={<TiEdit className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <form onSubmit={formik.handleSubmit}
                      className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                    <div className="flex flex-col gap-8">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                            <Media
                                single={true}
                                label="انتخاب تصویراول صفحه"
                                desc=""
                                name="about_image_one"
                                formik={formik}
                                formikAddress={formik.values.about_image_one}
                            />
                            <Media
                                single={true}
                                label="انتخاب تصویر دوم صفحه"
                                desc=""
                                name="about_image_two"
                                formik={formik}
                                formikAddress={formik.values.about_image_two}
                            />
                        </div>
                        <Input name="about_title" label="عنوان درباره ما" formik={formik}/>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-300">متن صفحه</span>
                        <div className="flex flex-col w-full justify-start gap-1">
                            <JoditEditorText formik={formik} name="about_body"
                                             formikAddress={formik.values.about_body}/>
                        </div>
                    </div>
                    <div className="my-12">
                        <ButtonForm
                            formik={formik}
                            title_button="ذخیره تغییرات"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AboutUs;