import React, {useEffect, useState} from 'react';
import {BiError, BiLoaderAlt} from 'react-icons/bi';
import Paging from "./Paging";
import useWindowSize from "../../customHook/useWindowSize";
import {NavLink} from "react-router-dom";


const DataTable = ({
                       columns,
                       data,
                       count,
                       page,
                       perPage,
                       setPerPage,
                       numberPage,
                       setNumberPage,
                       loading,
                       onClick,
                       to,
                       nameTitle
                   }) => {

    const {height, width} = useWindowSize();
    const [typeDataTable, setTypeDataTable] = useState(<MobileMode data={data} columns={columns}/>)

    useEffect(() => {
        if (width < 1024) {
            setTypeDataTable(<MobileMode data={data} columns={columns}/>)
        } else {
            setTypeDataTable(<PcMode data={data} columns={columns}/>)
        }
    }, [width, data])


    return (

        <div className=" flex flex-col gap-4 mt-3 ">
            <div className=" flex flex-col gap-2 md:flex-row items-center justify-between ">
                {nameTitle &&
                    <NavLink to={to}>
                        <span
                            className=" text-blue-600 dark:text-blue-400 text-sm border-2 border-blue-500 px-6 py-2 rounded-lg hover:bg-blue-500 hover:text-gray-100 dark:hover:text-gray-100 ">{nameTitle}</span>
                    </NavLink>
                }
                <div className="flex flex-row justify-end items-center gap-2">
                    {onClick &&
                        <button onClick={onClick} className={` flex flex-row gap-3 w-fit text-blue-600 hover:text-white border border-blue-500 hover:bg-blue-500 font-medium rounded-lg 
                                                  disabled:bg-gray-500 disabled:cursor-auto text-sm px-5 py-2.5 text-center mb-2 cursor-pointer ${loading ? " bg-gray-400 text-white " : ""} `}>
                            <div className="flex flex-row justify-center items-center gap-2">
                                {loading && (
                                    <BiLoaderAlt size={18} className="animate-spin text-white"/>
                                )}
                            </div>
                            بروزرسانی درخواست ها
                        </button>
                    }
                </div>
                <div className=" flex flex-col md:flex-row justify-end items-center gap-2 ">
                    <label htmlFor="" className=" text-gray-700 dark:text-gray-400 ">تعداد در صفحه</label>
                    <select onChange={(e) => setPerPage(e.target.value)}
                            className="p-1.5 px-3 w-28 rounded-lg text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                        <option value="50">50 عدد</option>
                        <option value="100">100 عدد</option>
                        <option value="150">150 عدد</option>
                        <option value="200">200 عدد</option>
                    </select>
                </div>
            </div>

            <div className="relative">

                {typeDataTable}

                {data && data.length < 1 && (
                    <div
                        className="md:text-xl flex flex-col md:flex-row gap-4 justify-center items-center my-10 dark:text-gray-100 text-gray-800">
                        <BiError className="text-indigo-500" size={30}/>
                        <span>اطلاعاتی برای نمایش وجود ندارد .</span>
                    </div>
                )}
                <nav className="flex flex-col md:flex-row gap-4 justify-between items-center p-6"
                     aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">درحال نمایش
                        <span
                            className="font-semibold text-gray-900 dark:text-white">{((parseInt(numberPage) - 1) * parseInt(perPage)) + 1}-{parseInt(perPage) * parseInt(numberPage) < count ? parseInt(perPage) * parseInt(numberPage) : count}</span> از <span
                            className="font-semibold text-gray-900 dark:text-white">{count}
                        </span>
                    </span>
                    <Paging page={page} setNumberPage={setNumberPage} numberPage={numberPage}/>
                </nav>
            </div>
            <div
                className="w-ful rounded-b-lg h-2 border-b-4 border-gray-300 dark:border-gray-950"></div>
        </div>

    );
};

export default DataTable;


const MobileMode = ({data, columns}) => {

    return (
        <div>
            {data && data.length > 0 && data.map((row, index) => {
                return (
                    <div key={index}
                         className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                        {columns && columns.length > 0 && columns.map((itemColumns, index) => {
                            return (
                                <div key={index} className="flex flex-row gap-2 py-4 px-6 break-words">
                                    <div>{itemColumns.name != "عملیات" ? itemColumns.name + " : " : ""}  </div>
                                    {[row].map(itemColumns.selector)}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}

const PcMode = ({columns, data}) => {

    return (

        <>
            <div
                className="w-full dark:bg-blue-500 bg-blue-300 rounded-t-lg h-2 border-t-4 border-blue-300 dark:border-blue-500"></div>
            <table className="w-full text-sm text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-white dark:bg-gray-800 dark:text-gray-400">
                <tr>
                    {columns && columns.length > 0 && columns.map((item, index) => {
                        return (
                            <th key={index} scope="col"
                                className="p-6 first:rounded-r-xl flex-initial last:rounded-l-xl bg-[#f8f8f8] dark:bg-gray-900 border-b-[6px] border-white dark:border-gray-800">
                                {item.name}
                            </th>
                        )
                    })}
                </tr>
                </thead>
                <tbody>
                {data && data.length > 0 && data.map((row, index) => {
                    return (
                        <tr key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600">
                            {columns && columns.length > 0 && columns.map((itemColumns, index) => {
                                return (
                                    <td key={index}
                                        className="first:rounded-r-xl flex-initial last:rounded-l-xl relative bg-[#eaeff5] dark:bg-gray-700 py-4 px-6 border-b-[6px] border-white dark:border-gray-800"
                                        style={itemColumns.style}>
                                        {[row].map(itemColumns.selector)}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    )
}

