import React from 'react';
import {IoExitOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {TiAttachment} from "react-icons/ti";


const LabelDashboard = ({titleLabel, ShortLabel = false, LabelButton, icons, titleButton, to}) => {

    const navigate = useNavigate()

    return (

        <div className={`flex flex-row gap-2 items-center justify-between border-b-2 border-gray-100 dark:hover:text-gray-100 dark:border-gray-500 px-3 xl:px-6 py-1`}>
            <div className="flex flex-row gap-2 items-center">
                {icons}
                <span className="text-xs font-bold text-slate-500 dark:text-slate-400">{titleLabel}</span>
            </div>
            {titleButton &&
                <div onClick={to}
                     className="w-fit flex flex-row gap-0.5 lg:gap-2 border border-blue-500 hover:bg-blue-500 hover:text-gray-100 dark:hover:text-gray-100 text-slate-500 dark:text-slate-400 rounded py-1 px-1 lg:px-3">
                    <TiAttachment size={23}/>
                    <button className="text-xs font-bold">{titleButton}</button>
                </div>
            }
            {ShortLabel &&
                <span className="text-xs text-slate-500 dark:text-slate-400">{ShortLabel}</span>
            }
            {LabelButton &&
            <div onClick={() => navigate(-1)}
                 className="w-fit flex flex-row gap-2 border border-blue-500 hover:bg-blue-500 hover:text-gray-100 dark:hover:text-gray-100 text-slate-500 dark:text-slate-400 rounded py-1 px-3">
                <IoExitOutline size={20}/>
                <button className="text-xs font-bold">{LabelButton}</button>
            </div>
            }
        </div>
    );
};

export default LabelDashboard;
