import React, {useEffect, useState} from 'react';
import {BiChevronLeft} from "react-icons/bi";


const SelectOptionManual = ({formik, formikAddress, name, options, title, require = false}) => {

    const [value, setValue] = useState(false)
    const [dropdown, setDropdown] = useState(null);
    const selectHandler = (input) => {
        console.log(input)
        formik.setFieldValue(name, input.value)
        setValue(input.label)
    }

    const handleDropdown = name => {
        if (dropdown === name || (dropdown && name === undefined)) {
            setDropdown(null);
        }
        if (dropdown !== name) {
            setDropdown(name);
        }
    }

    useEffect(() => {
        const handleClick = ({target}) => {
            handleDropdown(target.dataset.id);
        };
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick)
    }, [handleDropdown]);


    useEffect(() => {
        if (formikAddress !== undefined) {
            if (formikAddress !== "") {
                const get = options.find((item) => item.value === formikAddress)
                if (get !== undefined) {
                    setValue(get.label)
                }
            } else {
                const get = options.find((item, index) => index === 0)
                if (get !== undefined) {
                    formik.setFieldValue(name, get.value)
                    setValue(get.label)
                }
            }
        }
    }, [formikAddress, options]);


    return (
        <div className="w-full group relative">
            <label htmlFor={name}
                   className="flex flex-row gap-1 mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 ">
                {title}
            </label>
            <div className=" dark:text-gray-200
             dark:border-gray-600 border border-gray-300 text-gray-900 text-sm rounded-lg overflow-hidden
              focus-visible:outline-0 focus-visible:ring-2 focus-visible:border-none focus-visible:ring-blue-600 block w-full">
                <div className="relative">
                    <input data-id={name} value={value} id={name} name={name} type="text"
                           className="bg-gray-50 dark:bg-[#1f2937] p-2.5 w-full rounded-lg focus-visible:border-none focus-visible:outline-0 cursor-pointer"
                           role="listbox" aria-haspopup={true} readOnly={true}/>
                    <BiChevronLeft data-id={name} size={22}
                                   className={` ${dropdown === name ? "-rotate-90" : "-rotate-0"} cursor-pointer absolute left-2 top-1/2 transform -translate-y-1/2 transition-all ease-in-out duration-500 text-blue-500`}/>
                </div>

                <div
                    className={`${dropdown === name ? "max-h-60" : "max-h-0"} overflow-hidden z-50 absolute transition-[max-height] ease-in-out duration-400 w-full rounded-lg`}>
                    <div className=" bg-gray-200 dark:bg-gray-700 p-2 mt-1">
                        <div className="max-h-44 overflow-y-auto scrollbar">
                            {options && options.map((item, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        selectHandler({label: item.label, value: item.value})
                                    }}
                                         className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800 p-2  hover:rounded">
                                        <span onClick={() => {
                                            selectHandler({label: item.label, value: item.value})
                                        }}>
                                            {item.icon ? (
                                                <div className="flex flex-row gap-2 cursor-pointer">
                                                    {item.icon}
                                                    {item.label}
                                                </div>
                                            ) : (
                                                <span>
                                                    {item.label}
                                               </span>
                                            )}

                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SelectOptionManual;