import React from 'react';
import {NavLink} from "react-router-dom";

const SidebarItem = ({title, icon,link}) => {

    const inActive = 'flex flex-row items-center justify-between hover:bg-gray-400 rounded px-4 py-3 hover:font-bold hover:text-slate-100'
    const active = 'flex flex-row items-center justify-between bg-blue-500 rounded px-4 py-3 font-bold text-slate-100'

    return (

        <NavLink end to={link} className={(navData) =>(navData.isActive ? active : inActive)}>
            <div className="flex flex-row items-center">
                {icon}
                <span className="px-4">{title}</span>
            </div>
        </NavLink>

    );
};

export default SidebarItem;
