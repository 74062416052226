import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../../components/messageRedux/Loading";
import Error from "../../../components/messageRedux/Error";
import {persianDateNT} from "../../../utility/persianDateNT";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import DataTable from "../../../components/dataTable/DataTable";
import Alert from "../../../components/toast/Alert";
import {TiThListOutline} from "react-icons/ti";
import {clearProductDelete, deleteAsyncProducts, getAsyncProductsList} from "../../../features/products/productSlice";
import OperatorLink from "../../../components/dataTable/OperatorLink";
import {TbEdit} from "react-icons/tb";
import OperatorButton from "../../../components/dataTable/OperatorButton";
import {MdDelete} from "react-icons/md";
import {Toast} from "../../../components/toast/Toast";


const ListProducts = () => {

    const [perPage, setPerPage] = useState(10);
    const [numberPage, setNumberPage] = useState(1);

    const [showAlertPage, setShowAlertPage] = useState(false)
    const [showAlertValue, setShowAlertValue] = useState("")

    const {productList, productDelete, loading, error, count, page} = useSelector(state => state.product)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncProductsList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    }, [perPage, numberPage])

    const columns = [
        {
            name:  "مقاله",
            selector: row => row.product_title,
        },
        {
            name: "وضعیت",
            selector: row => row.product_publish,
        },
        {
            name: "تاریخ ثبت",
            style: {
                width: '220px'
            },
            selector: row => persianDateNT.date(row.product_create_time)
        },
        {
            name: "عملیات",
            style: {
                width: '70px'
            },
            selector: row => (
                <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center gap-2">
                        <OperatorLink to={'/add-products/' + row.product_id} icon={<TbEdit size={20}/>}
                                      label="ویرایش" classCustom="btn-blue"/>
                        <OperatorButton onClick={() => showAlert(row.product_id)} icon={<MdDelete size={20}/>}
                                        label="حذف"
                                        classCustom={row.product_status === "حذف" ? "btn-disable" : "btn-red"}
                                        disabledAction={row.product_status === "حذف"}/>
                    </div>
                </div>
            )
        }
    ];

    const showAlert = (input) => {
        setShowAlertPage(true)
        setShowAlertValue(input)
    }
    const removeFunc = () => {
        dispatch(deleteAsyncProducts({product_id: showAlertValue}))
        setShowAlertPage(false)
    }

    useEffect(() => {
        if (productDelete.status !== undefined) {
            if (productDelete.status === "success") {
                dispatch(getAsyncProductsList({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
                dispatch(clearProductDelete())
                Toast.success(productDelete.message)
                setShowAlertPage(false)
            } else {
                dispatch(clearProductDelete())
                Toast.error(productDelete.message)
            }
        }
    }, [productDelete, perPage, numberPage])

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="کلیه مقاله"
                                icons={ <TiThListOutline className="text-slate-500 dark:text-slate-400" size={22}/>}/>
               <div className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg lg:px-10 py-8">
                   <div className="flex flex-col gap-1">
                       <div>
                           {loading && (<Loading/>)}
                           {error && (<Error error={error}/>)}
                           {!loading && error === null && (
                               <DataTable
                                   to="/add-products"
                                   nameTitle="افزودن مقاله جدید"
                                   columns={columns}
                                   data={productList}
                                   count={count}
                                   page={page}
                                   perPage={perPage}
                                   setPerPage={setPerPage}
                                   numberPage={numberPage}
                                   setNumberPage={setNumberPage}
                               />
                           )}
                       </div>
                   </div>
                   <Alert
                       showAlertPage={showAlertPage}
                       setShowAlertPage={setShowAlertPage}
                       title="اخطار"
                       label="آیا برای حذف مطمئن هستید‌؟"
                       onclick={removeFunc}
                   />
               </div>
            </div>
        </div>
    );
};

export default ListProducts;
