import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import * as yup from "yup";
import ButtonForm from "../../../components/button/ButtonForm";
import Input from "../../../components/input/Input";
import {showItems} from "../../../assets/data/Data";
import {useDispatch, useSelector} from "react-redux";
import {Toast} from "../../../components/toast/Toast";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import JoditEditorText from "../../../components/editor/JoditEditorText";
import Media from "../../../components/media/Media";
import TextArea from "../../../components/textarea/TextArea";
import {TiChartLineOutline, TiTicket} from "react-icons/ti";
import {
    clearProduct,
    getAsyncProducts,
} from "../../../features/products/productSlice";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Config} from "../../../config/Config";
import {arrayObjectToFromDataNT} from "../../../utility/arrayObjectToFromData";
import {RiMailSendLine} from "react-icons/ri";
import SelectOptionManual from "../../../components/input/SelectOptionManual";
import {GenerateUrl} from "../../../utility/generateUrl";


const AddProducts = () => {

    const [uploadFile, setUploadFile] = useState(0)
    const {product, loading} = useSelector(state => state.product)
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState(true)
    const navigate = useNavigate()
    const {product_id} = useParams()

    const initialValues = {
        product_title: "",
        product_title_two: "",
        product_body: "",
        product_abstract: "",
        product_url: "",
        product_image: "",
        product_seo_title: "",
        product_seo_description: "",
        product_gallery_image: [],
        product_publish: "انتشار"
    }

    let validationSchema = yup.object({
        product_title: yup.string().required("افرودن عنوان مقاله برای ارسال ضروری است."),
        product_title_two: yup.string().required("افزودن عنوان دوم مقاله برای ارسال ضروری است."),
        product_abstract: yup.string().required("افزودن چکیده مقاله برای ارسال ضروری است."),
        product_body: yup.string().required("افزودن توضیحات مقاله برای ارسال ضروری است."),
    });

    const onSubmit = (values) => {
        if (formik.values.product_image !== null && formik.values.product_gallery_image.length > 0) {
            UploadMediaAxios(values)
        } else {
            Toast.warning("انتخاب تصاویر برای ارسال ضروری می باشد")
        }
    }

    const formik = useFormik({
        initialValues: product || initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit
    })

    useEffect(() => {
        if (product_id !== undefined) {
            formik.setFieldValue("product_url", product.product_url)
        } else {
            formik.setFieldValue("product_url", GenerateUrl(formik.values.product_title?.trim()))
        }
    }, [product_id, formik.values.product_title])

    useEffect(() => {
        formik.setFieldValue("product_url", GenerateUrl(formik.values.product_url?.trim()))
    }, [formik.values.product_url])

    useEffect(() => {
        if (product_id !== undefined) {
            dispatch(getAsyncProducts({product_id: product_id}))
        } else {
            dispatch(clearProduct())
        }
    }, [product_id])

    const config = {
        onUploadProgress: (event) => {
            const {loaded, total} = event;
            let number = Math.floor((loaded * 100) / total)
            if (number <= 100) {
                setUploadFile(number)
            }
        },
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }

    const UploadMediaAxios = async (payload) => {
        const {
            data: {
                status,
                message
            }
        } = await axios.post(Config.api, arrayObjectToFromDataNT(payload, "add_product_admin"), config)
        if (status === "success") {
            Toast.success(message)
            setTimeout(() => {
                return navigate(`/list-products`)
            }, 1000)
            formik.resetForm()
        } else {
            Toast.error(message)
        }
    }

    return (

        <div className="flex flex-col gap-2">
            <div className="bg-gray">
                <LabelDashboard LabelButton="بازگشت" titleLabel="مقاله جدید"
                                icons={<TiTicket className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <form onSubmit={formik.handleSubmit}>
                    <div
                        className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                        <div className="flex flex-col gap-1 mt-5">
                            <div  className="flex flex-col gap-5 w-full border-2 border-gray-100 dark:border-gray-500 p-6 rounded-lg">
                                <span className="text-xs text-blue-700 dark:text-blue-300 font-bold">برای انجام ویرایش url به جای فاصله از خط تیره (-) استفاده کنید.</span>
                                <div className="flex flex-col md:flex-row gap-5">
                                    <Input disabled={disabled} formik={formik} name="product_url" label="آدرس url"/>
                                    <div onClick={() => setDisabled(false)}
                                         className="flex py-2 px-8 items-center justify-center mt-6 cursor-pointer bg-blue-600 dark:bg-blue-600 text-sm text-gray-100 rounded font-bold">ویرایش
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-6 justify-start py-8">
                            <div className="flex flex-col w-full justify-start gap-4 text-gray-400">
                                <Input formik={formik} name="product_title" label="نام مقاله"/>
                                <Input formik={formik} name="product_title_two" label="عنوان معرفی مقاله"/>
                                <SelectOptionManual formik={formik} options={showItems} name="product_publish"
                                                    title="وضعیت انتشار"
                                                    formikAddress={formik.values.product_publish}/>
                            </div>
                            <div className="lg:w-80">
                                <Media
                                    single={true}
                                    label="افزودن تصویر اصلی"
                                    desc="سایز عکس 480x720-px"
                                    name="product_image"
                                    formik={formik}
                                    formikAddress={formik.values.product_image}/>
                            </div>
                        </div>
                        <TextArea formik={formik} name="product_abstract" label="چکیده اطلاعات مقاله"/>

                        <Media
                            label="افزودن تصاویر مقاله"
                            name="product_gallery_image"
                            formik={formik}
                            formikAddress={formik.values.product_gallery_image}/>

                        <div className="flex flex-col gap-2">
                            <span className="text-sm font-medium text-gray-900 dark:text-gray-300">توضیحات مقاله</span>
                            <div className="flex flex-col w-full justify-start gap-1">
                                <JoditEditorText formik={formik} name="product_body"
                                                 formikAddress={formik.values.product_body}/>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mt-5">
                            <LabelDashboard ShortLabel="بیدستان کالااستیل" titleLabel="سئو مقاله"
                                            icons={<TiChartLineOutline className="text-slate-500 dark:text-slate-400"
                                                                       size={25}/>}/>
                            <div
                                className="flex flex-col gap-5 w-full border-2 border-gray-100 dark:border-gray-500 p-6 rounded-lg">
                                <Input formik={formik} name="product_seo_title" label="عنوان سئو"/>
                                <TextArea formik={formik} name="product_seo_description" label="توضیحات"/>
                            </div>
                        </div>
                        {uploadFile > 0 &&
                            <div className="w-full bg-gray-50 dark:bg-gray-800 p-6 rounded-xl">
                                <div
                                    className="flex flex-col gap-2 border border-blue-600 rounded-xl w-full px-10 py-4 items-center justify-center">
                                    <div className="flex flex-row gap-2 items-center justify-center">
                                        <RiMailSendLine className="text-blue-600" size={30}/>
                                        <span className="text-blue-600 font-bold text-lg">در حال ثبت اطلاعات</span>
                                    </div>
                                    <div className="w-full my-5 bg-gray-300 dark:bg-gray-700 rounded-full">
                                        <div
                                            className="bg-blue-500 p-0.5 text-center text-sm font-medium leading-none text-white rounded-full"
                                            style={{width: ` ${uploadFile}% `}}>
                                            {uploadFile}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="w-full flex flex-col my-12 mt-10">
                            <ButtonForm
                                formik={formik}
                                title_button="ثبت مقاله"
                                title_button_edit="ویرایش مقاله"
                                type={product_id}
                                loading={loading}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddProducts;
