import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/toast/Toast";
import {deleteAsyncNews} from "../news/newsSlice";

export const getAsyncProductsList = createAsyncThunk('product/getAsyncProductsList', async (payload,
                                                                                            {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "list_product_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncProducts = createAsyncThunk('product/getAsyncProducts', async (payload,
                                                                                    {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_product_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const deleteAsyncProducts = createAsyncThunk('product/deleteAsyncProducts', async (payload,
                                                                                          {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "delete_product_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    productList: [],
    product: {},
    productDelete: {},
    count: 1,
    page: 1,
    loading: false,
    error: null,
    result: {},
    status: "idle"
}

const productSlice = createSlice({

    name: "product",
    initialState,
    reducers: {
        clearResultProduct: (state, action) => {
            state.result = {}
        },
        clearProduct: (state, action) => {
            state.product = false
        },
        clearProductDelete: (state, action) => {
            state.productDelete = {}
        },
    },
    extraReducers: {
        [getAsyncProductsList.fulfilled]: (state, action) => {
            return {
                ...state,
                productList: action.payload.result.product,
                count: action.payload.result.count,
                page: action.payload.result.page,
                loading: false,
                error: null
            }
        },
        [getAsyncProductsList.pending]: (state, action) => {
            return {
                ...state,
                productList: [], count: 1,
                page: 1,
                loading: true,
                error: null
            }
        },
        [getAsyncProductsList.rejected]: (state, action) => {
            return {
                ...state,
                productList: [],
                count: 1, page: 1,
                loading: false,
                error: ConfigMessage.error
            }
        },

        [getAsyncProducts.fulfilled]: (state, action) => {
            return {
                ...state,
                product: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncProducts.pending]: (state, action) => {
            return {
                ...state,
                product: {},
                loading: true,
                error: null
            }
        },
        [getAsyncProducts.rejected]: (state, action) => {
            return {
                ...state,
                product: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [deleteAsyncProducts.fulfilled]: (state, action) => {
            return {
                ...state,
                productDelete: action.payload,
                loading: false,
                error: ConfigMessage.error
            }
        },
        [deleteAsyncProducts.pending]: (state, action) => {
            return {
                ...state,
                productDelete: {},
                loading: true,
                error: null
            }
        },
        [deleteAsyncProducts.rejected]: (state, action) => {
            return {
                ...state,
                productDelete: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        // [deleteAsyncProducts.fulfilled]: (state, action) => {
        //     if (action.payload.status === "success") {
        //         state.productList = state.productList.filter((value) => value.product_id != action.payload.result)
        //         Toast.success(action.payload.message)
        //     } else {
        //         Toast.error(action.payload.message)
        //     }
        // },
    }
})

export const {clearProduct, clearResultProduct,clearProductDelete} = productSlice.actions;

export default productSlice.reducer
