import './App.css';
import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LayoutHomeAdmin from "./pages/homeAdmin/LayoutHomeAdmin";
import AddNews from "./pages/homeAdmin/D-news/AddNews";
import ListNews from "./pages/homeAdmin/D-news/ListNews";
import Manager from "./pages/homeAdmin/B-managers/Manager";
import CategoryNews from "./pages/homeAdmin/D-news/CategoryNews";
import Slider from "./pages/homeAdmin/E-Slider/Slider";
import Login from "./pages/login/Login";
import Setting from "./pages/homeAdmin/setting/Setting";
import AddManager from "./pages/homeAdmin/B-managers/AddManager";
import AddCategoryNews from "./pages/homeAdmin/D-news/AddCategoryNews";
import AddSlider from "./pages/homeAdmin/E-Slider/AddSlider";
import EditProfile from "./pages/homeAdmin/profile/EditProfile";
import Dashboard from "./pages/homeAdmin/A-dashboard/Dashbard";
import ListProducts from "./pages/homeAdmin/C-product/ListProducts";
import AddProducts from "./pages/homeAdmin/C-product/AddProduts";
import ListCustomers from "./pages/homeAdmin/F-customers/ListCustomers";
import AddCustomer from "./pages/homeAdmin/F-customers/AddCustomer";
import PublicRoutes from "./components/auth/PublicRouteAdmin";
import ProtectedRoutes from "./components/auth/AdminRoute";
import ContactUs from "./pages/homeAdmin/G-contactUs/ContactUs";
import Response from "./pages/homeAdmin/G-contactUs/Response";
import AboutUs from "./pages/homeAdmin/setting/AboutUs";
import Contact from "./pages/homeAdmin/setting/Contact";
import AboutNilfam from "./pages/homeAdmin/w-aboutNilfamtech/AboutNilfam";
import Reports from "./pages/homeAdmin/H-Reports/Reports";
import ReportsAdmin from "./pages/homeAdmin/H-Reports/ReportsAdmin";
import AddTeam from "./pages/homeAdmin/I-team/AddTeam";
import ListTeam from "./pages/homeAdmin/I-team/ListTeam";
import AddGallery from "./pages/homeAdmin/O-Gallery/AddGallery";
import SocialNetwork from "./pages/homeAdmin/setting/SocialNetwork";
import EditPassword from "./pages/homeAdmin/profile/EditPassword";
import EditPasswordGeneral from "./pages/homeAdmin/B-managers/EditPasswordGeneral";


function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="" element={<PublicRoutes/>}>
                    <Route path="" element={<Login/>}/>
                </Route>

                <Route path="/" element={<ProtectedRoutes/>}>
                    <Route path="*" element={<LayoutHomeAdmin/>}>
                        <Route path="*" element={<Dashboard/>}/>

                        <Route path="add-products" element={<AddProducts/>}/>
                        <Route path="add-products/:product_id" element={<AddProducts/>}/>
                        <Route path="list-products" element={<ListProducts/>}/>

                        <Route path="add-news" element={<AddNews/>}/>
                        <Route path="add-news/:news_id" element={<AddNews/>}/>
                        <Route path="list-news" element={<ListNews/>}/>
                        <Route path="category-news" element={<CategoryNews/>}/>
                        <Route path="add-category-news" element={<AddCategoryNews/>}/>
                        <Route path="add-category-news/:news_category_id" element={<AddCategoryNews/>}/>

                        <Route path="slider" element={<Slider/>}/>
                        <Route path="add-slider" element={<AddSlider/>}>
                            <Route path="" element={<AddSlider/>}/>
                            <Route path=":slider_id" element={<AddSlider/>}/>
                        </Route>

                        <Route path="list-customer" element={<ListCustomers/>}/>
                        <Route path="add-customer" element={<AddCustomer/>}/>
                        <Route path="add-customer/:customer_id" element={<AddCustomer/>}/>

                        <Route path="add-gallery" element={<AddGallery/>}/>

                        <Route path="manager" element={<Manager/>}/>
                        <Route path="add-manager/:id" element={<AddManager/>}/>
                        <Route path="change-password-general/:id" element={<EditPasswordGeneral/>}/>
                        <Route path="add-manager" element={<AddManager/>}/>

                        <Route path="social-network" element={<SocialNetwork/>}/>

                        <Route path="work-team" element={<AddTeam/>}/>
                        <Route path="list-work-team" element={<ListTeam/>}/>
                        <Route path="work-team/:team_id" element={<AddTeam/>}/>

                        <Route path="contact-us" element={<ContactUs/>}/>
                        <Route path="response" element={<Response/>}/>
                        <Route path="response/:contact_id" element={<Response/>}/>

                        <Route path="reports-all" element={<Reports/>}/>
                        <Route path="reports-admin/:log_admin_id" element={<ReportsAdmin/>}/>

                        <Route path="setting" element={<Setting/>}/>
                        <Route path="about" element={<AboutUs/>}/>
                        <Route path="contact" element={<Contact/>}/>

                        <Route path="edit-profile" element={<EditProfile/>}/>
                        <Route path="change-password" element={<EditPassword/>}/>

                        <Route path="about-software" element={<AboutNilfam/>}/>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
