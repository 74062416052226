import axios from "axios";
import {Config} from "../config/Config";

const app = axios.create({
    url : Config.api
})

app.interceptors.response.use(
    (res) => res,
    (err)=>{
        if(err.response.status === 401){
            localStorage.removeItem("token");
            return window.location.href = '/'
        }
    }
)

const http = {
    post : app.post,
}


export default http;
