import React from 'react';
import {BiLoaderAlt} from "react-icons/bi";

const ButtonForm = ({formik,title_button,title_button_edit = "",loading = null,type = undefined}) => {

    return (
        <div className="w-full flex flex-row gap-2">
            <button disabled={!formik.isValid || loading} className="w-full flex py-2 items-center justify-center
             disabled:dark:bg-gray-700 disabled:dark:text-gray-500 disabled:bg-gray-200 disabled:text-gray-500 bg-blue-600 dark:bg-blue-600 text-sm text-gray-100 rounded font-bold">
                {loading && (
                    <BiLoaderAlt size={18} className="animate-spin"/>
                )}
                {type !== undefined ? title_button_edit :  title_button}
            </button>
        </div>
    );
};

export default ButtonForm;
