import React, {useEffect} from 'react';
import Input from "../../../components/input/Input";
import SelectOptionManual from "../../../components/input/SelectOptionManual";
import {showItems} from "../../../assets/data/Data";
import ButtonForm from "../../../components/button/ButtonForm";
import * as yup from "yup";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Toast} from "../../../components/toast/Toast";
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import {TiUserAddOutline} from "react-icons/ti";
import Media from "../../../components/media/Media";
import {clearResultTeam, clearTeam, getAsyncTeam, postAsyncTeam} from "../../../features/team/teamSlice";


const AddTeam = () => {

    const {team, result, loading} = useSelector(state => state.team)
    const dispatch = useDispatch()
    const {team_id} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (team_id !== undefined) {
            dispatch(getAsyncTeam({team_id: team_id}))
        } else {
            dispatch(clearTeam())
        }
    }, [team_id])

    useEffect(() => {
        if (result.status !== undefined) {
            if (result.status === "success") {
                setTimeout(() => {
                    return navigate('/list-work-team')
                }, 1000)
                dispatch(clearResultTeam())
                Toast.success(result.message)
            } else {
                Toast.error(result.message)
            }
        }
    }, [result])

    const initialValues = {
        team_role: "",
        team_name: "",
        team_family: "",
        team_education: "",
        team_image: "",
        team_publish: "انتشار"
    }

    let validationSchema = yup.object({
        team_role: yup.string().required("افزودن سمت برای ارسال ضروری است."),
        team_name: yup.string().required("افزودن نام برای ارسال ضروری است."),
        team_family: yup.string().required("افزودن نام خانوادگی برای ارسال ضروری است."),
        team_education: yup.string().required("افزودن تحصیلات برای ارسال ضروری است."),
    });

    const onSubmit = (values) => {
        dispatch(postAsyncTeam(values))
    }

    const formik = useFormik({
        initialValues: team || initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit
    })

    console.log(formik.values)

    return (

        <div className="bg-gray">
            <LabelDashboard LabelButton="بازگشت" titleLabel="افزودن اعضاء جدید"
                            icons={<TiUserAddOutline className="text-slate-500 dark:text-slate-400" size={25}/>}/>
            <form onSubmit={formik.handleSubmit}
                  className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                <div className="flex flex-col lg:flex-row gap-5">
                    <div className="flex flex-col w-full gap-5">
                        <Input name="team_name" label="نام" formik={formik}/>
                        <Input name="team_family" label="نام خانوادگی" formik={formik}/>
                        <Input name="team_role" label="سمت" formik={formik}/>
                    </div>
                    <div className=" lg:w-80">
                        <Media
                            single={true}
                            desc=""
                            label="افزودن تصویر اعضاء"
                            name="team_image"
                            formik={formik}
                            formikAddress={formik.values.team_image}
                        />
                    </div>
                </div>
              <div className="flex flex-col md:flex-row gap-5">
                  <Input name="team_education" label="تحصیلات" formik={formik}/>
                  <SelectOptionManual formik={formik} options={showItems} name="team_publish"
                                      title="وضعیت انتشار" formikAddress={formik.values.team_publish}/>
              </div>
                <div className="w-full flex flex-col my-12">
                    <ButtonForm
                        formik={formik}
                        title_button="ثبت"
                        title_button_edit="ویرایش"
                        type={team_id}
                        loading={loading}
                    />
                </div>
            </form>
        </div>

    );
};

export default AddTeam;
