import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import http from "../../services/httpService"

export const getAsyncProfile = createAsyncThunk('profile/getAsyncProfile', async (payload, {rejectWithValue}) => {
    try {
        const result = await http.post(Config.api, formUrlEncoded({
            ...payload, action: "get_profile_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const postAsyncChangPasswordGeneral = createAsyncThunk('profile/postAsyncChangPasswordGeneral', async (payload, {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "change_password_general_admin_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const postAsyncProfile = createAsyncThunk('profile/postAsyncProfile', async (payload, {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "change_password_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})


const initialState = {
    profile : {},
    password : false,
    loading : false,
    error : null,
    result : {},
    resultProfile : {},
    status : "idle"
}

const profileSlice = createSlice({

    name: "profile",
    initialState,
    reducers: {
        clearResultGeneral:(state,action) => {
            state.result = {}
        },
        clearResultProfile:(state,action) => {
            state.resultProfile = {}
        },
        clearProfile:(state,action) => {
            state.password = false
        },
    },
    extraReducers: {
        [getAsyncProfile.fulfilled]: (state, action) => {
            return {
                ...state,
                profile: action.payload.result,
                loading: false,
                error: null
            }
        },
        [getAsyncProfile.pending]: (state, action) => {
            return {
                ...state,
                profile: {},
                loading: true,
                error: ConfigMessage.error
            }
        },
        [getAsyncProfile.rejected]: (state, action) => {
            return {
                ...state,
                profile: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncChangPasswordGeneral.fulfilled]: (state, action) => {
            return {
                ...state,
                result: action.payload,
                loading: false,
                error: null
            }
        },
        [postAsyncChangPasswordGeneral.pending]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: true,
                error: ConfigMessage.error
            }
        },
        [postAsyncChangPasswordGeneral.rejected]: (state, action) => {
            return {
                ...state,
                result: {},
                loading: false,
                error: ConfigMessage.error
            }
        },

        [postAsyncProfile.fulfilled]: (state, action) => {
            return {
                ...state,
                resultProfile: action.payload,
                loading: false,
                error: null
            }
        },
        [postAsyncProfile.pending]: (state, action) => {
            return {
                ...state,
                resultProfile: {},
                loading: true,
                error: ConfigMessage.error
            }
        },
        [postAsyncProfile.rejected]: (state, action) => {
            return {
                ...state,
                resultProfile: {},
                loading: false,
                error: ConfigMessage.error
            }
        }
    }

})

export const {clearProfile,clearResultProfile,clearResultGeneral} = profileSlice.actions;

export default profileSlice.reducer
