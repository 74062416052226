import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import formUrlEncoded from "form-urlencoded";
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";


export const getAsyncDetailsDashboard = createAsyncThunk('dashboard/getAsyncDetailsDashboard', async (payload,
                                                                                             {rejectWithValue}) => {
    try {
        const result = await axios.post(Config.api, formUrlEncoded({
            ...payload, action: "get_details_dashboard_admin"
        }), {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        const value = result.data;
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

const initialState = {
    dashboard: {},
    loading: false,
    error: null,
    status: "idle"
}

const managersSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: {
        [getAsyncDetailsDashboard.fulfilled]: (state, action) => {
            return {
                ...state,
                dashboard: action.payload.result,
                loading: false,
                error: null,
            }
        },
        [getAsyncDetailsDashboard.pending]: (state, action) => {
            return {
                ...state,
                dashboard: {},
                loading: true,
                error: null
            }
        },
        [getAsyncDetailsDashboard.rejected]: (state, action) => {
            return {
                ...state,
                dashboard: {},
                loading: false,
                error: ConfigMessage.error
            }
        },
    }

})

export const {} = managersSlice.actions;

export default managersSlice.reducer
