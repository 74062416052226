import React, {useEffect} from 'react';
import LabelDashboard from "../../../components/labelDashboard/LabelDashboard";
import ButtonForm from "../../../components/button/ButtonForm";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";
import {
    clearContact,
    clearResultContact,
    getAsyncContact,
    postAsyncContact
} from "../../../features/contact/contactSlice";
import JoditEditorText from "../../../components/editor/JoditEditorText";
import {Toast} from "../../../components/toast/Toast";
import {TiEdit} from "react-icons/ti";


const Response = () => {

    const {contact , result} = useSelector(state => state.contact)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {contact_id} = useParams()

    useEffect(() => {
        if (contact_id !== undefined) {
            dispatch(getAsyncContact({contact_id: contact_id}))
        } else {
            dispatch(clearContact())
        }
    }, [contact_id])

    useEffect(() => {
        if (result.status !== undefined) {
            if (result.status === "success") {
                dispatch(clearResultContact())
                Toast.success(result.message)
                setTimeout(() => {
                    return navigate('/dashboard/manager')
                }, 1000)
            } else {
                Toast.error(result.message)
            }
        }
    }, [result])

    const initialValues = {
        contact_name: contact.contact_name,
        contact_mobile: contact.contact_mobile,
        contact_email: contact.contact_email,
        contact_body: contact.contact_body,
        contact_answered_status: contact.contact_answered_status,
        contact_answered_body: contact.contact_answered_body,
    }

    let validationSchema = yup.object({
        response_body: yup.string().required("افزودن پیام برای ارسال ضروری است.")

    });

    const onSubmit = (values) => {
        dispatch(postAsyncContact(values))
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize : true,
        onSubmit

    })

    console.log(contact.contact_name)

    return (

        <div className="flex flex-col gap-6">
            <div className="bg-gray">
                <LabelDashboard LabelButton="بازگشت" titleLabel="پاسخگویی به پیام ها" icons={<TiEdit className="text-slate-500 dark:text-slate-400" size={25}/>}/>
                <form onSubmit={formik.handleSubmit}  className="flex flex-col gap-4 justify-start border-2 border-gray-100 dark:border-gray-500 rounded-lg px-5 lg:px-10 py-8">
                    <div className="flex flex-row gap-6 justify-start">
                        <div className="flex flex-col gap-4 w-full justify-start border border-blue-300/50 p-8 rounded-lg text-gray-900 dark:text-gray-300">
                            <div className="border-b pb-2 flex flex-row gap-1">
                                <span>وضعیت پیام : </span>
                                <span>{formik.values.contact_answered_status ?
                                    (<div className="text-red-500 font-bold">پاسخ نداده</div>)
                                    :
                                    (<div className="text-green-500 font-bold">پاسخ داده</div>)}
                                </span>
                            </div>
                            <div>
                                <span>نام : </span>
                                <span>{formik.values.contact_name}</span>
                            </div>
                            <div>
                                <span>تلفن همراه : </span>
                                <span>{formik.values.contact_mobile}</span>
                            </div>
                            <div>
                                <span>ایمیل : </span>
                                <span>{formik.values.contact_email}</span>
                            </div>
                            <div>
                                <span>پیام دریافتی : </span>
                                <span>{formik.values.contact_body}</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-6 justify-start">
                        <div className="flex flex-col gap-4 w-full justify-start text-gray-400 relative">
                            <label className="text-gray-700 text-sm font-bold dark:text-gray-400">
                                پاسخگویی به پیام
                            </label>
                            <JoditEditorText media={false} formik={formik} name="contact_answered_body" formikAddress={formik.values.contact_answered_body}/>
                        </div>
                    </div>
                    <div className="w-full flex flex-col my-12">
                        <ButtonForm formik={formik} title_button="ارسال پیام"/>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Response;
